<div
    *ngIf="getAccessRightsMetadata()"
    class="item-page-field access-status"
>
    <ds-metadata-field-wrapper [label]="'item.page.accessrights.status' | translate">
        <span *ngFor="let mv of getAccessRightsMetadata(); let last=last;">
          <span [innerHTML]="getLoginUrlVersionOfValue(mv)"></span>
          <span *ngIf="!last"><br/></span>
        </span>
    </ds-metadata-field-wrapper>
</div>
