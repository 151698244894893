<div class="item-page-field">
    <ds-metadata-field-wrapper [label]="label | translate">
        <ng-container *ngFor="let field of fields; let last = last;">
            <ng-container *ngFor="let uri of item.allMetadata(field); let innerLast = last;">
                <a [href]="uri.value">
                    <ds-truncate-chars [text]="uri.value"
                                       [charLimit]="getCharLimit(field) | async"></ds-truncate-chars>
                </a>
                <span *ngIf="!last && !innerLast" [innerHTML]="separator"></span>
            </ng-container>
        </ng-container>
    </ds-metadata-field-wrapper>
</div>
