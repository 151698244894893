<div class="item-page-field">
  <ds-metadata-field-wrapper [label]="label | translate">
    <div *ngIf="renderMelbourneAuthors">
      <span *ngFor="let author of melbourneAuthors; let last=last;">
        <a [routerLink]="'/search'" [queryParams]="{ query: 'melbourne.contributor.author:' + author }">{{ author }}</a>
        <span *ngIf="!last" [innerHTML]="separator"></span>
      </span>
      <span *ngIf="melbourneAuthorsOverMax">...</span>
    </div>
    <div *ngIf="!renderMelbourneAuthors">
      <span *ngFor="let author of authors; let last=last;">
        <span>{{ author }}</span>
        <span *ngIf="!last" [innerHTML]="separator"></span>
      </span>
      <span *ngIf="authorsOverMax">...</span>
    </div>
  </ds-metadata-field-wrapper>
</div>
