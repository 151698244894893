import { Component, Input, OnInit } from '@angular/core';
import { ItemPageFieldComponent } from '../item-page-field.component';
import { Item } from '../../../../../core/shared/item.model';
import { isNotEmpty, isNotUndefined, isUndefined } from '../../../../../shared/empty.util';
import { ConfigurationDataService } from '../../../../../core/data/configuration-data.service';
import { combineLatest as observableCombineLatest, Observable, of as observableOf } from 'rxjs';
import { getFirstSucceededRemoteDataPayload } from '../../../../../core/shared/operators';
import { ConfigurationProperty } from '../../../../../core/shared/configuration-property.model';
import { map } from 'rxjs/operators';

const CHAR_LIMIT_CONFIGURATION_PREFIX = 'item-view.max-chars.';

@Component({
    selector: 'ds-item-page-truncated-uri-field',
    templateUrl: './item-page-truncated-uri-field.component.html',
    styleUrls: ['./item-page-truncated-uri-field.component.scss']
})
export class ItemPageTruncatedUriFieldComponent extends ItemPageFieldComponent implements OnInit {

    /**
     * The item to display metadata for
     */
    @Input() item: Item;

    /**
     * Fields (schema.element.qualifier) used to render their values.
     */
    @Input() fields: string[];

    /**
     * Label i18n key for the rendered metadata
     */
    @Input() label: string;

    /**
     * Separator string between multiple values of the metadata fields defined
     */
    @Input() separator: string;

    /**
     * The maximum amount of characters that can be displayed without truncation
     */
    @Input() charLimit: number;

    @Input() charLimitMap$: Observable<{ [mdField: string]: number }>;

    constructor(
        public configService: ConfigurationDataService,
    ) {
        super();
    }

    ngOnInit(): void {
        if (isUndefined(this.charLimit)) {
            this.charLimitMap$ = observableCombineLatest(this.fields.map((field: string) =>
                    this.configService.findByPropertyName(CHAR_LIMIT_CONFIGURATION_PREFIX + field)
                        .pipe(
                            getFirstSucceededRemoteDataPayload(),
                            map((value: ConfigurationProperty) => isNotEmpty(value.values) ? +value.values[0] : undefined),
                            map((value) => [field, value])
                        )
                )
            ).pipe(map((configurationArray: [field: string, value: number][]) => {
                return configurationArray.reduce((a, [f, v]: [string, number]) => ({...a, [f]: v}), {});
            }));
        }
    }

    getCharLimit(field: string): Observable<number> {
        if (isNotUndefined(this.charLimit)) {
            return observableOf(this.charLimit);
        } else if (isNotUndefined(this.charLimitMap$)) {
            return this.charLimitMap$.pipe(
                map((charLimitMap) => isNotEmpty(charLimitMap) ? charLimitMap[field] : undefined)
            );
        }
        return observableOf(undefined);
    }
}
