<ds-metadata-field-wrapper *ngIf="(ancestorHandles$ | async).length > 0" [label]="(label | translate)">
  <ng-container *ngFor="let relation of relations">
    <ds-item-page-search-link-field *ngIf="showLink$(relation) | async"
                                    [item]="item"
                                    [withWrapper]="false"
                                    [field]="relation.sourceMdf"
                                    [solrField]="relation.destinationMdf"
                                    [linkText]="relation.i18nLink"
                                    [viewMode]="ViewMode.GridElement"
                                    [commonSearchOptions]="getSearchOptions$(relation) | async"
    ></ds-item-page-search-link-field>
  </ng-container>
</ds-metadata-field-wrapper>
