import {Component, Input, OnInit} from '@angular/core';
import {MetadataValue} from '../../../../../../../../app/core/shared/metadata.models';

@Component({
  selector: 'ds-access-rights-field',
  templateUrl: './access-rights-field.component.html',
  styleUrls: ['./access-rights-field.component.scss']
})
export class AccessRightsFieldComponent {

  @Input() item;

  getAccessRightsMetadata() {
    return this.item?.allMetadata([
      'melbourne.accessrights',
      // NOTE: disabled for w2p 84708
      // 'dc.description.accessrights',
    ]);
  }

  getLoginUrlVersionOfValue(mv: MetadataValue) {
    return mv.value.replace('login', `<a target="_blank" href="/login" ]>login</a>`);
  }

}
