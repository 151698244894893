/**
 * Model class for a citation source
 */
import { autoserialize, deserialize, inheritSerialization } from 'cerialize';
import { inheritLinkAnnotations, typedObject } from '../cache/builders/build-decorators';
import { CacheableObject } from '../cache/object-cache.reducer';
import { HALLink } from './hal-link.model';
import { ResourceType } from './resource-type';
import { excludeFromEquals } from '../utilities/equals.decorators';
import { CITATION_SOURCE } from './citation-source.resource-type';

/**
 * A Citation Source object, ex. Scopus
 */
@typedObject
@inheritSerialization(CacheableObject)
@inheritLinkAnnotations(CacheableObject)
export class CitationSource implements CacheableObject {
  static type = CITATION_SOURCE;

  /**
   * Unique identifier
   */
  @autoserialize
  id: string;

  /**
   * The name of this external source
   */
  @autoserialize
  name: string;

  /**
   * The object type
   */
  @excludeFromEquals
  @autoserialize
  type: ResourceType;

  /**
   * The {@link HALLink}s for this CitationSource
   */
  @deserialize
  _links: {
    self: HALLink;
  };
}
