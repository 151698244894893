import { typedObject } from '../../cache/builders/build-decorators';
import { ACCESS_CONDITION_OPTION } from './config-type';
import { autoserialize } from 'cerialize';
import { TypedObject } from '../../cache/object-cache.reducer';

/**
 * Model class for an Access Condition Option
 */
@typedObject
export class AccessConditionOptionModel extends TypedObject {
  static type = ACCESS_CONDITION_OPTION;

  /**
   * The name for this Access Condition
   */
  @autoserialize
  name: string;

  /**
   * A boolean representing if this Access Condition has a start date
   */
  @autoserialize
  hasStartDate: boolean;

  /**
   * A boolean representing if this Access Condition has an end date
   */
  @autoserialize
  hasEndDate: boolean;

  /**
   * Maximum value of the start date
   */
  @autoserialize
  maxStartDate: string;

  /**
   * Maximum value of the end date
   */
  @autoserialize
  maxEndDate: string;
}
