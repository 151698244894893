import { Injectable } from '@angular/core';
import { statletDataTypeService } from '../statlet-data-type.decorator';
import { StatletPointRoute } from '../../../data/models/statlet.utils';
import { getStatletPagePath } from '../../../../statlets-routing-paths';
import {
  AtmireValuePairDataService
} from '../../../../../shared-reports/value-pairs/atmire-value-pair-data.service';
import { StatletCountryCodeDataTypeService } from '../statlet-country-code-data-type.service';

@Injectable()
@statletDataTypeService('visitor', 'countryCode', 'SiteTopCountriesCurrentUser-course-work')
export class CourseWorkSCCUDTService extends StatletCountryCodeDataTypeService {
  constructor(protected valuePairService: AtmireValuePairDataService) {
    super(valuePairService);
  }

  getLinkByValue(value: string): StatletPointRoute {
    return { href: getStatletPagePath('SiteTopItemsCurrentUser-course-work'), queryParams: { ['f.visitor.countryCode']: value } };
  }
}
