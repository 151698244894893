<ds-metadata-field-wrapper [label]="label | translate">
    <span *ngFor="let mdValue of item?.allMetadata(fields); let last=last;">
        <a [queryParams]="getFilterLink(mdValue.value)" [routerLink]="['/search']">
            {{mdValue.value}}
        </a>
        <span *ngIf="!last" [innerHTML]="separator"></span>
    </span>
</ds-metadata-field-wrapper>


