/**
 * Model class for a citation value
 */
import { autoserialize, autoserializeAs, deserialize } from 'cerialize';
import { Observable } from 'rxjs/internal/Observable';
import { link, typedObject } from '../cache/builders/build-decorators';
import { CacheableObject } from '../cache/object-cache.reducer';
import { RemoteData } from '../data/remote-data';
import { excludeFromEquals } from '../utilities/equals.decorators';
import { CitationSource } from './citation-source.model';
import { CITATION_SOURCE } from './citation-source.resource-type';
import { CITATION_VALUE } from './citation-value.resource-type';
import { HALLink } from './hal-link.model';
import { ResourceType } from './resource-type';

/**
 * A Citation Value object, citation info of an Item from a certain Citation Source
 */
@typedObject
export class CitationValue implements CacheableObject {
  static type = CITATION_VALUE;

  /**
   * The object type
   */
  @excludeFromEquals
  @autoserialize
  type: ResourceType;

  /**
   * The identifier of this CitationValue
   */
  @autoserialize
  id: string;

  /**
   * The number count of this CitationValue
   */
  @autoserialize
  count: number;

  /**
   * The URI of this CitationValue
   */
  @autoserialize
  uri: string;

  /**
   * The UUID of the item of this CitationValue
   */
  @autoserialize
  item: string;

  /**
   * The date that this CitationValue was last updated
   */
  @autoserializeAs('last_updated')
  lastUpdated: string;

  /**
   * The {@link HALLink}s for this CitationValue
   */
  @deserialize
  _links: {
    self: HALLink;
    source: HALLink;
  };

  /**
   * The Citation Source of this Citation Value
   * Will be undefined unless the source {@link HALLink} has been resolved.
   */
  @link(CITATION_SOURCE, false)
  source?: Observable<RemoteData<CitationSource>>;

}
