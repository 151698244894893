<span class="uom-author-entry">
  <a [routerLink]="['/search']" [queryParams]="getAuthorFilterLink(label)">
    {{label}}
  </a>
  <a [href]="faeUrl" class="uom-badge uom-fae">
    <span class="fa fa-user"></span>
  </a>
  <a *ngIf="hasOrcid" [href]="orcidUrl" class="uom-badge uom-orcid">
    <img src="../../../../../../assets/images/ORCID_iD.svg" alt="ORCID">
  </a>
</span>
