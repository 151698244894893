import { Component, Input } from '@angular/core';

import { Item } from '../../../../../core/shared/item.model';
import { ItemPageFieldComponent } from '../item-page-field.component';

@Component({
  selector: 'ds-item-page-html-field',
  templateUrl: './item-page-html-field.component.html'
})
/**
 * This component can be used to display HTML metadata on a simple item page.
 */
export class ItemPageHtmlFieldComponent extends ItemPageFieldComponent {

  /**
   * The item to display metadata for
   */
  @Input() item: Item;

  /**
   * Fields (schema.element.qualifier) used to render their values.
   */
  @Input() fields: string[];

  /**
   * Label i18n key for the rendered metadata
   */
  @Input() label: string;

  get values(): string[] {
    return this.item?.allMetadata(this.fields).map(mdv => mdv.value);
  }
}
