import {createSelector} from '@ngrx/store';
import {AppState} from '../../app.reducer';

/**
 * Return the VHost state from the App state.
 * @param state
 *    the App state
 */
export const vHostStateSelector = (state: AppState) => state.vHost;

/**
 * Return the VHost from the VHost state.
 */
export const vHostSelector = createSelector(
  vHostStateSelector,
  (state) => state.vHost,
);

/**
 * Return the VHost community from the VHost state.
 */
export const vHostCommmunitySelector = createSelector(
  vHostStateSelector,
  (state) => state.community,
);
