import { autoserialize, deserialize, inheritSerialization } from 'cerialize';
import { Observable } from 'rxjs';
import { link, typedObject } from '../cache/builders/build-decorators';
import { RemoteData } from '../data/remote-data';
import { BitstreamFormat } from './bitstream-format.model';
import { BITSTREAM_FORMAT } from './bitstream-format.resource-type';
import { BITSTREAM } from './bitstream.resource-type';
import { DSpaceObject } from './dspace-object.model';
import { HALLink } from './hal-link.model';
import { HALResource } from './hal-resource.model';
import {BUNDLE} from './bundle.resource-type';
import {Bundle} from './bundle.model';
import { hasValue, isUndefined } from '../../shared/empty.util';
import { excludeFromEquals } from '../utilities/equals.decorators';
import { ACCESS_CONDITION_OPTION } from '../config/models/config-type';
import { AccessConditionOptionModel } from '../config/models/config-access-conditions-option.model';
import { PaginatedList } from '../data/paginated-list.model';
import { AccessConditionModel } from '../config/models/config-access-conditions.model';

@typedObject
@inheritSerialization(DSpaceObject)
export class Bitstream extends DSpaceObject implements HALResource {
  static type = BITSTREAM;

  /**
   * The size of this bitstream in bytes
   */
  @autoserialize
  sizeBytes: number;

  /**
   * The description of this Bitstream
   */
  @excludeFromEquals
  _description: string;

  /**
   * The name of the Bundle this Bitstream is part of
   */
  @autoserialize
  bundleName: string;

  /**
   * The {@link HALLink}s for this Bitstream
   */
  @deserialize
  _links: {
    self: HALLink;
    bundle: HALLink;
    format: HALLink;
    content: HALLink;
    thumbnail: HALLink;
    accessConditions: HALLink;
    accessConditionsOptions: HALLink;
  };

  /**
   * The thumbnail for this Bitstream
   * Will be undefined unless the thumbnail {@link HALLink} has been resolved.
   */
  @link(BITSTREAM, false, 'thumbnail')
  thumbnail?: Observable<RemoteData<Bitstream>>;

  /**
   * The BitstreamFormat of this Bitstream
   * Will be undefined unless the format {@link HALLink} has been resolved.
   */
  @link(BITSTREAM_FORMAT, false, 'format')
  format?: Observable<RemoteData<BitstreamFormat>>;

  /**
   * The owning bundle for this Bitstream
   * Will be undefined unless the bundle{@link HALLink} has been resolved.
   */
  @link(BUNDLE)
  bundle?: Observable<RemoteData<Bundle>>;

  /**
   * The name for this DSpaceObject
   * @deprecated use {@link DSONameService} instead
   */
  get description(): string {
    return (isUndefined(this._description)) ? this.firstMetadataValue('dc.description') : this._description;
  }

  /**
   * The name for this DSpaceObject
   */
  set description(description) {
    if (hasValue(this.firstMetadata('dc.description'))) {
      this.firstMetadata('dc.description').value = description;
    }
    this._description = name;
  }

  /**
   * The access conditions for this Bitstream
   * Will be undefined unless the bundle{@link HALLink} has been resolved.
   */
  @link(ACCESS_CONDITION_OPTION, true)
  accessConditions?: Observable<RemoteData<PaginatedList<AccessConditionModel>>>;

  /**
   * The access condition options for this Bitstream
   * Will be undefined unless the bundle{@link HALLink} has been resolved.
   */
  @link(ACCESS_CONDITION_OPTION, true)
  accessConditionsOptions?: Observable<RemoteData<PaginatedList<AccessConditionOptionModel>>>;

}
