<ng-container *ngVar="(bitstreams$ | async) as bitstreams">
  <ds-metadata-field-wrapper *ngIf="bitstreams?.length > 0" [label]="label | translate">
    <div class="file-section">
      <span *ngFor="let file of bitstreams; let last=last;">
        <div class="d-flex flex-row">
            <ds-file-download-link [bitstream]="file" [hitHighlights]="hitHighlightsOfItem[SNIPPET_HITHIGHLIGHT_PREFIX + file.id]">
              <span>{{file?.description || file?.name}}</span>
              <span>({{(file?.sizeBytes) | dsFileSize }})</span>
            </ds-file-download-link>
            <ds-extend-embargo-link prefix="(" [item]="item" [bitstream]="file" postfix=")"></ds-extend-embargo-link>
        </div>

        <span *ngIf="!last" innerHTML="{{separator}}"></span>
      </span>
      <ds-loading *ngIf="isLoading" message="{{'loading.default' | translate}}" [showMessage]="false"></ds-loading>
      <div *ngIf="!isLastPage" class="mt-1" id="view-more">
        <a class="bitstream-view-more btn btn-outline-secondary btn-sm" [routerLink]="" (click)="getNextPage()">{{'item.page.bitstreams.view-more' | translate}}</a>
      </div>
      <div *ngIf="isLastPage && currentPage != 1" class="mt-1" id="collapse">
        <a class="bitstream-collapse btn btn-outline-secondary btn-sm" [routerLink]="" (click)="currentPage = undefined; getNextPage();">{{'item.page.bitstreams.collapse' | translate}}</a>
      </div>
    </div>
  </ds-metadata-field-wrapper>
</ng-container>
