import { Component, Renderer2 } from '@angular/core';
import { CitationValueService } from '../../../../../core/data/citation-value.service';
import { Item } from '../../../../../core/shared/item.model';
import { CitationsSectionComponent } from '../../../../citation/citations-section.component';
import { SearchConfigurationService } from '../../../../../core/shared/search/search-configuration.service';

/**
 * Displays a list of CitationValue objects of an item on the item page
 */
@Component({
  selector: 'ds-citations-section-browse-element',
  templateUrl: './citations-section-browse-element.component.html'
})
export class CitationsSectionBrowseElementComponent extends CitationsSectionComponent {

  constructor(protected citationValueServiceChild: CitationValueService, protected renderer2Child: Renderer2,
              protected searchConfigurationService: SearchConfigurationService) {
    super(citationValueServiceChild, renderer2Child, searchConfigurationService);
  }

  ngOnInit() {
    this.shouldShowCitations$ = this.searchConfigurationService.shouldShowCitations();
    super.ngOnInit();
  }

  public setShowCitations(event: [boolean, Item]) {
    if (event[1].id === this.item.id) {
      const querySelector = '.citations-section-' + this.item.id;
      this.renderer2Child.removeClass(document.querySelector(querySelector), 'd-none');
    }
  }

}
