import { Inject, Injectable } from '@angular/core';
import { VHostRedirectService } from './vhost-redirect.service';
import { LocationToken } from './browser-hard-redirect.service';

@Injectable()
export class BrowserVHostRedirectService implements VHostRedirectService {

  constructor(
    @Inject(LocationToken) protected location: Location,
  ) {
  }

  redirect(url: string) {
    this.location.replace(url);
  }

  getOriginFromUrl() {
    return this.location.hostname;
  }

  getRoute() {
    return this.location.href;
  }
}
