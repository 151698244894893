<ng-container *ngVar="searchOptions as config">
    <h3>{{ 'search.sidebar.settings.title' | translate}}</h3>
    <div class="result-order-settings">
    <ds-sidebar-dropdown
            *ngIf="config?.sort"
            [id]="'search-sidebar-sort'"
            [label]="'search.sidebar.settings.sort-by'"
            (change)="reloadOrder($event)"
    >
        <option *ngFor="let sortOption of sortOptions"
                [value]="sortOption.field + ',' + sortOption.direction.toString()"
                [selected]="sortOption.field === config?.sort.field && sortOption.direction === (config?.sort.direction)? 'selected': null">
            {{'sorting.' + sortOption.field + '.' + sortOption.direction | translate}}
        </option>
    </ds-sidebar-dropdown>
    </div>
    <ds-page-size-selector></ds-page-size-selector>
    <div class="setting-option page-size-settings mb-3 px-3 py-3">
      <h5 class="mb-3">{{ 'search.sidebar.settings.statistics.header' | translate}}</h5>
      <div class="custom-control custom-switch statistics-setting">
        <input type="checkbox" class="custom-control-input" id="statisticsSwitch"
               [checked]="this.showStatistics$ | async"
               (change)="toggleShowStatistics($event)">
        <label class="custom-control-label"
               for="statisticsSwitch">{{ 'search.sidebar.settings.statistics.label' | translate }}</label>
      </div>
    </div>

    <div class="setting-option page-size-settings mb-3 px-3 py-3">
      <h5 class="mb-3">{{ 'search.sidebar.settings.citations.header' | translate}}</h5>
      <div class="custom-control custom-switch citations-setting">
        <input type="checkbox" class="custom-control-input" id="citationsSwitch"
               [checked]="this.showCitations$ | async"
               (change)="toggleShowCitations($event)">
        <label class="custom-control-label"
               for="citationsSwitch">{{ 'search.sidebar.settings.citations.label' | translate }}</label>
      </div>
    </div>

</ng-container>
