import { Component, Input } from '@angular/core';
import { ItemPageFieldComponent } from '../item-page-field.component';
import { Item } from '../../../../../core/shared/item.model';
import { hasNoValue } from '../../../../../shared/empty.util';

@Component({
  selector: 'ds-item-page-named-uri-field',
  templateUrl: './item-page-named-uri-field.component.html',
  styleUrls: ['./item-page-named-uri-field.component.scss']
})
export class ItemPageNamedUriFieldComponent extends ItemPageFieldComponent {

  /**
   * The i18n key contains the label for this metadata field.
   */
  @Input() label: string;

  /**
   * The item to display metadata for.
   */
  @Input() item: Item;

  /**
   * The metadata field that contains the names of the hyperlinks, as displayed to the user.
   */
  @Input() nameField: string;

  /**
   * The metadata field that contains the href values of the hyperlinks.
   */
  @Input() hrefField: string;

  /**
   * Separator string between between links.
   */
  @Input() separator: string;

  /**
   * Process the metadata fields into a structure that the template understands.
   * Each outer array represents a value, as displayed in the UI.
   * Each inner array consists of the href value (or null to render plain text)
   * and the name value (or null to use the href value as link text).
   */
  getData(): [string|null, string|null][] {
    if (hasNoValue(this.item)) {
      return [];
    }

    const nameMdvs = this.item.allMetadata(this.nameField);
    const hrefMdvs = this.item.allMetadata(this.hrefField);
    const valueCount = nameMdvs.length > hrefMdvs.length ? nameMdvs.length : hrefMdvs.length;

    const data = [];
    for (let i = 0; i < valueCount; i++) {
      const name = nameMdvs[i]?.value || null;
      const href = hrefMdvs[i]?.value || null;

      data.push([href, name]);
    }

    return data;
  }

}
