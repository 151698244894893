<ng-template #deleteTipContent><p [innerHTML]="'submission.workflow.tasks.claimed.delete_help' | translate"></p></ng-template>
<button [className]="'btn btn-danger'"
        [ngbTooltip]="deleteTipContent"
        [disabled]="processing$ | async"
        (click)="openDeleteModal(deleteModal)" >
  <span *ngIf="processing$ | async"><i class='fas fa-circle-notch fa-spin'></i> {{'submission.workflow.tasks.generic.processing' | translate}}</span>
  <span *ngIf="!(processing$ | async)"><i class="fa fa-trash"></i> {{'submission.workflow.tasks.claimed.delete.submit' | translate}}</span>
</button>

<ng-template #deleteModal let-c="close" let-d="dismiss">
  <div class="modal-header">
    <h4 class="modal-title">{{'submission.workflow.tasks.claimed.delete.confirm.title' | translate}}</h4>
    <button type="button"
            class="close"
            aria-label="Close"
            (click)="d('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <p>{{ 'submission.workflow.tasks.claimed.delete.confirm.info' | translate }}</p>
    <button type="button" class="cancel btn btn-secondary" (click)="d('Cross click')" aria-label="Cancel">
      {{ 'submission.workflow.tasks.claimed.delete.confirm.cancel' | translate }}
    </button>
    <button type="button" class="confirm btn btn-primary" (click)="submitTask()" aria-label="Confirm" ngbAutofocus>
      {{ 'submission.workflow.tasks.claimed.delete.confirm.confirm' | translate }}
    </button>
  </div>
</ng-template>
