import { MenuService } from '../menu.service';
import { Injectable } from '@angular/core';
import { StatletDataService } from '../../../atmire-cua/statlets/shared/data/services/statlet-data.service';
import { MenuID, MenuItemType } from '../initial-menus-state';
import { Item } from '../../../core/shared/item.model';
import { StatletPosition } from '../../../atmire-cua/statlets/shared/data/models/statlet-position.model';
import { getFirstSucceededRemoteDataPayload } from '../../../core/shared/operators';
import { map } from 'rxjs/operators';
import { hasValue, isNotEmpty, isNotEmptyOperator } from '../../empty.util';
import { LinkMenuItemModel } from '../menu-item/models/link.model';
import { TextMenuItemModel } from '../menu-item/models/text.model';
import { DSpaceObject } from '../../../core/shared/dspace-object.model';

@Injectable()
/**
 * A service for creating and adding statistics menu sections
 */
export class StatisticsMenuService {
  constructor(protected menuService: MenuService,
              protected statletService: StatletDataService) {
  }

  /**
   * Add statistics menu sections within the context of an item
   * @param item
   */
  addMenuOptions(item: Item) {
    this.addMenuOptionsForType(item.firstMetadataValue('dspace.entity.type'), false, item.id, item);
  }

  /**
   * Add statistics menu sections for an entity or dspace-object type
   * @param type                        The type of object to search available standAlone statlets for (e.g. Site, Person...)
   * @param shouldPersistOnRouteChange  Should the added options persist on route change or not
   * @param relationshipWith            Link to statlets filtering on objects related to a given other object (by ID)
   * @param object                      Optional DSpaceObject to add statistic links for (always includes Site)
   */
  addMenuOptionsForType(type: string = 'Untyped', shouldPersistOnRouteChange = false, relationshipWith?: string, object?: DSpaceObject) {
    this.statletService.searchByObject(null, `standAlone${type}` as StatletPosition, true).pipe(
      getFirstSucceededRemoteDataPayload(),
      map((statlets) => statlets.page),
      isNotEmptyOperator(),
    ).subscribe((statlets) => {
      const menuList = [];
      menuList.push(...statlets.map((statlet, index: number) => {
        const extraParams = {};
        if (isNotEmpty(relationshipWith)) {
          const relationshipFilter = statlet.optionalFilters.find((filter) => filter.category === 'relationship');
          if (hasValue(relationshipFilter)) {
            extraParams[relationshipFilter.filterField] = relationshipWith;
          }
        }
        return {
          id: 'atmire-cua_' + statlet.shortName,
          parentID: 'atmire-cua',
          active: false,
          visible: true,
          index: index * 100,
          model: {
            type: MenuItemType.LINK,
            text: 'menu.section.statistics.' + statlet.shortName,
            link: '/atmire-cua/reports/' + statlet.shortName,
            params: extraParams,
          } as LinkMenuItemModel,
          shouldPersistOnRouteChange
        };
      }), {
        id: 'atmire-cua',
        active: false,
        visible: true,
        model: {
          type: MenuItemType.TEXT,
          text: 'menu.section.statistics'
        } as TextMenuItemModel,
        index: 1,
        shouldPersistOnRouteChange: true,
      });
      menuList.forEach((menuSection) => this.menuService.addSection(MenuID.PUBLIC, menuSection));
    });

    if (hasValue(object)) {
      this.statletService.searchByObject(object.self, `standAlone${type}` as StatletPosition, true).pipe(
        getFirstSucceededRemoteDataPayload(),
        map((statlets) => statlets.page),
        isNotEmptyOperator(),
      ).subscribe((statlets) => {
        const menuList = [];
        menuList.push(...statlets.map((statlet, index: number) => {
          return {
            id: 'atmire-cua_' + statlet.shortName,
            parentID: 'atmire-cua',
            active: false,
            visible: true,
            index: 10000 + (index * 100),
            model: {
              type: MenuItemType.LINK,
              text: 'menu.section.statistics.' + statlet.shortName,
              link: '/atmire-cua/reports/' + statlet.shortName,
              params: {
                dso: object.id,
              },
            } as LinkMenuItemModel,
            shouldPersistOnRouteChange
          };
        }));
        menuList.forEach((menuSection) => this.menuService.addSection(MenuID.PUBLIC, menuSection));
      });
    }
  }
}
