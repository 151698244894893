import { Component, Input } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import { map } from 'rxjs/operators';
import { CitationSourceService } from '../../../core/data/citation-source.service';
import { CitationSource } from '../../../core/shared/citation-source.model';
import { CitationValue } from '../../../core/shared/citation-value.model';
import { getFirstSucceededRemoteDataPayload } from '../../../core/shared/operators';

@Component({
  selector: 'ds-citation-circle',
  styleUrls: ['./citation-circle.component.scss'],
  templateUrl: './citation-circle.component.html'
})
/**
 * Displays info about a single CitationValue object of an item
 */
export class CitationCircleComponent {

  @Input() citationValue: CitationValue;

  // Whether or not to render the small view version of the citation circles
  @Input() smallView: boolean;

  constructor(private citationSourceService: CitationSourceService) {
  }

  /**
   * Gets source name by retrieving citationSource of this.citationValue
   */
  getSourceName(): Observable<string> {
    return this.citationSourceService.findByHref(this.citationValue._links.source.href).pipe(
      getFirstSucceededRemoteDataPayload(),
      map((citationSource: CitationSource) => {
        return citationSource.name;
      })
    );
  }
}
