<form class="form-login"
      (ngSubmit)="submit()"
      [formGroup]="form" novalidate>

  <button class="btn btn-lg btn-primary btn-block mt-3" type="submit"
          [disabled]="!form.valid"><i class="fas fa-sign-in-alt"></i> {{"login.form.submit" | translate}}</button>
  <label class="sr-only">{{"login.form.email" | translate}}</label>
  <input [attr.aria-label]="'login.form.email' |translate"
         autocomplete="off"
         autofocus
         class="form-control form-control-lg position-relative"
         formControlName="email"
         placeholder="{{'login.form.email' | translate}}"
         required
         type="email">
  <label class="sr-only">{{"login.form.password" | translate}}</label>
  <input [attr.aria-label]="'login.form.password' |translate"
         autocomplete="off"
         class="form-control form-control-lg position-relative mb-3"
         placeholder="{{'login.form.password' | translate}}"
         formControlName="password"
         required
         type="password">
  <div *ngIf="(error | async) && hasError" class="alert alert-danger" role="alert"
       @fadeOut>{{ (error | async) | translate }}</div>
  <div *ngIf="(message | async) && hasMessage" class="alert alert-info" role="alert"
       @fadeOut>{{ (message | async) | translate }}</div>

</form>
