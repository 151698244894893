<ds-type-badge *ngIf="showLabel" [object]="dso"></ds-type-badge>

<div class="d-flex align-items-center">
  <div class="flex-grow-1 overflow-hidden">
      <ds-truncatable [id]="dso.id" *ngIf="object !== undefined && object !== null">
          <div class="d-flex">
              <div class="thumbnail-wrapper mr-2 mt-2">
                <ds-melbourne-thumbnail
                  [item]="dso"
                  [mdFieldsForThumbnail]="['melbourne.prints.digitalurl', 'melbourne.archives.digitalurl']"
                  [clickable]="false"
                  [imageCount]="1"
                >
                </ds-melbourne-thumbnail>
              </div>
              <div class="description-wrapper">
                  <a *ngIf="linkType != linkTypes.None" [target]="(linkType == linkTypes.ExternalLink) ? '_blank' : '_self'" [attr.rel]="(linkType == linkTypes.ExternalLink) ? 'noopener' : null"
                     [routerLink]="[itemPageRoute]" class="lead item-list-title dont-break-out"
               [innerHTML]="dsoTitle" [state]="getState(object)"></a>
                  <span *ngIf="linkType == linkTypes.None" class="lead item-list-title dont-break-out"
                        [innerHTML]="dsoTitle"></span>
                  <span class="text-muted">
                    <ds-truncatable-part [id]="dso.id" [minLines]="1">
                      <span *ngIf="dso.allMetadata(['dc.contributor.author', 'dc.creator', 'dc.contributor.*']).length > 0" class="item-list-authors">
                        <span *ngFor="let author of allMetadataValues(['dc.contributor.author', 'dc.creator', 'dc.contributor.*']); let last=last;">
                            <span [innerHTML]="author"><span [innerHTML]="author"></span></span>
                            <span *ngIf="!last">; </span>
                        </span>
                      </span>
                      <ng-container *ngIf="dso.firstMetadataValue('dc.publisher') || dso.firstMetadataValue('dc.date.issued')">
                        (<span *ngIf="dso.firstMetadataValue('dc.publisher')" class="item-list-publisher" [innerHTML]="firstMetadataValue('dc.publisher') + ', '"></span><!--
                         --><span *ngIf="dso.firstMetadataValue('dc.date.issued')" class="item-list-date" [innerHTML]="firstMetadataValue('dc.date.issued')"></span>)
                      </ng-container>
                    </ds-truncatable-part>
                  </span>
                  <div *ngIf="dso.firstMetadataValue('dc.description.abstract')" class="item-list-abstract">
                      <ds-truncatable-part [id]="dso.id" [minLines]="3"><span
                              [innerHTML]="firstMetadataValue('dc.description.abstract')"></span>
                      </ds-truncatable-part>
                  </div>
              </div>
          </div>
        <div class="pt-1">
          <ds-search-result-statlet [object]="object"
                                                      [separator]="'; '">
          </ds-search-result-statlet>
        </div>
      </ds-truncatable>
      <ds-citations-section-browse-element [item]="dso"
                                           [labelTitle]="'publications.citations-section.title'"></ds-citations-section-browse-element>
  </div>
  <div class="ml-2">
    <ds-atmire-saved-item-list-control *ngIf="savedItemListControlEnabled" [item]="dso"></ds-atmire-saved-item-list-control>
  </div>
</div>
