import { Injectable } from '@angular/core';
import { dataService } from '../cache/builders/build-decorators';
import { CitationSource } from '../shared/citation-source.model';
import { CITATION_SOURCE } from '../shared/citation-source.resource-type';
import { DataService } from './data.service';
import { RequestService } from './request.service';
import { RemoteDataBuildService } from '../cache/builders/remote-data-build.service';
import { Store } from '@ngrx/store';
import { CoreState } from '../core.reducers';
import { ObjectCacheService } from '../cache/object-cache.service';
import { HALEndpointService } from '../shared/hal-endpoint.service';
import { NotificationsService } from '../../shared/notifications/notifications.service';
import { HttpClient } from '@angular/common/http';
import { FindListOptions } from './request.models';
import { Observable } from 'rxjs/internal/Observable';
import { DefaultChangeAnalyzer } from './default-change-analyzer.service';

/**
 * A service handling all citation source requests
 */
@Injectable()
@dataService(CITATION_SOURCE)
export class CitationSourceService extends DataService<CitationSource> {
  protected linkPath = 'citationsources';

  constructor(
    protected requestService: RequestService,
    protected rdbService: RemoteDataBuildService,
    protected store: Store<CoreState>,
    protected objectCache: ObjectCacheService,
    protected halService: HALEndpointService,
    protected notificationsService: NotificationsService,
    protected http: HttpClient,
    protected comparator: DefaultChangeAnalyzer<CitationSource>) {
    super();
  }

  /**
   * Get the endpoint to browse citation sources
   * @param options
   * @param linkPathCitation
   */
  getBrowseEndpoint(options: FindListOptions = {}, linkPath: string = this.linkPath): Observable<string> {
    return this.halService.getEndpoint(linkPath);
  }
}
