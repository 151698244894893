<div ngbDropdown #sdRef="ngbDropdown"
     (openChange)="openChange($event);">

  <div class="form-row align-items-center" ngbDropdownAnchor>
    <!--Simple lookup, first field -->
    <div class="col right-addon">
      <i dsAuthorityConfidenceState
         class="far fa-circle fa-2x fa-fw position-absolute mt-1 p-0 pr-2"
         aria-hidden="true"
         [authorityValue]="model.value"
         (whenClickOnConfidenceNotAccepted)="search(sdRef)"></i>
      <input class="form-control"
             [attr.autoComplete]="model.autoComplete"
             [class.is-invalid]="showErrorMessages"
             [id]="model.id"
             [name]="model.name"
             [type]="model.inputType"
             [(ngModel)]="firstInputValue"
             [dsDebounce]="500" (onDebounce)="search(sdRef)"
             [disabled]="model.closedAuthority"
             [placeholder]="model.placeholder | translate"
             [readonly]="model.readOnly"
             (keydown)="keydownInput($event)"
             (change)="onChange($event)">
    </div>

    <!--Lookup-name, second field-->
    <div *ngIf="isLookupName()" class="col" >
      <input  class="form-control"
              [ngClass]="{}"
              [attr.autoComplete]="model.autoComplete"
              [class.is-invalid]="showErrorMessages"
              [id]="id"
              [name]="model.name + '_2'"
              [type]="model.inputType"
              [(ngModel)]="secondInputValue"
              [dsDebounce]="500" (onDebounce)="search(sdRef)"
              [disabled]="model.closedAuthority"
              [placeholder]="model.secondPlaceholder | translate"
              [readonly]="model.readOnly"
              (keydown)="keydownInput($event)"
              (change)="onChange($event)">
    </div>
    <div class="col-auto text-center">
      <button class="btn btn-secondary"
              type="button"
              ngbTooltip="{{'form.search-help' | translate}}"
              placement="top"
              [disabled]="model.readOnly"
              (click)="lookup()">{{'form.lookup' | translate}}
      </button>
    </div>
  </div>

  <div ngbDropdownMenu *ngVar="(localEntries$ | async) as localEntriesRD"
       class="dropdown-menu scrollable-dropdown-menu w-100"
       aria-haspopup="true"
       aria-expanded="false"
       aria-labelledby="scrollableDropdownMenuButton">
    <h6 class="dropdown-header">{{ 'atmire.submission.search.local-authority' | translate}}</h6>
    <ds-loading *ngIf="localEntriesRD?.isLoading" class="dropdown-item-text" [message]="''"></ds-loading>
    <a *ngFor="let localEntry of localEntriesRD?.payload?.page" class="dropdown-item"
       (keydown)="keydownDropdownItem($event)"
       (click)="addLocalEntry(localEntry, sdRef)"
       ngbDropdownItem href="javascript:void(0)">
      <div>{{ localEntry?.display }}</div>
      <div *ngIf="localEntry?.metadata && localEntry?.metadata['person.identifier.orcid']?.length > 0">{{ localEntry?.metadata['person.identifier.orcid'][0].value }}</div>
    </a>
    <div *ngIf="localEntriesRD?.payload?.page?.length === 0"
         class="dropdown-item-text local-authorities-empty">
      {{ 'atmire.submission.search.local-authority.empty' | translate }}
    </div>
    <a *ngIf="localEntriesRD && localEntriesRD?.payload?.totalElements > localEntriesAmount"
       class="dropdown-item dropdown-item-link"
       (keydown)="keydownDropdownItem($event)"
       (click)="lookup()"
       ngbDropdownItem href="javascript:void(0)">
      {{ 'atmire.submission.search.local-authority.more' | translate:{ amount: (localEntriesRD?.payload?.totalElements - localEntriesAmount) } }}
    </a>
    <h6 *ngIf="model?.externalSources?.length > 0" class="dropdown-header">{{ 'atmire.submission.search.external-sources' | translate}}</h6>
    <a *ngFor="let externalSource of model.externalSources"
       class="dropdown-item dropdown-item-link"
       (keydown)="keydownDropdownItem($event)"
       (click)="lookup(externalSource)"
       ngbDropdownItem href="javascript:void(0)">
      {{ 'atmire.submission.search.external-sources.more' | translate:{ amount: (externalSourceCount$[externalSource] | async), source: externalSource } }}
    </a>
  </div>
</div>

<ng-template #hasInfo let-entry="entry">
  <ul class="list-unstyled mb-0">
    <li class="list-item text-truncate text-primary font-weight-bold">{{entry.value}}</li>
    <li class="list-item text-truncate text-secondary" *ngFor="let item of entry.otherInformation | dsObjNgFor" >
      {{ 'form.other-information.' + item.key | translate }} : {{item.value}}
    </li>
  </ul>
</ng-template>

<ng-template #noInfo let-entry="entry">
  <ul class="list-unstyled mb-0">
    <li class="list-item text-truncate text-primary font-weight-bold">{{entry.value}}</li>
  </ul>
</ng-template>
