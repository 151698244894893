import { Component, Input } from '@angular/core';
import { GenericItemPageFieldComponent } from '../generic/generic-item-page-field.component';

@Component({
  selector: 'ds-item-page-discovery-field',
  templateUrl: './item-page-discovery-field.component.html'
})
/**
 * This component is used for displaying a metadata field value while also linking to a discover search facet
 */
export class ItemPageDiscoveryFieldComponent extends GenericItemPageFieldComponent {

  @Input() filter: string;

  getFilterLink(value) {
    return {
      [`f.${this.filter}`]: `${value},query`
    };
  }

}
