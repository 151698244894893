import { Component, Input, OnInit } from '@angular/core';
import { DSpaceObject } from '../../../core/shared/dspace-object.model';
import { ScriptDataService } from '../../../core/data/processes/script-data.service';
import { CacheableObject } from '../../../core/cache/object-cache.reducer';
import { getFirstCompletedRemoteData } from '../../../core/shared/operators';
import { RemoteData } from '../../../core/data/remote-data';
import { Process } from '../../../process-page/processes/process.model';
import { NotificationsService } from '../../notifications/notifications.service';
import { TranslateService } from '@ngx-translate/core';
import { ProcessParameter } from '../../../process-page/processes/process-parameter.model';
import { FeatureID } from '../../../core/data/feature-authorization/feature-id';
import { Observable } from 'rxjs/internal/Observable';
import { AuthorizationDataService } from '../../../core/data/feature-authorization/authorization-data.service';

@Component({
  selector: 'ds-dso-page-preservica-button',
  templateUrl: './dso-page-preservica-button.component.html',
  styleUrls: ['./dso-page-preservica-button.component.scss']
})
export class DsoPagePreservicaButtonComponent implements OnInit {
  /**
   * The DSpaceObject to display a button to the edit page for
   */
  @Input() dso: DSpaceObject;

  /**
   * A message for the tooltip on the button
   * Supports i18n keys
   */
  @Input() tooltipMsg: string;

  /**
   * Whether or not the current user is authorized to see the preservica icon the DSpaceObject
   */
  isAuthorized$: Observable<boolean>;

  constructor(protected scriptDataService: ScriptDataService,
              protected notificationsService: NotificationsService,
              protected translateService: TranslateService,
              protected authorizationService: AuthorizationDataService) { }

  ngOnInit() {
    this.isAuthorized$ = this.authorizationService.isAuthorized(FeatureID.AdministratorOf);
  }

  public sendToPreservica(): void {
    const handle = this.handle;
    const uuid = this.uuid;

    const parameterValues: ProcessParameter[] = [
      Object.assign(new ProcessParameter(), {name: '-s', value: null}),
      Object.assign(new ProcessParameter(), {name: '-h', value: handle}),
      Object.assign(new ProcessParameter(), {name: '-f', value: 'date-file-last-preservica-export-' + uuid}),
    ];
    this.scriptDataService.invoke('preservica-aip-export', parameterValues, []).pipe(
      getFirstCompletedRemoteData()
    ).subscribe((remoteData: RemoteData<Process>) => {
      console.log(remoteData);
      if (remoteData.hasSucceeded) {
        this.notificationsService.success(
          this.translateService.get('preservica.export.success.head', { type: this.dso.type, handle: handle }),
          this.translateService.get('preservica.export.success.body'),
        );
      } else {
        this.notificationsService.error(
          this.translateService.get('preservica.export.failure.head', { type: this.dso.type, handle: handle }),
          this.translateService.get('preservica.export.failure.body', { statusCode: remoteData.statusCode, errorMessage: remoteData.errorMessage }),
        );
      }
    });
  }

  private get handle(): string {
    return (this.dso as CacheableObject).handle;
  }

  private get uuid(): string {
    return (this.dso as CacheableObject).uuid;
  }
}
