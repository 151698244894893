import { DOCUMENT } from '@angular/common';
import {
  Component,
  EventEmitter, HostListener,
  Inject,
  Input,
  OnInit,
  Output,
  Renderer2
} from '@angular/core';
import { Item } from '../../../core/shared/item.model';
import { MetadataValue } from '../../../core/shared/metadata.models';
import { isNotEmpty } from '../../empty.util';

@Component({
  selector: 'ds-altmetric-donut',
  styleUrls: ['./altmetric-donut.component.scss'],
  templateUrl: './altmetric-donut.component.html'
})
/**
 * Displays the altMetric donut with the identifier's in the given mdFields of the given item
 */
export class AltmetricDonutComponent implements OnInit {

  /**
   * mdFields (schema.element.qualifier) possibly containing the altMetric doi identifier; priority ordered
   */
  @Input() doiIdentifierMdFields: string[];

  /**
   * Item containing the altMetric identifier in one of the given identifierMdFields
   */
  @Input() item: Item;

  /**
   * Whether or not to render the small view version of the altMetric donut
   */
  @Input() smallView: boolean;

  /**
   * Identifier used to render the altMetric donut with a doi
   */
  doiIdentifierValue: string;

  /**
   * Identifier used to render the altMetric donut with a handle
   */
  handleIdentifierValue: string;

  @Output()
  altMetricDonutRenderedEmitter = new EventEmitter<[boolean, Item]>();

  constructor(private renderer2: Renderer2,
              @Inject(DOCUMENT) private _document) {
  }

  ngOnInit(): void {
    const s = this.renderer2.createElement('script');
    s.type = 'text/javascript';
    s.src = 'https://d1bxh8uas1mnw7.cloudfront.net/assets/embed.js';
    this.renderer2.appendChild(this._document.body, s);
    this.renderer2.listen(this._document, 'altmetric:show', (event) => {
      const altMetricTitle = document.querySelector('.altmetric-title');
      this.renderer2.removeClass(altMetricTitle, 'd-none');
      this.altMetricDonutRenderedEmitter.emit([true, this.item]);
    });
    this.renderer2.listen(this._document, 'altmetric:hide', (event) => {
      const altMetricTitle = document.querySelector('.altmetric-title');
        this.renderer2.addClass(altMetricTitle, 'd-none');
        this.altMetricDonutRenderedEmitter.emit([ false, this.item ]);
    });
    // Get the first identifier or use handle; for determining which identifier to use
    //  - First order of metadata field string in this.doiIdentifierMdFields is important
    //  - Then place of metadata value
    //  - Then uses first identifier in sorted list with a value
    let possibleIdentifiersSorted = [];
    if (this.item.metadata) {
      this.doiIdentifierMdFields.forEach((mdField: string) => {
        possibleIdentifiersSorted = [...possibleIdentifiersSorted, ...this.item.findMetadataSortedByPlace(mdField)];
      });
    }
    if (possibleIdentifiersSorted.length > 0) {
      const firstIdentifierMdWithValue = possibleIdentifiersSorted.find((metadata: MetadataValue) => isNotEmpty(metadata.value));
      if (firstIdentifierMdWithValue != null) {
        this.doiIdentifierValue = firstIdentifierMdWithValue.value;
      } else {
        this.handleIdentifierValue = this.item.handle;
      }
    } else {
      this.handleIdentifierValue = this.item.handle;
    }
  }
}
