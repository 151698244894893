import { Component, Input, OnChanges } from '@angular/core';

import { Item } from '../../../../../core/shared/item.model';
import { ItemPageFieldComponent } from '../item-page-field.component';
import { MetadataValue } from '../../../../../core/shared/metadata.models';

@Component({
  selector: 'ds-item-page-grant-field',
  templateUrl: './item-page-grant-field.component.html'
})
/**
 * This component can be used to represent a grant.
 */
export class ItemPageGrantFieldComponent extends ItemPageFieldComponent {

  /**
   * The item to display metadata for.
   */
  @Input() item: Item;

  /**
   * Separator string between multiple values of the metadata fields defined.
   * @type {string}
   */
  @Input() separator: string;

  /**
   * Fields (schema.element.qualifier) used to render their values.
   */
  @Input() fields: string[];

  /**
   * The name of the grant.
   */
  @Input() grantName: string;

  /**
   * Label i18n key for the rendered metadata.
   */
  @Input() label: string;

  getData(): [string, string][] {
    const data = [];

    const mdvs = this.item?.allMetadata(this.fields);
    for (const mdv of mdvs) {
      const value = mdv.value;
      const link = `https://purl.org/au-research/grants/${this.grantName}/${value}`;
      const label = `${this.grantName}/${value}`;
      data.push([link, label]);
    }

    return data;
  }

}
