<div class="d-flex flex-row">
  <h2 class="item-page-title-field mr-auto">
    <ds-metadata-values [mdValues]="object?.allMetadata(['dc.title'])"></ds-metadata-values>
  </h2>
  <div class="pl-2 d-flex flex-row">
    <ds-atmire-saved-item-list-control class="mr-1" [item]="object"></ds-atmire-saved-item-list-control>
    <ds-atmire-item-export-dropdown class="mr-1" [item]="object" [compact]="true"></ds-atmire-item-export-dropdown>
    <ds-dso-page-edit-button [pageRoute]="itemPageRoute" [dso]="object" [tooltipMsg]="'item.page.edit'"></ds-dso-page-edit-button>
    <ds-dso-page-preservica-button [dso]="object"
                                   [tooltipMsg]="'item.page.send-to-preservica'"></ds-dso-page-preservica-button>
  </div>
</div>
<div class="row">
  <div class="col-xs-12 col-md-4">
    <ng-container *ngIf="!mediaViewer.image">
      <ds-metadata-field-wrapper [hideIfNoTextContent]="false">
        <ds-thumbnail [thumbnail]="object?.thumbnail | async"></ds-thumbnail>
      </ds-metadata-field-wrapper>
    </ng-container>
    <ng-container *ngIf="mediaViewer.image">
      <ds-media-viewer [item]="object" [videoOptions]="mediaViewer.video"></ds-media-viewer>
    </ng-container>
    <ds-citations-section-item-page [item]="object"
                                    [labelTitle]="'publications.citations-section.title'">
    </ds-citations-section-item-page>
    <ds-item-page-file-section [item]="object" [hitHighlightsOfItem]="hitHighlightsOfItem"></ds-item-page-file-section>

    <a [routerLink]="'/atmire-cua/reports/ItemTopCountriesPage'" [queryParams]="{ dso: object?.id }" class="btn btn-outline-primary mb-2">
      {{ 'statlets.item.page.countries' | translate }}
    </a>

    <ds-item-page-relations-field [item]="object"
    ></ds-item-page-relations-field>

    <!--        Authors -->
    <ds-item-page-author-discovery-link-field [item]="object"></ds-item-page-author-discovery-link-field>
    <!--        Editors -->
    <ds-generic-item-page-field [item]="object"
                                [fields]="['dc.contributor.editor']"
                                [label]="'publication.page.editor'">
    </ds-generic-item-page-field>
    <!--        Date -->
    <ds-item-page-date-field [item]="object"></ds-item-page-date-field>
    <!--        Source title -->
    <ds-generic-item-page-field [item]="object"
                                [fields]="['melbourne.source.title']"
                                [label]="'publication.page.source-title'">
    </ds-generic-item-page-field>
    <!--        Publisher -->
    <ds-generic-item-page-field [item]="object"
                                [fields]="['dc.publisher']"
                                [label]="'publication.page.publisher'">
    </ds-generic-item-page-field>
    <ds-item-page-uom-author [item]="object"
                             [fields]="['melbourne.internal.authorids']"
                             [label]="'item.page.melbourne-authors'">
    </ds-item-page-uom-author>
    <!--        Affiliation -->
    <ds-generic-item-page-field [item]="object"
                                [fields]="['melbourne.affiliation.department']"
                                [label]="'publication.page.affiliation'">
    </ds-generic-item-page-field>
    <div>
      <a class="btn btn-outline-primary" [routerLink]="[itemPageRoute + '/full']" role="button">
        <i class="fas fa-info-circle"></i> {{"item.page.link.full" | translate}}
      </a>
    </div>
  </div>
  <div class="col-xs-12 col-md-6">
    <!--        Type -->
    <ds-generic-item-page-field [item]="object"
                                [fields]="['dc.type']"
                                [label]="'publication.page.type'">
    </ds-generic-item-page-field>
    <!--        Course code -->
    <ds-generic-item-page-field [item]="object"
                                [fields]="['melbourne.course.code']"
                                [label]="'publication.page.course-code'">
    </ds-generic-item-page-field>
    <!--        Identifier Citation -->
    <ds-generic-item-page-field [item]="object"
                                [fields]="['dc.identifier.citation', 'melbourne.identifier.citation']"
                                [label]="'publication.page.identifier-citation'">
    </ds-generic-item-page-field>
    <!--        Access Status -->
    <ds-item-page-html-field [item]="object"
                             [fields]="['melbourne.accessrights']"
                             [label]="'item.page.melbourne.accessrights'"
                             class="text-danger">
    </ds-item-page-html-field>
    <!--        URI -->
    <ds-item-page-uri-field [item]="object"
                            [fields]="['dc.identifier.uri']"
                            [label]="'publication.page.uri'">
    </ds-item-page-uri-field>
    <!--        DOI -->
    <ds-item-page-uri-field [item]="object"
                            [label]="'publication.page.doi' | translate"
                            [fields]="['dc.identifier.doi', 'melbourne.identifier.doi', 'dc.description.doi', 'melbourne.description.doi']"
                            prefixValue="http://dx.doi.org/"
                            [addPrefixIfDoesNotContains]="['http://dx.doi.org/', 'https://dx.doi.org/', 'doi.org']">
    </ds-item-page-uri-field>
    <!--        External URL -->
    <ds-item-page-uri-field [item]="object"
                            [label]="'publication.page.external-url' | translate"
                            [fields]="['dc.bitstream.url', 'melbourne.bitstream.url']">
    </ds-item-page-uri-field>
    <!--        Openaccess URL -->
    <ds-item-page-uri-field [item]="object"
                            [label]="'publication.page.openaccess-url' | translate"
                            [fields]="['melbourne.openaccess.url']">

    </ds-item-page-uri-field>
    <!--        Openaccess PMC -->
    <ds-item-page-uri-field [item]="object"
                            [label]="'publication.page.openaccess-pmc' | translate"
                            [fields]="['melbourne.openaccess.pmc']">

    </ds-item-page-uri-field>
    <!-- Linked Resources -->
    <ds-item-page-uri-field [item]="object"
                            [label]="'publication.page.linked-resources' | translate"
                            [fields]="['melbourne.linkedresource.url']">

    </ds-item-page-uri-field>
    <!--        Grants -->
    <ds-item-page-grant-field [item]="object"
                              [fields]="['melbourne.identifier.nhmrc']"
                              grantName="NHMRC"
                              [label]="'item.page.melbourne.grant'">
    </ds-item-page-grant-field>
    <ds-item-page-grant-field [item]="object"
                              [fields]="['melbourne.identifier.arc']"
                              grantName="ARC"
                              [label]="'item.page.melbourne.grant'">
    </ds-item-page-grant-field>
    <!--        Configurable related items -->
    <!--    <ds-related-items [parentItem]="object" [label]="'publication.page.related-items'"></ds-related-items>-->
    <!--        Description -->
    <ds-generic-item-page-field [item]="object"
                                [fields]="['dc.description']"
                                [separator]="'<br><br>'"
                                [label]="'publication.page.description'">
    </ds-generic-item-page-field>
    <!--        Abstract -->
    <ds-generic-item-page-field [item]="object"
                                [fields]="['dc.description.abstract']"
                                [separator]="'<br><br>'"
                                [label]="'publication.page.abstract'">
    </ds-generic-item-page-field>
    <!--        Melbourne Notes -->
    <ds-generic-item-page-field [item]="object"
                                [fields]="['melbourne.description.notes']"
                                [label]="'publication.page.notes'">
    </ds-generic-item-page-field>
    <!--        Melbourne Rights -->
    <ds-generic-item-page-field [item]="object"
                                [fields]="['melbourne.rights']"
                                [label]="'publication.page.rights'">
    </ds-generic-item-page-field>
    <!--        Keywords -->
    <ds-generic-item-page-field [item]="object"
                                [fields]="['dc.subject']"
                                [label]="'publication.page.subject'">
    </ds-generic-item-page-field>
    <!--        Search snippets -->
    <ds-snippets-section [hitHighlightsOfItem]="hitHighlightsOfItem"></ds-snippets-section>
    <!--        RIS -->
    <!--        Collections -->
    <ds-item-page-collections [item]="object"></ds-item-page-collections>

  </div>
</div>
