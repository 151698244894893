import { Component, Inject, Input, OnInit } from '@angular/core';
import { SearchService } from '../../../core/shared/search/search.service';
import { SortDirection, SortOptions } from '../../../core/cache/models/sort-options.model';
import { ActivatedRoute, NavigationExtras, Router } from '@angular/router';
import { PaginatedSearchOptions } from '../paginated-search-options.model';
import { SearchConfigurationService } from '../../../core/shared/search/search-configuration.service';
import { SEARCH_CONFIG_SERVICE } from '../../../my-dspace-page/my-dspace-page.component';
import { PaginationService } from '../../../core/pagination/pagination.service';
import { Observable, of as observableOf } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
  selector: 'ds-search-settings',
  styleUrls: ['./search-settings.component.scss'],
  templateUrl: './search-settings.component.html'
})

/**
 * This component represents the part of the search sidebar that contains the general search settings.
 */
export class SearchSettingsComponent implements OnInit {

  /**
   * The configuration for the current paginated search results
   */
  @Input() searchOptions: PaginatedSearchOptions;

  /**
   * All sort options that are shown in the settings
   */
  @Input() sortOptions: SortOptions[];

  /**
   * Whether or not to show statistics in the search results
   */
  showStatistics$: Observable<boolean> = observableOf(false);

  /**
   * Whether or not to show citations in the search results
   */
  showCitations$: Observable<boolean> = observableOf(false);

  constructor(private service: SearchService,
              private route: ActivatedRoute,
              private router: Router,
              private paginationService: PaginationService,
              @Inject(SEARCH_CONFIG_SERVICE) public searchConfigurationService: SearchConfigurationService) {
  }

  /**
   * Initialize paginated search options
   */
  ngOnInit(): void {
    this.showStatistics$ = this.searchConfigurationService.shouldShowStatistics().pipe(
      map((shouldShowStatistics: boolean) => {
        return shouldShowStatistics ? true : null;
      }));
    this.showCitations$ = this.searchConfigurationService.shouldShowCitations().pipe(
      map((shouldShowCitations: boolean) => {
        return shouldShowCitations;
      }));
  }

  /**
   * Method to change the current sort field and direction
   * @param {Event} event Change event containing the sort direction and sort field
   */
  reloadOrder(event: Event) {
    const values = (event.target as HTMLInputElement).value.split(',');
    this.paginationService.updateRoute(this.searchConfigurationService.paginationID, {
      sortField: values[0],
      sortDirection: values[1] as SortDirection,
      page: 1
    });
  }

  /**
   * Method to change the showStatistics setting
   */
  toggleShowStatistics(eventShowStatistics) {
    const navigationExtras: NavigationExtras = {
      queryParams: {
        showStatistics: eventShowStatistics.target.checked ? true : null,
      },
      queryParamsHandling: 'merge'
    };
    this.router.navigate([], navigationExtras);
  }

  /**
   * Method to change the showCitations setting
   */
  toggleShowCitations(eventShowCitations) {
    const navigationExtras: NavigationExtras = {
      queryParams: {
        showCitations: eventShowCitations.target.checked ? true : null,
      },
      queryParamsHandling: 'merge'
    };
    this.router.navigate([], navigationExtras);
  }
}
