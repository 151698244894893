<div class="row mb-3">
    <div class="col-xs-12 col-md-4">
        <ng-container *ngIf="!mediaViewer.image">
            <ds-melbourne-thumbnail [item]="item" [mdFieldsForThumbnail]="['melbourne.prints.digitalurl', 'melbourne.archives.digitalurl']">
            </ds-melbourne-thumbnail>
        </ng-container>
        <ng-container *ngIf="mediaViewer.image">
            <ds-media-viewer [item]="item" [videoOptions]="mediaViewer.video"></ds-media-viewer>
        </ng-container>
        <ds-citations-section-item-page [item]="item"
                                        [labelTitle]="'publications.citations-section.title'">
        </ds-citations-section-item-page>
    </div>
</div>
