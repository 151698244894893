import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { MetadataValue } from '../../../core/shared/metadata.models';
import { hasValue } from '../../empty.util';

/**
 * Component that renders the thumbnails from URL's in metadata fields on the simple item page
 */
@Component({
  selector: 'ds-melbourne-url-thumbnail',
  styleUrls: ['./melbourne-url-thumbnail.component.scss'],
  templateUrl: './melbourne-url-thumbnail.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MelbourneUrlThumbnailComponent implements OnInit {

  // Metadata values to use to render thumbnails from
  @Input() metadataValues: MetadataValue[];

  /**
   * The default image, used if the thumbnail isn't set or can't be downloaded.
   * If defaultImage is null, a HTML placeholder is used instead.
   */
  @Input() defaultImage? = null;

  /**
   * Whether or not to wrap the image in a hyperlink.
   */
  @Input() clickable = true;

  /**
   * The maximum amount of images that can be shown. Non-positive values are ignored.
   */
  @Input() imageCount: number = null;

  // Unique urls that could be found in the given metadata values
  uniqueUrlsFromMdValues: string[];

  // Number of times the rendering of a thumbnail failed
  thumbnailsFailed = 0;

  ngOnInit(): void {
    this.uniqueUrlsFromMdValues = this.metadataValues.map((metadataValue: MetadataValue) => {
      return this.getThumbnailLink(metadataValue.value);
    });
    this.uniqueUrlsFromMdValues = [...new Set(this.uniqueUrlsFromMdValues)];

    if (hasValue(this.imageCount) && this.imageCount > 0) {
      this.uniqueUrlsFromMdValues = this.uniqueUrlsFromMdValues.slice(0, this.imageCount);
    }
  }

  public getThumbnailLink(url): string {
    let resultingValue = url;
    if (hasValue(resultingValue)) {
      if (!resultingValue.startsWith('http://') && !resultingValue.startsWith('https://')) {
        resultingValue = `//${resultingValue}`;
      } else if (resultingValue.startsWith('http://')) {
        resultingValue.replace('http://', 'https://');
      }
    }
    return resultingValue;
  }

  public increaseFailedThumbnailCounts() {
    this.thumbnailsFailed++;
  }

}
