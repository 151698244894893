import { Component, Input, OnDestroy, OnInit, Renderer2 } from '@angular/core';
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';
import { Observable } from 'rxjs/internal/Observable';
import { CitationValueService } from '../../core/data/citation-value.service';
import { RemoteData } from '../../core/data/remote-data';
import { CitationValue } from '../../core/shared/citation-value.model';
import { Item } from '../../core/shared/item.model';
import { followLink } from '../utils/follow-link-config.model';
import { PaginatedList } from '../../core/data/paginated-list.model';
import { Subscription } from 'rxjs';
import { hasValue } from '../empty.util';
import { SearchConfigurationService } from '../../core/shared/search/search-configuration.service';

/**
 * Displays a list of CitationValue objects of an item
 */
@Component({
  selector: 'ds-citations-section',
  templateUrl: './citations-section.component.html'
})
export class CitationsSectionComponent implements OnInit, OnDestroy {
  @Input() item: Item;

  @Input() labelTitle: string;

  citationValuesRD$: Observable<RemoteData<PaginatedList<CitationValue>>>;

  altMetricDonutRendered$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  /**
   * Whether or not to show citations in the search results
   */
  shouldShowCitations$: Observable<boolean>;

  /**
   * The list of Subscription
   * @type {Array}
   */
  subs: Subscription[] = [];

  constructor(protected citationValueService: CitationValueService, protected renderer2: Renderer2,
              protected searchConfigurationService: SearchConfigurationService) {
  }

  public setShowCitations(event: [boolean, Item]) {
    const altMetricDonutRendered = event[0];
    if (altMetricDonutRendered) {
      this.altMetricDonutRendered$.next(altMetricDonutRendered);
      this.renderer2.removeClass(document.querySelector('.citations-section'), 'd-none');
    }
  }

  /**
   * If the citationValues of the item are not already retrieve, retrieve them here (if show citations enabled)
   */
  public ngOnInit(): void {
    this.subs.push(
      this.shouldShowCitations$.subscribe((shouldShowCitations: boolean) => {
        if (shouldShowCitations) {
          if (this.item && !this.citationValuesRD$) {
            if (this.item.citations) {
              this.citationValuesRD$ = this.item.citations;
            } else {
              if (this.item._links && this.item._links.citations) {
                this.citationValuesRD$ = this.citationValueService.findAllByHref(this.item._links.citations.href, {}, true, true, followLink('source'));
              }
            }
          }
        }
      })
    );
  }

  /**
   * Unsubscribe from all open subscriptions
   */
  ngOnDestroy(): void {
    this.subs.filter((sub) => hasValue(sub)).forEach((sub) => sub.unsubscribe());
  }

}
