<a [href]="bitstreamPath" [target]="isBlank ? '_blank': '_self'" [ngClass]="cssClasses" class="dont-break-out">
  <ng-container *ngTemplateOutlet="content"></ng-container>
</a>

<ng-template #content>
  <ng-content></ng-content>
</ng-template>

<div class="item-page-field mt-2">
  <ds-metadata-field-wrapper [label]="'item.page.files.simple.snippets' | translate">
    <span *ngFor="let mdValue of hitHighlights; let last=last;" [innerHtml]="mdValue.value"></span>
  </ds-metadata-field-wrapper>
</div>
