import { Component } from '@angular/core';
import {
  DEFAULT_CONTEXT,
  listableObjectComponent
} from '../../../../../../../../../app/shared/object-collection/shared/listable-object/listable-object.decorator';
import { ViewMode } from '../../../../../../../../../app/core/shared/view-mode.model';
import { ItemSearchResult } from '../../../../../../../../../app/shared/object-collection/shared/item-search-result.model';
import { SearchResultListElementComponent } from '../../../../../../../../../app/shared/object-list/search-result-list-element/search-result-list-element.component';
import { Item } from '../../../../../../../../../app/core/shared/item.model';
import { getItemPageRoute } from '../../../../../../../../../app/item-page/item-page-routing-paths';
import { BitstreamDataService } from '../../../../../../../../../app/core/data/bitstream-data.service';
import { TruncatableService } from '../../../../../../../../../app/shared/truncatable/truncatable.service';
import { DSONameService } from '../../../../../../../../../app/core/breadcrumbs/dso-name.service';

@listableObjectComponent('PublicationSearchResult', ViewMode.ListElement, DEFAULT_CONTEXT, 'minerva')
@listableObjectComponent(ItemSearchResult, ViewMode.ListElement, DEFAULT_CONTEXT, 'minerva')
@Component({
  selector: 'ds-item-search-result-list-element',
  styleUrls: ['./item-search-result-list-element.component.scss', '../../../../../../../../../app/shared/object-list/search-result-list-element/item-search-result/item-types/item/item-search-result-list-element.component.scss'],
  templateUrl: './item-search-result-list-element.component.html'
})
/**
 * The component for displaying a list element for an item search result of the type Publication
 */
export class ItemSearchResultListElementComponent extends SearchResultListElementComponent<ItemSearchResult, Item> {
  /**
   * Route to the item's page
   */
  itemPageRoute: string;

  constructor(
    protected bitstreamDataService: BitstreamDataService,
    protected truncatableService: TruncatableService,
    protected dsoNameService: DSONameService
  ) {
    super(
      truncatableService,
      dsoNameService,
    );
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.itemPageRoute = getItemPageRoute(this.dso);
  }
}
