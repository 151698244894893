<ng-container *ngIf="(shouldShowCitations$ | async)">
  <div
    [class.d-none]="(this.citationValuesRD$ | async)?.payload?.totalElements == 0 && !(altMetricDonutRendered$ | async)"
    class="citations-section-{{ this.item.id }} align-content-center pt-1">
    <div class="d-flex">
      <span class="pr-1">{{ this.labelTitle | translate }}:</span>
      <div class="d-flex">
        <ds-citation-circle *ngFor="let citation of (this.citationValuesRD$ | async)?.payload?.page"
                            [citationValue]="citation"
                            [smallView]="true" class="pr-2"></ds-citation-circle>
        <ds-altmetric-donut (altMetricDonutRenderedEmitter)="setShowCitations($event)"
                            [doiIdentifierMdFields]="['dc.description.doi', 'dc.identifier.doi']"
                            [item]="item" [smallView]="true">
        </ds-altmetric-donut>
      </div>
    </div>
  </div>
</ng-container>
