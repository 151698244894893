<ng-container *ngVar="(statisticsStatletRD$ | async) as statletRD">
  <div *ngIf="shouldShowStatistics$ && (shouldShowStatistics$ | async) && hasData$ && (hasData$ | async)">
    <ng-container *ngVar="statletRD.payload.page as statlets">
      <ng-container *ngFor="let statlet of statlets">
        <div [class.d-none]="!singleStatlet.hasData">
          <ds-search-result-single-statlet #singleStatlet [statlet]="statlet"
                                           [separator]="separator">
          </ds-search-result-single-statlet>
        </div>
      </ng-container>
    </ng-container>
  </div>
</ng-container>
