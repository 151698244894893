import { Component } from '@angular/core';
import {
  DEFAULT_CONTEXT,
  listableObjectComponent
} from '../../../../../../../../../app/shared/object-collection/shared/listable-object/listable-object.decorator';
import { ViewMode } from '../../../../../../../../../app/core/shared/view-mode.model';
import { ItemSearchResult } from '../../../../../../../../../app/shared/object-collection/shared/item-search-result.model';
import { ItemSearchResultListElementComponent as BaseComponent } from '../../../../../../../../../app/shared/object-list/search-result-list-element/item-search-result/item-types/item/item-search-result-list-element.component';

@listableObjectComponent('PublicationSearchResult', ViewMode.ListElement, DEFAULT_CONTEXT, 'digitisedcollections')
@listableObjectComponent(ItemSearchResult, ViewMode.ListElement, DEFAULT_CONTEXT, 'digitisedcollections')

@Component({
  selector: 'ds-dc-item-search-result-list-element',
  styleUrls: ['./item-search-result-list-element.component.scss', '../../../../../../../../../app/shared/object-list/search-result-list-element/item-search-result/item-types/item/item-search-result-list-element.component.scss'],
  templateUrl: './item-search-result-list-element.component.html'
})
/**
 * The component for displaying a list element for an item search result of the type Publication
 */
export class ItemSearchResultListElementComponent extends BaseComponent {

}
