import { DataService } from '../../core/data/data.service';
import { AtmireSavedItemList } from '../models/atmire-saved-item-list.model';
import { dataService, getClassForType } from '../../core/cache/builders/build-decorators';
import { ATMIRE_SAVED_ITEM_LIST } from '../models/atmire-saved-item-list.resource-type';
import { Injectable } from '@angular/core';
import { RequestService } from '../../core/data/request.service';
import { RemoteDataBuildService } from '../../core/cache/builders/remote-data-build.service';
import { Store } from '@ngrx/store';
import { CoreState } from '../../core/core.reducers';
import { ObjectCacheService } from '../../core/cache/object-cache.service';
import { HALEndpointService } from '../../core/shared/hal-endpoint.service';
import { NotificationsService } from '../../shared/notifications/notifications.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { DefaultChangeAnalyzer } from '../../core/data/default-change-analyzer.service';
import { ItemDataService } from '../../core/data/item-data.service';
import { CreateRequest, DeleteRequest, FindListOptions, PostRequest, PutRequest } from '../../core/data/request.models';
import { FollowLinkConfig } from '../../shared/utils/follow-link-config.model';
import { Item } from '../../core/shared/item.model';
import { combineLatest as observableCombineLatest, Observable, of as observableOf, BehaviorSubject } from 'rxjs';
import { RemoteData } from '../../core/data/remote-data';
import { PaginatedList } from '../../core/data/paginated-list.model';
import { HttpOptions } from '../../core/dspace-rest/dspace-rest.service';
import { map, mergeMap, startWith, switchMap, take, takeWhile, tap, } from 'rxjs/operators';
import {
  getAllCompletedRemoteData,
  getAllSucceededRemoteDataPayload,
  getFirstCompletedRemoteData,
  getFirstSucceededRemoteDataPayload,
} from '../../core/shared/operators';
import { RequestParam } from '../../core/cache/models/request-param.model';
import { hasNoValue, hasValue, isEmpty, isNotEmpty, isNotEmptyOperator } from '../../shared/empty.util';
import { LocalStorageService } from '../../core/services/local-storage.service';
import { AuthService } from '../../core/auth/auth.service';
import { EPerson } from '../../core/eperson/models/eperson.model';
import { AtmireSavedItemListStoreService } from '../store/atmire-saved-item-list-store.service';
import { SearchOptions } from '../../shared/search/search-options.model';
import { distinctUntilChanged, filter } from 'rxjs/operators';
import { AtmireSavedItemListState } from '../store/atmire-saved-item-list.reducer';
import { AtmireSavedItemListTypes } from '../atmire-saved-item-list-types';
import { environment } from '../../../environments/environment';
import { NoContent } from '../../core/shared/NoContent.model';
import { DSpaceSerializer } from '../../core/dspace-rest/dspace.serializer';
import { NotificationOptions } from '../../shared/notifications/models/notification-options.model';

export const STORAGE_ATMIRE_LIST_ID_PREFIX = 'ATMIRE_LIST_ID_';
export const STORAGE_ATMIRE_RECENT_LIST_PREFIX = 'ATMIRE_RECENT_LIST_';

/**
 * An object containing a list's ID and name, to be stored in storage
 */
/* tslint:disable:max-classes-per-file */
export class SavedItemListStorage {
  id: string;
  name: string;

  constructor(id: string, name: string) {
    this.id = id;
    this.name = name;
  }
}

/**
 * A Map containing observables for lists that are being created. The observables return a boolean telling the observer
 * whether or not the list was created yet.
 * This is to avoid multiple calls being made simultaneously to create a list for the same user (or cookie). This would
 * be possible if for example findMyList() was called from multiple separate locations at the same time.
 *
 * Key: Cookie the list will be stored in
 * Value: BehaviourSubject containing whether or not the list has been created and added to local storage
 */
const listsBeingCreated: Map<string, BehaviorSubject<boolean>> = new Map<string, BehaviorSubject<boolean>>();

/**
 * A service to retrieve {@link AtmireSavedItemList}s from the REST API.
 */
/* tslint:disable:max-classes-per-file */
@Injectable()
@dataService(ATMIRE_SAVED_ITEM_LIST)
export class AtmireSavedItemListDataService extends DataService<AtmireSavedItemList> {
  protected linkPath = 'saveditemlists';
  protected itemsByListPath = 'itemsByList';

  constructor(
    protected requestService: RequestService,
    protected rdbService: RemoteDataBuildService,
    protected store: Store<CoreState>,
    protected objectCache: ObjectCacheService,
    protected halService: HALEndpointService,
    protected notificationsService: NotificationsService,
    protected http: HttpClient,
    protected comparator: DefaultChangeAnalyzer<AtmireSavedItemList>,
    protected itemDataService: ItemDataService,
    protected storageService: LocalStorageService,
    protected authService: AuthService,
    protected savedItemListStoreService: AtmireSavedItemListStoreService) {
    super();
  }

  /**
   * Find the {@link AtmireSavedItemList} for the currently (un)authenticated user by retrieving its ID from the Store
   * and performing a findById()
   * If no ID is stored, use findMyListIgnoreStore()
   */
  findMyList(name: string = AtmireSavedItemListTypes.Default): Observable<RemoteData<AtmireSavedItemList>> {
    return observableCombineLatest(this.savedItemListStoreService.getStoredListState(), this.authService.getAuthenticatedUserOrNullFromStore()).pipe(
      // Filter observable to only emit when the list state's user ID matches the authenticated user ID (or null for anonymous). This avoids retrieving lists when the two are out of sync.
      filter(([state, user]: [AtmireSavedItemListState, EPerson]) => (isEmpty(state.currentUserID) && hasNoValue(user)) || (hasValue(user) && state.currentUserID === user.id)),
      map(([state, user]) => state.currentLists[name]),
      distinctUntilChanged(),
      switchMap((storedID) => {
        if (isNotEmpty(storedID)) {
          return this.findById(storedID);
        } else {
          return this.findMyListIgnoreStore(false, name);
        }
      })
    );
  }

  /**
   * Find the appropriate {@link AtmireSavedItemList} for the currently (un)authenticated user
   * Ignore the list ID stored in the ngrx store. Instead:
   *
   * This method first retrieves the name of the cookie under which the list ID should be stored for this user (or anonymous).
   * Using the found list ID, it retrieves the correct {@link AtmireSavedItemList} using findById.
   * If findById fails (404 response), it recalls this method with the skipCookie flag.
   * If no list ID was found in local storage and the user is authenticated, it searches for the correct list using the
   * search "byName" endpoint. If no list is found again, a new private list is created. The found or created list is saved in local storage.
   * If no list ID was found in local storage and the user is NOT authenticated (anonymous), a new public list is created
   * and saved in local storage.
   *
   * @param skipCookie  Whether or not we should skip fetching the list ID from local storage.
   *                    This is used recursively whenever a cookie contains an incorrect or outdated ID.
   */
  findMyListIgnoreStore(skipCookie: boolean = false, name: string = AtmireSavedItemListTypes.Default): Observable<RemoteData<AtmireSavedItemList>> {
    return this.authService.isAuthenticated().pipe(
      switchMap((authenticated) => this.getListIdCookieName().pipe(
        mergeMap((cookieName) => {
          const listIds = this.storageService.get(cookieName);
          if (!skipCookie && isNotEmpty(listIds) && hasValue(listIds[name])) {
            // The list ID exists in local storage. Attempt to retrieve the list using this ID.
            return this.findById(listIds[name]).pipe(
              getFirstCompletedRemoteData(),
              mergeMap((rd) => {
                if (hasValue(rd) && rd.statusCode === 404) {
                  // The list wasn't found, retry without using local storage. (The stored ID might be incorrect or outdated)
                  return this.findMyListIgnoreStore(true, name);
                } else {
                  // The list was found, return.
                  this.savedItemListStoreService.addListToStore(rd.payload);
                  return [rd];
                }
              })
            );
          } else if (authenticated) {
            // The user is authenticated, attempt to find the list using the "byName" search endpoint.
            return this.searchByName(name).pipe(
              getAllCompletedRemoteData(),
              mergeMap((rd) => {
                if (hasValue(rd) && rd.hasSucceeded && hasValue(rd.payload)) {
                  let cookieContent = this.storageService.get(cookieName);
                  if (isEmpty(cookieContent)) {
                    cookieContent = Object.assign({}, []);
                  }
                  cookieContent[rd.payload.name] = rd.payload.id;
                  this.storageService.set(cookieName, cookieContent);
                  this.savedItemListStoreService.addListToStore(rd.payload);
                  return this.findById(rd.payload.id);
                } else if (hasValue(rd) && (rd.statusCode === 404 || hasValue(rd.payload))) {
                  // The list wasn't found (404 or empty result). Create a new private list and save its ID.
                  return this.createAndSaveId(cookieName, name, false);
                } else {
                  // An unknown error occurred. Return undefined.
                  return [undefined];
                }
              })
            );
          } else {
            // The user isn't authenticated. Create a new public list and save its ID.
            return this.createAndSaveId(cookieName, name);
          }
        })
      ))
    );
  }

  findAllListsIgnoreStore(): Observable<[RemoteData<AtmireSavedItemList>]> {
    return this.authService.isAuthenticated().pipe(
      switchMap((authenticated) => this.getListIdCookieName().pipe(
        mergeMap((cookieName) => {
          if (authenticated) {
            return this.findAll().pipe(
              getAllCompletedRemoteData(),
              tap((rd) => {
                if (hasValue(rd) && rd.hasSucceeded && hasValue(rd.payload) && hasValue(rd.payload.page)) {
                  let cookieContent = this.storageService.get(cookieName);
                  if (isEmpty(cookieContent)) {
                    cookieContent = Object.assign({}, []);
                  }
                  rd.payload.page.forEach((list) => {
                    cookieContent[list.name] = list.id;
                  });
                  this.storageService.set(cookieName, cookieContent);
                }
              }),
              map((rd) => {
                if (hasValue(rd) && rd.hasSucceeded && hasValue(rd.payload) && hasValue(rd.payload.page)) {
                  return rd.payload.page.map((list) => new RemoteData(rd.timeCompleted, rd.msToLive, rd.lastUpdated, rd.state, rd.errorMessage, list, rd.statusCode));
                }
              }),
            );
          } else {
            const lists = this.storageService.get(cookieName);
            if (isNotEmpty(lists)) {
              const listIds = [];
              for (const name of Object.keys(lists)) {
                listIds.push(lists[name]);
              }
              // The list ID exists in local storage. Attempt to retrieve the list using this ID.
              return observableCombineLatest(listIds.map((listId) => {
                return this.findById(listId).pipe(
                  getAllCompletedRemoteData(),
                  mergeMap((rd) => {
                    if (hasValue(rd) && rd.statusCode === 404) {
                      // The list wasn't found, retry without using local storage. (The stored ID might be incorrect or outdated)
                      return this.findMyListIgnoreStore(true, name);
                    } else {
                      // The list was found, return.
                      return [rd];
                    }
                  })
                );
              }));
            } else {
              return [undefined];
            }
          }
        })
      ))
    );
  }

  /**
   * Find a {@link AtmireSavedItemList} by its cookie saved in local storage or create a new one if none exists (no cookie present or findById returns 404)
   * @param cookieName  The name of the cookie to find the {@link AtmireSavedItemList}'s ID at
   */
  findByCookie(cookieName: string, name: string): Observable<RemoteData<AtmireSavedItemList>> {
    const lists = this.storageService.get(cookieName);
    if (isNotEmpty(lists) && hasValue(lists[name])) {
      return this.findById(lists[name]);
    } else {
      return observableOf(null);
    }
  }

  /**
   * Create a new {@link AtmireSavedItemList} and save its ID into local storage
   *
   * This method will first check if a list for the same cookie is being created at this very moment through variable map "listsBeingCreated".
   * If this map contains a value, a list is already being created for the same cookie. As soon as the value emits "true",
   * the list has been created and added to local storage, meaning we can simply retrieve it using findByCookie().
   * If the map doesn't contain a value, we can safely start the creation of a new list.
   *
   * @param cookieName  The name of the cookie to save the new {@link AtmireSavedItemList}'s ID in
   * @param listName    The name of the new {@link AtmireSavedItemList}
   * @param pub         Whether or not the new {@link AtmireSavedItemList} should be public
   */
  private createAndSaveId(cookieName: string, listName: string, pub: boolean = true): Observable<RemoteData<AtmireSavedItemList>> {
    const list = Object.assign(new AtmireSavedItemList(), {name: listName, public: pub});
    let created$ = listsBeingCreated.get(`${cookieName}__${listName}`);
    if (hasValue(created$)) {
      return created$.pipe(
        filter((created) => created),
        switchMap(() => this.findByCookie(cookieName, listName))
      );
    } else {
      created$ = new BehaviorSubject<boolean>(false);
      listsBeingCreated.set(`${cookieName}__${listName}`, created$);
    }
    return this.create(list).pipe(
      getAllCompletedRemoteData(),
      tap((listRD) => {
        if (!created$.getValue() && hasValue(listRD) && listRD.hasSucceeded) {
          let cookieContent = this.storageService.get(cookieName);
          if (isEmpty(cookieContent)) {
            cookieContent = Object.assign({}, []);
          }
          cookieContent[listRD.payload.name] = listRD.payload.id;
          this.storageService.set(cookieName, cookieContent);
          this.savedItemListStoreService.addListToStore(listRD.payload);
          this.saveRecentList(listRD.payload);
          this.removeSearchByNameRequests(listRD.payload.name);
          created$.next(true);
        }
      })
    );
  }

  /**
   * Create a new {@link AtmireSavedItemList} by name for the current user
   * @param name  Name of the new list
   */
  createByName(name: string): Observable<RemoteData<AtmireSavedItemList>> {
    return this.authService.isAuthenticated().pipe(
      switchMap((authenticated) => this.getListIdCookieName().pipe(
        switchMap((cookieName) => this.createAndSaveId(cookieName, name, !authenticated))
      ))
    );
  }

  /**
   * Search {@link AtmireSavedItemList} by name
   * @param name          Name of the {@link AtmireSavedItemList} to find
   * @param linksToFollow List of {@link FollowLinkConfig} that indicate which {@link HALLink}s should be automatically resolved
   */
  searchByName(name: string, ...linksToFollow: FollowLinkConfig<AtmireSavedItemList>[]): Observable<RemoteData<AtmireSavedItemList>> {
    return this.getSearchByNameEndpoint(name, ...linksToFollow).pipe(
      switchMap((href) => this.findByHref(href, true, true, ...linksToFollow))
    );
  }

  private getSearchByNameEndpoint(name: string, ...linksToFollow: FollowLinkConfig<AtmireSavedItemList>[]): Observable<string> {
    return this.getSearchByHref('byName', {searchParams: [new RequestParam('name', name)]}, ...linksToFollow);
  }

  /**
   * Find items belonging to a list
   * @param list            The {@link AtmireSavedItemList} to find items for
   * @param findListOptions Options to limit/filter the search
   * @param linksToFollow   List of {@link FollowLinkConfig} that indicate which {@link HALLink}s should be automatically resolved
   */
  findItems(list: AtmireSavedItemList, findListOptions: FindListOptions = {}, ...linksToFollow: FollowLinkConfig<Item>[]): Observable<RemoteData<PaginatedList<Item>>> {
    return this.findItemsByListId(list.id, findListOptions, ...linksToFollow);
  }

  /**
   * Find items belonging to a list
   * @param listId          The ID of the {@link AtmireSavedItemList} to find items for
   * @param findListOptions Options to limit/filter the search
   * @param linksToFollow   List of {@link FollowLinkConfig} that indicate which {@link HALLink}s should be automatically resolved
   */
  findItemsByListId(listId: string, findListOptions: FindListOptions = {}, ...linksToFollow: FollowLinkConfig<Item>[]): Observable<RemoteData<PaginatedList<Item>>> {
    const options = Object.assign({}, findListOptions);
    if (isEmpty(options.searchParams)) {
      options.searchParams = [];
    }
    options.searchParams.push(new RequestParam('listId', listId));
    return this.itemDataService.getSearchByHref(this.itemsByListPath, options, ...linksToFollow).pipe(
      // Switch to an observable that fires whenever the request enters the store.
      // This allows the list to properly reload when it gets removed from the store.
      switchMap((href) => this.requestService.hasByHref$(href)),
      switchMap(() => this.itemDataService.searchBy(this.itemsByListPath, options, true, true, ...linksToFollow))
    );
  }

  /**
   * Count the amount of total items contained within a list by sending out a search request for a single item and
   * returning the list's totalElements
   * @param list  The {@link AtmireSavedItemList} to count items for
   */
  countItems(list: AtmireSavedItemList): Observable<number> {
    return this.countItemsByListId(list.id);
  }

  /**
   * Count the amount of total items contained within a list by sending out a search request for a single item and
   * returning the list's totalElements
   * @param listId  The ID of the {@link AtmireSavedItemList} to count items for
   */
  countItemsByListId(listId: string): Observable<number> {
    return this.findItemsByListId(listId, {elementsPerPage: 1}).pipe(
      getAllSucceededRemoteDataPayload(),
      map((items) => items.totalElements),
      startWith(0)
    );
  }

  addItemByListId(listId: string, item: Item) {
    return this.findById(listId).pipe(
      getFirstSucceededRemoteDataPayload(),
      switchMap((list) => this.addItem(list, item)),
    );
  }

  /**
   * Add an {@link Item} to a {@link AtmireSavedItemList}
   * This automatically refreshes the list's item request, allowing the list to be reloaded
   * @param list  The {@link AtmireSavedItemList} to add an {@link Item} to
   * @param item  The {@link Item} to add to the {@link AtmireSavedItemList}
   */
  addItem(list: AtmireSavedItemList, item: Item): Observable<RemoteData<AtmireSavedItemList>> {
    const requestId = this.requestService.generateRequestId();
    const options: HttpOptions = Object.create({});
    let headers = new HttpHeaders();
    headers = headers.append('Content-Type', 'text/uri-list');
    options.headers = headers;
    this.requestService.send(new PostRequest(requestId, list._links.items.href, item._links.self.href.split('?')[0], options));

    return this.toRemoteDataFromItemActionAndSaveRecentList(list, requestId, item);
  }

  removeItemByListId(listId: string, item: Item) {
    return this.findById(listId).pipe(
      getFirstSucceededRemoteDataPayload(),
      switchMap((list) => this.removeItem(list, item)),
    );
  }

  /**
   * Remove an {@link Item} from a {@link AtmireSavedItemList}
   * This automatically refreshes the list's item request, allowing the list to be reloaded
   * @param list  The {@link AtmireSavedItemList} to remove an {@link Item} from
   * @param item  The {@link Item} to remove from the {@link AtmireSavedItemList}
   */
  removeItem(list: AtmireSavedItemList, item: Item): Observable<RemoteData<AtmireSavedItemList>> {
    const requestId = this.requestService.generateRequestId();
    this.requestService.send(new DeleteRequest(requestId, `${list._links.items.href}/${item.uuid}`));
    return this.toRemoteDataFromItemActionAndSaveRecentList(list, requestId, item);
  }

  /**
   * Clear all {@link Item}s within a {@link AtmireSavedItemList}
   * @param list  The {@link AtmireSavedItemList} to clear all items from
   */
  clearList(list: AtmireSavedItemList): Observable<RemoteData<AtmireSavedItemList>> {
    const requestId = this.requestService.generateRequestId();
    const options: HttpOptions = Object.create({});
    let headers = new HttpHeaders();
    headers = headers.append('Content-Type', 'text/uri-list');
    options.headers = headers;
    this.requestService.send(new PutRequest(requestId, list._links.items.href, '', options));

    return this.toRemoteDataFromItemAction(requestId);
  }

  /**
   * Add multiple items using discovery options
   * @param list          The {@link AtmireSavedItemList} to add items to
   * @param searchOptions The {@link SearchOptions} used to retrieve the items to add
   */
  addDiscoveryItems(list: AtmireSavedItemList, searchOptions: SearchOptions): Observable<RemoteData<AtmireSavedItemList>> {
    const requestId = this.requestService.generateRequestId();
    this.requestService.send(new PostRequest(requestId, searchOptions.toRestUrl(list._links.items.href, ['type=discovery'])));

    return this.toRemoteDataFromItemAction(requestId);
  }

  /**
   * Delete a {@link AtmireSavedItemList}
   * Remove the list from Store upon successful response
   * @param objectId
   * @param copyVirtualMetadata
   */
  delete(objectId: string, copyVirtualMetadata?: string[]): Observable<RemoteData<NoContent>> {
    return super.delete(objectId, copyVirtualMetadata).pipe(
      tap((rd) => {
        if (rd.hasSucceeded) {
          this.removeListFromCookie(objectId);
          this.removeRecentList();
          this.savedItemListStoreService.deleteListFromStore(objectId);
        }
      })
    );
  }

  /**
   * Turn an item(s) update request into a RemoteData and save the list's ID as the most recently used
   * @param listId    The list's ID
   * @param requestId The request's ID
   * @param items     The modified items (added or removed from the list)
   */
  private toRemoteDataFromItemActionAndSaveRecentList(list: AtmireSavedItemList, requestId: string, ...items: Item[]): Observable<RemoteData<AtmireSavedItemList>> {
    const result$ = this.toRemoteDataFromItemAction(requestId, ...items);

    // Save the list ID as the most recently used
    result$.pipe(getFirstCompletedRemoteData()).subscribe(() => this.saveRecentList(list));

    return result$;
  }

  /**
   * Turn an item(s) update request into a RemoteData
   * This method also removes relevant requests from cache when the item(s) request completes
   * @param requestId The request's ID
   * @param items     The modified items (added or removed from the list)
   */
  private toRemoteDataFromItemAction(requestId: string, ...items: Item[]): Observable<RemoteData<AtmireSavedItemList>> {
    const result$ = this.rdbService.buildFromRequestUUID<AtmireSavedItemList>(requestId);

    // Clear related requests to allow for lists to reload
    result$.pipe(getFirstCompletedRemoteData()).subscribe(() => this.removeRequests(items));

    return result$;
  }

  /**
   * Remove requests from cache, related to a list and its modified item
   * @param items  The modified items (added or removed from the list)
   */
  private removeRequests(items: Item[] = []) {
    this.itemDataService.getSearchEndpoint(this.itemsByListPath).pipe(
      take(1)
    ).subscribe((href) => {
      // Remove the cached requests for the 'itemsByList' search endpoint.
      // This will ensure that the item count stays up-to-date, as it uses this endpoint
      this.requestService.setStaleByHrefSubstring(href);
    });

    items.forEach((item) => {
      // Remove the cached request responsible for caching our item.
      // This will make sure our item is re-cached whenever it's requested, ensuring an up-to-date savedLists property
      this.requestService.setStaleByHrefSubstring(item._links.self.href);
    });
  }

  removeSearchByNameRequests(name: string) {
    this.getSearchByNameEndpoint(name).pipe(take(1)).subscribe((href) => {
      this.requestService.setStaleByHrefSubstring(href);
    });
  }

  removeBaseRequests() {
    this.getEndpoint().pipe(take(1)).subscribe((href) => this.requestService.setStaleByHrefSubstring(href));
  }

  /**
   * Save the most recently used list into local storage
   */
  saveRecentList(list: AtmireSavedItemList) {
    this.getRecentListCookieName().pipe(take(1)).subscribe((cookieName) => {
      this.storageService.set(cookieName, new SavedItemListStorage(list.id, list.name));
    });
  }

  removeRecentList() {
    this.getRecentListCookieName().pipe(take(1)).subscribe((cookieName) => {
      this.storageService.remove(cookieName);
    });
  }

  removeListFromCookie(listId: string) {
    this.getListIdCookieName().pipe(take(1)).subscribe((cookieName) => {
      const cookieContent = Object.assign({}, this.storageService.get(cookieName));
      const names = Object.entries(cookieContent).filter(([name, id]) => id === listId).map(([name, id]) => name);
      names.forEach((name) => {
        delete cookieContent[name];
      });
      this.storageService.set(cookieName, cookieContent);
    });
  }

  /**
   * Get the most recently used list's ID
   */
  getRecentList(): Observable<SavedItemListStorage> {
    return this.getRecentListCookieName().pipe(
      switchMap((cookieName) => this.storageService.watch(cookieName))
    );
  }

  /**
   * Get the cookie's name to store the most recent {@link AtmireSavedItemList}'s ID into
   * This name is dependent on the currently authenticated user
   */
  private getRecentListCookieName(): Observable<string> {
    return this.getCookieName(STORAGE_ATMIRE_RECENT_LIST_PREFIX);
  }

  /**
   * Get the cookie's name to store the {@link AtmireSavedItemList}'s ID into
   * This name is dependent on the currently authenticated user
   */
  private getListIdCookieName(): Observable<string> {
    return this.getCookieName(STORAGE_ATMIRE_LIST_ID_PREFIX);
  }

  /**
   * Get the cookie's name for a given prefix
   * This name is dependent on the currently authenticated user
   */
  private getCookieName(prefix: string): Observable<string> {
    return this.authService.getAuthenticatedUserOrNullFromStore().pipe(
      map((user) => hasValue(user) ? `${prefix}${user.id}` : `${prefix}anonymous`)
    );
  }

  /**
   * Get the maximum allowed amount of lists per person from the environment configuration property "atmire.savedItemLists.maxNbLists"
   * Defaults to 1 for unauthenticated users
   */
  getMaxNbLists(): Observable<number> {
    return this.authService.isAuthenticated().pipe(
      map((authenticated) => {
        if (authenticated) {
          const maxNbLists = environment.atmire.savedItemLists.maxNbLists;
          if (!Number.isInteger(maxNbLists) && maxNbLists < 0) {
            console.error(`Expected configured property "atmire.savedItemLists.maxNbLists" to be a positive integer, but found: ${maxNbLists}`);
            return 0;
          } else {
            return maxNbLists;
          }
        } else {
          return 1;
        }
      })
    );
  }

  /**
   * Overwritten create method from DataService to remove error notifications
   */
  create(object: AtmireSavedItemList, ...params: RequestParam[]): Observable<RemoteData<AtmireSavedItemList>> {
    const requestId = this.requestService.generateRequestId();
    const endpoint$ = this.getEndpoint().pipe(
      isNotEmptyOperator(),
      distinctUntilChanged(),
      map((endpoint: string) => this.buildHrefWithParams(endpoint, params))
    );

    const serializedObject = new DSpaceSerializer(getClassForType(object.type)).serialize(object);

    endpoint$.pipe(
      take(1)
    ).subscribe((endpoint: string) => {
      const request = new CreateRequest(requestId, endpoint, JSON.stringify(serializedObject));
      if (hasValue(this.responseMsToLive)) {
        request.responseMsToLive = this.responseMsToLive;
      }
      this.requestService.send(request);
    });

    return this.rdbService.buildFromRequestUUID<AtmireSavedItemList>(requestId);
  }
}
/* tslint:enable:max-classes-per-file */
