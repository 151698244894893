<div ngbNav *ngIf="relationTypes.length > 1" [destroyOnHide]="true" #nav="ngbNav" [activeId]="activeTab$ | async" (navChange)="onTabChange($event)">
  <div ngbNavItem *ngFor="let relationType of relationTypes" [id]="relationType.filter">
    <a ngbNavLink>{{'item.page.relationships.' + relationType.label | translate}}</a>
    <ng-template ngbNavContent>
      <div class="mt-4">
        <ds-related-entities-search [item]="item"
                                    [relationType]="relationType.filter"
                                    [configuration]="relationType.configuration"
                                    [searchEnabled]="searchEnabled"
                                    [sideBarWidth]="sideBarWidth">
        </ds-related-entities-search>
      </div>
    </ng-template>
  </div>
</div>
<div *ngIf="relationTypes.length === 1" class="mt-4">
  <ds-related-entities-search *ngVar="relationTypes[0] as relationType" [item]="item"
                              [relationType]="relationType.filter"
                              [configuration]="relationType.configuration"
                              [searchEnabled]="searchEnabled"
                              [sideBarWidth]="sideBarWidth">
  </ds-related-entities-search>
</div>
