<div class="item-page-field">
  <ds-metadata-field-wrapper [label]="label | translate">
    <ng-container *ngFor="let data of getData(); let last=last;">
      <!-- display link -->
      <a *ngIf="data[0]" [href]="data[0]">
        {{ data[1] || data[0] }}
      </a>

      <!-- display plain text -->
      <span *ngIf="!data[0]">
        {{ data[1] }}
      </span>

      <!-- separator -->
      <span *ngIf="!last" [innerHTML]="separator"></span>
    </ng-container>
  </ds-metadata-field-wrapper>
</div>
