import { Component, OnInit } from '@angular/core';
import { ItemPageFieldComponent } from '../item-page-field.component';
import { isEmpty, isNotEmpty } from '../../../../../shared/empty.util';

@Component({
  selector: 'ds-item-page-author-discovery-link-field',
  templateUrl: 'item-page-author-discovery-link-field.component.html'
})
export class ItemPageAuthorDiscoveryLinkFieldComponent extends ItemPageFieldComponent implements OnInit {
  elementsId: string;
  melbourneAuthors: string[];
  melbourneAuthorsOverMax = false;
  authors: string[];
  authorsOverMax = false;

  renderMelbourneAuthors = false;

  /**
   * The maximum allowed amount of authors to display at once
   */
  maxAuthors = 10;

  label = 'item.page.author';
  separator = '; ';

  ngOnInit(): void {
    this.elementsId = this.item.firstMetadataValue('melbourne.elementsid');

    const allMelbourneAuthors = this.item.allMetadataValues('melbourne.contributor.author');
    this.melbourneAuthorsOverMax = allMelbourneAuthors.length > this.maxAuthors;
    this.melbourneAuthors = allMelbourneAuthors.slice(0, this.maxAuthors);

    const allAuthors = this.item.allMetadataValues('dc.contributor.author');
    this.authorsOverMax = allAuthors.length > this.maxAuthors;
    this.authors = allAuthors.slice(0, this.maxAuthors);

    this.renderMelbourneAuthors = isEmpty(this.elementsId) && isNotEmpty(this.melbourneAuthors);
  }
}
