<div class="card" [@focusShadow]="(isCollapsed$ | async)?'blur':'focus'">
  <ds-truncatable [id]="dso.id">
    <div class="position-absolute ml-1">
      <ng-content></ng-content>
    </div>
    <a *ngIf="linkType != linkTypes.None"
       [target]="(linkType == linkTypes.ExternalLink) ? '_blank' : '_self'"
       [routerLink]="[itemPageRoute]" [state]="getState(object)" [attr.rel]="(linkType == linkTypes.ExternalLink) ? 'noopener' : null"
       class="card-img-top full-width">
      <div>
        <ds-thumbnail [thumbnail]="dso?.thumbnail | async" [limitWidth]="false">
        </ds-thumbnail>
      </div>
    </a>
    <span *ngIf="linkType == linkTypes.None" class="card-img-top full-width">
            <div>
                <ds-thumbnail [thumbnail]="dso?.thumbnail | async" [limitWidth]="false">
                </ds-thumbnail>
            </div>
        </span>
    <div class="card-body">
      <ds-type-badge *ngIf="showLabel" [object]="dso"></ds-type-badge>
      <ds-truncatable-part [id]="dso.id" [minLines]="3" type="h4">
        <h4 class="card-title"
            [innerHTML]="firstMetadataValue('person.familyName') + ', ' + firstMetadataValue('person.givenName')"></h4>
      </ds-truncatable-part>
      <p *ngIf="dso.hasMetadata('person.email')" class="item-email card-text text-muted">
        <ds-truncatable-part [id]="dso.id" [minLines]="1">
          <span [innerHTML]="firstMetadataValue('person.email')"></span>
        </ds-truncatable-part>
      </p>
      <p *ngIf="dso.hasMetadata('person.jobTitle')" class="item-jobtitle card-text">
        <ds-truncatable-part [id]="dso.id" [minLines]="3">
          <span [innerHTML]="firstMetadataValue('person.jobTitle')"></span>
        </ds-truncatable-part>
      </p>
      <div class="d-flex justify-content-center">
        <div *ngIf="linkType != linkTypes.None" class="text-center">
          <a [target]="(linkType == linkTypes.ExternalLink) ? '_blank' : '_self'"
             [routerLink]="[itemPageRoute]" [state]="getState(object)" [attr.rel]="(linkType == linkTypes.ExternalLink) ? 'noopener' : null"
             class="lead btn btn-primary viewButton">View</a>
        </div>
        <div [ngClass]="{'ml-2': linkType != linkTypes.None}">
          <ds-atmire-saved-item-list-control *ngIf="savedItemListControlEnabled" [item]="dso" [useLargeButton]="true" [context]="context"></ds-atmire-saved-item-list-control>
        </div>
      </div>
    </div>
  </ds-truncatable>
  <ng-content></ng-content>
</div>
