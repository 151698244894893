<div class="d-flex flex-row">
  <h2 class="item-page-title-field mr-auto">
    {{'journalvolume.page.titleprefix' | translate}}<ds-metadata-values [mdValues]="object?.allMetadata(['dc.title'])"></ds-metadata-values>
  </h2>
  <div class="pl-2 d-flex flex-row">
    <ds-atmire-saved-item-list-control class="mr-1" [item]="object"></ds-atmire-saved-item-list-control>
    <ds-atmire-item-export-dropdown class="mr-1" [item]="object" [compact]="true"></ds-atmire-item-export-dropdown>
    <ds-dso-page-edit-button [pageRoute]="itemPageRoute" [dso]="object" [tooltipMsg]="'journalvolume.page.edit'"></ds-dso-page-edit-button>
    <ds-dso-page-preservica-button [dso]="object" [tooltipMsg]="'journalvolume.page.send-to-preservica'"></ds-dso-page-preservica-button>
  </div>
</div>
<div class="row mb-4">
  <div class="col-xs-12 col-md-4">
    <ds-metadata-field-wrapper [hideIfNoTextContent]="false">
      <ds-thumbnail [thumbnail]="object?.thumbnail | async"></ds-thumbnail>
    </ds-metadata-field-wrapper>
    <ds-citations-section-item-page [item]="object"
                                    [labelTitle]="'publications.citations-section.title'">
    </ds-citations-section-item-page>
    <ds-generic-item-page-field [item]="object"
      [fields]="['publicationvolume.volumeNumber']"
      [label]="'journalvolume.page.volume'">
    </ds-generic-item-page-field>
    <ds-generic-item-page-field [item]="object"
      [fields]="['creativework.datePublished']"
      [label]="'journalvolume.page.issuedate'">
    </ds-generic-item-page-field>
  </div>
  <div class="col-xs-12 col-md-6">
    <ds-related-items
      [parentItem]="object"
      [relationType]="'isJournalOfVolume'"
      [label]="'relationships.isSingleJournalOf' | translate">
    </ds-related-items>
    <ds-related-items
      [parentItem]="object"
      [relationType]="'isIssueOfJournalVolume'"
      [label]="'relationships.isIssueOf' | translate">
    </ds-related-items>
    <ds-generic-item-page-field [item]="object"
      [fields]="['dc.description']"
      [label]="'journalvolume.page.description'">
    </ds-generic-item-page-field>
    <div>
      <a class="btn btn-outline-primary" [routerLink]="[itemPageRoute + '/full']">
        {{"item.page.link.full" | translate}}
      </a>
    </div>
  </div>
</div>
<ds-atmire-cua-statlets-section [dso]="object"></ds-atmire-cua-statlets-section>
