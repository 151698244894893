<ul class="navbar-nav" [ngClass]="{'mr-auto': (isXsOrSm$ | async)}">
    <li *ngIf="!(isAuthenticated | async) && !(isXsOrSm$ | async) && (showAuth | async)" class="nav-item"
        (click)="$event.stopPropagation();">
        <div ngbDropdown #loginDrop display="dynamic" placement="bottom-right" class="header-button d-flex" @fadeInOut>
            <a class="dropdownLogin btn w-100 d-flex flex-column align-items-center justify-content-center" href="#" (click)="$event.preventDefault()"
               ngbDropdownToggle>{{ 'nav.login' | translate }}</a>
            <div class="loginDropdownMenu" [ngClass]="{'pl-3 pr-3': (loading | async)}" ngbDropdownMenu
                 [attr.aria-label]="'nav.login' |translate">
                <ds-log-in
                        [isStandalonePage]="false"></ds-log-in>
            </div>
        </div>
    </li>
    <li *ngIf="!(isAuthenticated | async) && (isXsOrSm$ | async)" class="nav-item header-button d-flex">
        <a class="loginLink btn w-100 d-flex flex-column align-items-center justify-content-center" routerLink="/login" routerLinkActive="active">
            {{ 'nav.login' | translate }}<span class="sr-only">(current)</span>
        </a>
    </li>
    <li *ngIf="(isAuthenticated | async) && !(isXsOrSm$ | async) && (showAuth | async)" class="nav-item">
        <div ngbDropdown display="dynamic" placement="bottom-right" class="header-button d-flex" @fadeInOut>
            <a class="btn w-100 d-flex flex-column align-items-center justify-content-center" href="#" role="button" [attr.aria-label]="'nav.logout' |translate"
               (click)="$event.preventDefault()" [title]="'nav.logout' | translate" ngbDropdownToggle>
                <i class="fas fa-user-circle fa-lg fa-fw"></i></a>
      <div class="logoutDropdownMenu" ngbDropdownMenu [attr.aria-label]="'nav.logout' |translate">
                <ds-user-menu></ds-user-menu>
            </div>
        </div>
    </li>
    <li *ngIf="(isAuthenticated | async) && (isXsOrSm$ | async)" class="nav-item">
        <div class=" header-button d-flex">
            <a class="btn w-100 d-flex flex-column align-items-center justify-content-center" id="logoutLink" role="button" [attr.aria-label]="'nav.logout' |translate"
               [title]="'nav.logout' | translate" routerLink="/logout" routerLinkActive="active">
                <i class="fas fa-user-circle fa-lg fa-fw"></i>
                <span class="sr-only">(current)</span>
            </a>
        </div>
    </li>
</ul>


