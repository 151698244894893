import { Component, Input } from '@angular/core';

import { Item } from '../../../../../core/shared/item.model';
import { ItemPageFieldComponent } from '../item-page-field.component';

@Component({
  selector: 'ds-item-page-uri-field',
  templateUrl: './item-page-uri-field.component.html'
})
/**
 * This component can be used to represent any uri on a simple item page.
 * It expects 4 parameters: The item, a separator, the metadata keys and an i18n key
 * 2 optional parameters: prefixValue and addPrefixIfDoesNotContains, if the value doesn't contain the second, prefix
 * value with the first
 */
export class ItemPageUriFieldComponent extends ItemPageFieldComponent {

  /**
   * The item to display metadata for
   */
  @Input() item: Item;

  /**
   * Separator string between multiple values of the metadata fields defined
   * @type {string}
   */
  @Input() separator: string;

  /**
   * Fields (schema.element.qualifier) used to render their values.
   */
  @Input() fields: string[];

  /**
   * Label i18n key for the rendered metadata
   */
  @Input() label: string;

  /**
   * If the actual metadata value needs to be prefixed with something to form the url, prefix with it if original
   * value does not contain any of addPrefixIfDoesNotContains
   * Ex: addPrefixIfDoesNotContains=['http://dx.doi.org/', 'doi.org'] => If the original value contains none of these,
   * the prepend original value with prefix
   */
  @Input() prefixValue: string;

  /**
   * If the actual metadata value needs to be prefixed with something to form the url, prefix with first values,
   * if none of the values are present
   * Ex: addPrefixIfDoesNotContains=['http://dx.doi.org/', 'doi.org'] => If the original value contains none of these,
   * the prepend original value with prefix
   */
  @Input() addPrefixIfDoesNotContains: string[];

}
