import { Component, Input } from '@angular/core';
import { AbstractAtmireSearchListElementComponent } from '../../list-element/abstract-atmire-search-list-element.component';
import { VocabularyEntry } from '../../../../../../../../core/submission/vocabularies/models/vocabulary-entry.model';
import { MetadataMap, MetadataValue } from '../../../../../../../../core/shared/metadata.models';
import { hasNoValue, hasValue, isEmpty } from '../../../../../../../empty.util';
import { Metadata } from '../../../../../../../../core/shared/metadata.utils';
import { slide } from '../../../../../../../animations/slide';

@Component({
  selector: 'ds-atmire-search-vocabulary-entry-list-element',
  styleUrls: ['./atmire-search-vocabulary-entry-list-element.component.scss'],
  templateUrl: './atmire-search-vocabulary-entry-list-element.component.html',
  animations: [slide],
})
export class AtmireSearchVocabularyEntryListElementComponent extends AbstractAtmireSearchListElementComponent<VocabularyEntry> {
  @Input() metadata: MetadataMap;
  @Input() title: string;

  /**
   * The metadata value for the object's first name
   */
  firstName: MetadataValue;

  /**
   * The metadata value for the object's last name
   */
  lastName: MetadataValue;

  /**
   * The metadata value for the object's orcid
   */
  orcid: MetadataValue;

  /**
   * The metadata value for the object's uri
   */
  uri: MetadataValue;

  /**
   * Extra Melbourne metadata
   */
  email: MetadataValue;
  proprietaryId: MetadataValue;

  /**
   * Whether or not the extra metadata section and its toggle should be visible
   * Setting this to false will hide the extra metadata toggle and section
   */
  extraMetadataSectionEnabled = true;

  /**
   * Whether or not the metadata section is expanded
   * Only relevant if extraMetadataSectionEnabled is true
   */
  expanded = false;

  /**
   * Whether or not the object has extra metadata to show
   * If false, the collapse button will be hidden
   * Only relevant if extraMetadataSectionEnabled is true
   */
  hasExtraMetadata = false;

  ngOnInit(): void {
    if (hasValue(this.element)) {
      if (hasNoValue(this.metadata)) {
        this.metadata = this.element.object.metadata;
      }
      if (isEmpty(this.title)) {
        this.title = this.element.selectedValue;
      }
    }

    if (hasValue(this.metadata)) {
      this.firstName = Metadata.first(this.metadata, 'person.givenName');
      this.lastName = Metadata.first(this.metadata, 'person.familyName');
      // this.orcid = Metadata.first(this.metadata, 'person.identifier.orcid');
      // this.uri = Metadata.first(this.metadata, 'dc.identifier.uri');
      this.email = Metadata.first(this.metadata, 'person.email');
      this.proprietaryId = Metadata.first(this.metadata, 'person.identifier.proprietary');

      this.hasExtraMetadata = hasValue(this.firstName) || hasValue(this.lastName) || hasValue(this.orcid)
        || hasValue(this.uri) || hasValue(this.email) || hasValue(this.proprietaryId);
    }
  }
}
