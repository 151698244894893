import { Injectable } from '@angular/core';
import { Bitstream } from '../core/shared/bitstream.model';
import { RequestService } from '../core/data/request.service';
import { PostRequest } from '../core/data/request.models';
import { RemoteDataBuildService } from '../core/cache/builders/remote-data-build.service';
import { Observable } from 'rxjs/internal/Observable';
import { RemoteData } from '../core/data/remote-data';

@Injectable({
  providedIn: 'root'
})
export class ExtendEmbargoService {

  constructor(
    protected requestService: RequestService,
    protected rdbService: RemoteDataBuildService,
  ) {}

  /**
   * Extend the embargo of the given bitstream.
   * @param bitstream the bitstream.
   */
  public extendEmbargo(bitstream: Bitstream): Observable<RemoteData<Bitstream>> {
    const requestId = this.requestService.generateRequestId();
    // NOTE: bitstream id is provided in self link
    const endpoint = `${bitstream.self}/extendEmbargo`;
    const extendRequest = new PostRequest(requestId, endpoint);
    this.requestService.send(extendRequest);
    return this.rdbService.buildFromRequestUUID<Bitstream>(requestId);
  }

}
