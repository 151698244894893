import { Component } from '@angular/core';
import { CitationsSectionComponent } from '../../../shared/citation/citations-section.component';
import { of as observableOf } from 'rxjs';

/**
 * Displays a list of CitationValue objects of an item on the item page
 */
@Component({
  selector: 'ds-citations-section-item-page',
  templateUrl: './citations-section-item-page.component.html'
})
export class CitationsSectionItemPageComponent extends CitationsSectionComponent {

  ngOnInit() {
    super.shouldShowCitations$ = observableOf(true);
    super.ngOnInit();
  }

}
