import { AtmireSelectiveExportOption } from './models/atmire-selective-export-option.model';
import { environment } from '../../environments/environment';
import { AuthService } from '../core/auth/auth.service';
import { ScriptDataService } from '../core/data/processes/script-data.service';
import { Observable } from 'rxjs/internal/Observable';
import { RemoteData } from '../core/data/remote-data';
import { Process } from '../process-page/processes/process.model';
import { switchMap } from 'rxjs/operators';
import { EPerson } from '../core/eperson/models/eperson.model';
import { ProcessParameter } from '../process-page/processes/process-parameter.model';
import { hasValue, isNotEmpty } from '../shared/empty.util';
import { SearchFilter } from '../shared/search/search-filter.model';
import { NotificationsService } from '../shared/notifications/notifications.service';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';
import { Item } from '../core/shared/item.model';
import { PaginatedSearchOptions } from '../shared/search/paginated-search-options.model';

export const ITEM_EXPORT_SCRIPT_NAME = 'item-export';

/**
 * Get the export options from the current environment
 */
export function getAtmireSelectiveExportOptions(): AtmireSelectiveExportOption[] {
  return environment.atmire.export.formats.map((format: any) =>
    new AtmireSelectiveExportOption(format.label, format.id, format.extension, format.fileName, format.disabled));
}

/**
 * Invoke the item-export script with search-configuration arguments
 * @param authService       The {@link AuthService} to retrieve the current user
 * @param scriptDataService The {@link ScriptDataService} to invoke the script
 * @param exportOption      The {@link AtmireSelectiveExportOption} containing the format to export items to
 * @param searchConfig      The {@link PaginatedSearchOptions} containing search-configuration arguments
 */
export function invokeItemExportScriptBySearchConfig(authService: AuthService, scriptDataService: ScriptDataService,
                                                     exportOption: AtmireSelectiveExportOption, searchConfig: PaginatedSearchOptions): Observable<RemoteData<Process>> {
  return authService.getAuthenticatedUserFromStore().pipe(
    switchMap((user) =>
      scriptDataService.invoke(ITEM_EXPORT_SCRIPT_NAME, createItemExportScriptParamsBySearchConfig(exportOption, user, searchConfig), [])
    )
  );
}

/**
 * Invoke the item-export script with item arguments
 * @param authService       The {@link AuthService} to retrieve the current user
 * @param scriptDataService The {@link ScriptDataService} to invoke the script
 * @param exportOption      The {@link AtmireSelectiveExportOption} containing the format to export items to
 * @param item              The {@link Item} to export
 */
export function invokeItemExportScriptByItem(authService: AuthService, scriptDataService: ScriptDataService,
                                             exportOption: AtmireSelectiveExportOption, item: Item): Observable<RemoteData<Process>> {
  return authService.getAuthenticatedUserFromStore().pipe(
    switchMap((user) =>
      scriptDataService.invoke(ITEM_EXPORT_SCRIPT_NAME, createItemExportScriptParamsByItem(exportOption, user, item), [])
    )
  );
}

/**
 * Invoke the item-export script with search-configuration arguments
 * @param authService       The {@link AuthService} to retrieve the current user
 * @param scriptDataService The {@link ScriptDataService} to invoke the script
 * @param exportOption      The {@link AtmireSelectiveExportOption} containing the format to export items to
 * @param itemIds           The list of IDs of the items to export
 */
export function invokeItemExportScriptByIds(authService: AuthService, scriptDataService: ScriptDataService,
                                            exportOption: AtmireSelectiveExportOption, itemIds: string[]): Observable<RemoteData<Process>> {
  return authService.getAuthenticatedUserFromStore().pipe(
    switchMap((user) =>
      scriptDataService.invoke(ITEM_EXPORT_SCRIPT_NAME, createItemExportScriptParamsByIds(exportOption, user, itemIds), [])
    )
  );
}

/**
 * Create arguments to provide to the item-export script for a search-configuration
 * @param exportOption  The {@link AtmireSelectiveExportOption} containing the format to export items to
 * @param user          The {@link EPerson} exporting items
 * @param searchConfig  The {@link PaginatedSearchOptions} containing search-configuration arguments
 */
export function createItemExportScriptParamsBySearchConfig(exportOption: AtmireSelectiveExportOption, user: EPerson, searchConfig: PaginatedSearchOptions): ProcessParameter[] {
  const params = createItemExportScriptBaseParams(exportOption, user);
  if (isNotEmpty(searchConfig.query)) {
    // Adding quotes around the query solves an issue with command-line parser in REST:
    // REST removes quoted command line arguments. Adding an extra pair of quotes fixes this issue, allowing for our
    // query to be passed with its original quotes.
    params.push({ name: '-q', value: `"${searchConfig.query}"` });
  }
  if (isNotEmpty(searchConfig.sort)) {
    params.push({ name: '-s', value: `${searchConfig.sort.field},${searchConfig.sort.direction}` });
  }
  if (isNotEmpty(searchConfig.configuration)) {
    params.push({ name: '-c', value: searchConfig.configuration });
  }
  if (isNotEmpty(searchConfig.scope)) {
    params.push({ name: '-sc', value: searchConfig.scope });
  }
  if (isNotEmpty(searchConfig.filters)) {
    searchConfig.filters.forEach((filter: SearchFilter) => {
      filter.values.forEach((value) => {
        const filterKey = filter.key.startsWith('f.') ? filter.key.substring(2, filter.key.length) : filter.key;
        const filterValue = value.includes(',') ? `${value}` : value + (filter.operator ? ',' + filter.operator : '');
        params.push({ name: '-fq', value: `${filterKey}:(${filterValue})` });
      });
    });
  }
  if (isNotEmpty(searchConfig.fixedFilter)) {
    const filterKey = searchConfig.fixedFilter.substring(2, searchConfig.fixedFilter.length).split('=')[0];
    const filterValue = searchConfig.fixedFilter.substring(2, searchConfig.fixedFilter.length).split('=')[1];
    params.push({ name: '-fq', value: `${filterKey}:(${filterValue})` });
  }
  return params;
}

/**
 * Create arguments to provide to the item-export script for an item
 * @param exportOption  The {@link AtmireSelectiveExportOption} containing the format to export items to
 * @param user          The {@link EPerson} exporting items
 * @param item          The {@link Item} to export
 */
export function createItemExportScriptParamsByItem(exportOption: AtmireSelectiveExportOption, user: EPerson, item: Item): ProcessParameter[] {
  const params = createItemExportScriptBaseParams(exportOption, user);
  params.push({ name: '-i', value: item.id });
  return params;
}

/**
 * Create arguments to provide to the item-export script for a list of item IDs
 * @param exportOption  The {@link AtmireSelectiveExportOption} containing the format to export items to
 * @param user          The {@link EPerson} exporting items
 * @param itemIds       The list of IDs of the items to export
 */
export function createItemExportScriptParamsByIds(exportOption: AtmireSelectiveExportOption, user: EPerson, itemIds: string[]): ProcessParameter[] {
  return [
    ...createItemExportScriptBaseParams(exportOption, user),
    { name: '-i', value: itemIds.join(',') },
  ];
}

/**
 * Create base arguments to provide to the item-export script
 * @param exportOption  The {@link AtmireSelectiveExportOption} containing the format to export items to
 * @param user          The {@link EPerson} exporting items
 */
export function createItemExportScriptBaseParams(exportOption: AtmireSelectiveExportOption, user: EPerson) {
  return [
    { name: '-p', value: exportOption.getFileName() },
    { name: '-f', value: exportOption.label },
    { name: '-e', value: user.email },
  ];
}

/**
 * Handle the response ({@link RequestEntry}) of an invoked item-export script by displaying notifications and/or
 * redirecting the user to the created process's page.
 * @param notificationsService  The {@link NotificationsService} to display notifications with
 * @param translate             The {@link TranslateService} to translate messages
 * @param router                The {@link Router} to navigate the user to the process's page on success
 * @param rd                    The response ({@link RemoteData}) of the invoked script
 */
export function handleItemExportResponse(notificationsService: NotificationsService, translate: TranslateService,
                                         router: Router, rd: RemoteData<Process>) {
  if (rd.hasSucceeded) {
    notificationsService.success(
      translate.get('process.new.notification.success.title'),
      translate.get('process.new.notification.success.content')
    );
    if (hasValue(rd.payload)) {
      router.navigateByUrl('/exports/' + rd.payload.processId + '?download=true');
    }
  } else if (rd.hasFailed) {
    notificationsService.error(
      translate.get('process.new.notification.error.title'),
      translate.get('process.new.notification.error.content')
    );
  }
}
