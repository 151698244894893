import { Component, Input, OnInit } from '@angular/core';
import { environment } from '../../../../../environments/environment';
import { Item } from '../../../../core/shared/item.model';
import { MetadataMap } from '../../../../core/shared/metadata.models';
import { getFirstSucceededRemoteDataPayload } from '../../../../core/shared/operators';
import { getItemPageRoute } from '../../../item-page-routing-paths';

@Component({
  selector: 'ds-item',
  template: ''
})
/**
 * A generic component for displaying metadata and relations of an item
 */
export class ItemComponent implements OnInit {
  @Input() object: Item;

  /**
   * Route to the item page
   */
  itemPageRoute: string;

  mediaViewer = environment.mediaViewer;

  /**
   * HitHighlights of this item added to the state after a search
   */
  hitHighlightsOfItem: MetadataMap;

  constructor() {
    if (window.history.state != null) {
      this.hitHighlightsOfItem = window.history.state.hitHighlights;
    }
  }

  ngOnInit(): void {
    this.itemPageRoute = getItemPageRoute(this.object);
  }
}
