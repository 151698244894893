import { StatletDefaultDataTypeService } from './statlet-default-data-type.service';
import { Injectable } from '@angular/core';
import { statletDataTypeService } from './statlet-data-type.decorator';
import { StatletPoint } from '../../data/models/statlet-point.model';
import { StatletPointRoute } from '../../data/models/statlet.utils';

@Injectable()
@statletDataTypeService('metadata', 'author')
export class StatletAuthorDataTypeService extends StatletDefaultDataTypeService {
  getLinkByPoint(point: StatletPoint): StatletPointRoute {
    return this.getLinkByValue(point.label);
  }

  getLinkByValue(value: string): StatletPointRoute {
    return { href: '/search', queryParams: { ['f.author']: value + ',equals' } };
  }
}
