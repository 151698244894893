import { Injectable } from '@angular/core';
import { CommunityDataService } from '../data/community-data.service';
import { EMPTY, Observable, of as observableOf} from 'rxjs';
import { Community } from '../shared/community.model';
import { getAllSucceededRemoteListPayload, getFirstSucceededRemoteData, getRemoteDataPayload } from '../shared/operators';
import { find, map, switchMap, take } from 'rxjs/operators';
import { select, Store } from '@ngrx/store';
import { StoreVHostAction, StoreVHostCommunityAction } from '../vhost/vhost.actions';
import { vHostCommmunitySelector, vHostSelector } from '../vhost/vhost.selectors';
import { AppState } from '../../app.reducer';
import { followLink } from '../../shared/utils/follow-link-config.model';
import { RemoteData } from '../data/remote-data';
import { hasValue } from '../../shared/empty.util';
import { Item } from '../shared/item.model';
import { Collection } from '../shared/collection.model';
import { LinkService } from '../cache/builders/link.service';
import { DSpaceObjectDataService } from '../data/dspace-object-data.service';
import { VHostRedirectService } from './vhost-redirect.service';

/**
 * The VHost service.
 */
@Injectable()
export class VHostService {

  constructor(
    protected vhostRedirectService: VHostRedirectService,
    protected communityService: CommunityDataService,
    protected dsoService: DSpaceObjectDataService,
    protected linkService: LinkService,
    protected store: Store<AppState>,
  ) { }

  getVHostCommunityByLocation(): Observable<Community> {
    return this.getVHostCommunityByVHost(
      this.vhostRedirectService.getOriginFromUrl(),
    );
  }

  /**
   * Get the community for a given vHost.
   * This will return the top level community wih 'atmire.vhost' metadata equal to the given vHost string.
   * Calling this method will also store the vHost and the vHost community in the redux store.
   * @param vHost
   *    the vHost string, this should match the 'atmire.vhost' metadata of the returned community.
   */
  getVHostCommunityByVHost(vHost: string): Observable<Community> {

    const response = this.communityService.findTop({
      elementsPerPage: 9999,
    }).pipe(
      getAllSucceededRemoteListPayload(),
      map((communities) =>
        communities.find(
          (community) => this.getVHostFromCommunity(community) === vHost
        )
      ),
    );

    response.pipe(
      take(1),
    ).subscribe((community) => this.storeVHost(vHost, community));

    return response;
  }

  /**
   * Get the vHost community for a given DSpaceObject.
   * This will return the top level community which the parent of the given DSpaceObject.
   * Calling this method will also store the vHost and the vHost community in the redux store.
   * @param dso
   *    the DSpaceObject to get the vHost community for.
   */
  getVHostCommunityByDso(dso: Item | Collection | Community): Observable<Community> {

    const propertyName = dso.getParentLinkKey();
    const response = this.linkService.resolveLink(dso, followLink(propertyName))[propertyName].pipe(
      find((parentRD: RemoteData<Collection | Community>) => parentRD.hasSucceeded || parentRD.statusCode === 204),
      switchMap((parentRD: RemoteData<Collection | Community>) => {
        if (hasValue(parentRD.payload)) {
          return this.getVHostCommunityByDso(parentRD.payload);
        }
        if (dso instanceof Community) {
          return observableOf(dso as Community);
        }
        return EMPTY;
      }),
    );

    response.pipe(
      take(1),
    ).subscribe((community) => this.storeVHost(this.getVHostFromCommunity(community), community));

    return response;
  }

  getVHostCommunityByUuid(uuid: string): Observable<Community> {
    return this.dsoService.findById(uuid).pipe(
      getFirstSucceededRemoteData(),
      getRemoteDataPayload(),
      switchMap((dso) => {
        if (!(dso instanceof Item || dso instanceof Collection || dso instanceof Community)) {
          return EMPTY;
        }
        return this.getVHostCommunityByDso(dso);
      })
    );
  }

  getVHostFromCommunity(community): string {
    return community.firstMetadataValue('atmire.vhost');
  }

  private storeVHost(vHost: string, community: Community) {
    this.store.dispatch(new StoreVHostAction(vHost));
    if (community) {
      this.store.dispatch(new StoreVHostCommunityAction(community.uuid));
    }
  }

  /**
   * Get the VHost from the Redux store.
   */
  getVHostFromStore(): Observable<string> {
    return this.store.pipe(
      select(vHostSelector)
    );
  }

  /**
   * Get the VHost community id from the Redux store.
   */
  getVHostCommunityIdFromStore(): Observable<string> {
    return this.store.pipe(
      select(vHostCommmunitySelector)
    );
  }

  /**
   * Get the VHost community from the Redux store.
   */
  getVHostCommunityFromStore(): Observable<Community> {
    return this.getVHostCommunityIdFromStore().pipe(
      switchMap((uuid) => {
        if (!uuid) {
          return observableOf(undefined);
        }
        return this.communityService.findById(uuid).pipe(
          getFirstSucceededRemoteData(),
          getRemoteDataPayload(),
        );
      }),
    );
  }
}
