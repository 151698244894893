import { DataService } from './data.service';
import { SubmissionDefinitionModel } from '../config/models/config-submission-definition.model';
import { RequestService } from './request.service';
import { RemoteDataBuildService } from '../cache/builders/remote-data-build.service';
import { Store } from '@ngrx/store';
import { CoreState } from '../core.reducers';
import { ObjectCacheService } from '../cache/object-cache.service';
import { HALEndpointService } from '../shared/hal-endpoint.service';
import { NotificationsService } from '../../shared/notifications/notifications.service';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { RemoteData } from './remote-data';
import { RequestParam } from '../cache/models/request-param.model';
import { Injectable } from '@angular/core';
import { DefaultChangeAnalyzer } from './default-change-analyzer.service';
import { dataService } from '../cache/builders/build-decorators';
import { SUBMISSION_DEFINITION_TYPE } from '../config/models/config-type';
import { FindListOptions } from './request.models';
import { NoContent } from '../shared/NoContent.model';

@Injectable()
@dataService(SUBMISSION_DEFINITION_TYPE)
export class SubmissionDefinitionDataService extends DataService<SubmissionDefinitionModel> {
  protected linkPath = 'submissiondefinitions';

  constructor(
    protected requestService: RequestService,
    protected rdbService: RemoteDataBuildService,
    protected store: Store<CoreState>,
    protected objectCache: ObjectCacheService,
    protected halService: HALEndpointService,
    protected notificationsService: NotificationsService,
    protected http: HttpClient,
    protected comparator: DefaultChangeAnalyzer<SubmissionDefinitionModel>,
  ) {
    super();
  }

  public findByCollection(collection: string): Observable<RemoteData<SubmissionDefinitionModel | NoContent>> {
    const findListOptions = new FindListOptions();
    findListOptions.searchParams = [new RequestParam('uuid', encodeURIComponent(collection))];

    const href$ = this.getSearchByHref('findByCollection', findListOptions);
    return this.findByHref(href$);
  }
}
