import { Component, Input, OnDestroy } from '@angular/core';
import { combineLatest, ReplaySubject, Subject } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import { isNotNull, isNotUndefined } from '../empty.util';

@Component({
    selector: 'ds-truncate-chars',
    templateUrl: './truncate-chars.component.html',
    styleUrls: ['./truncate-chars.component.scss']
})
export class TruncateCharsComponent implements OnDestroy {

    /**
     * Emits a single event when the component is destroyed. See {@link ngOnDestroy}.
     */
    protected destroy$: Subject<void> = new Subject();

    /**
     * The full text, as provided by the user. See {@link text}.
     */
    protected fullText$: ReplaySubject<string> = new ReplaySubject<string>(1);

    /**
     * The character limit, as provided by the user. See {@link charLimit}.
     */
    protected charLimit$: ReplaySubject<number> = new ReplaySubject<number>(1);

    /**
     * The text that is displayed to the user.
     */
    displayedText$: ReplaySubject<string> = new ReplaySubject<string>(1);

    /**
     * The text that should be displayed and/or truncated.
     * @param text
     */
    @Input()
    set text(text: string) {
        this.fullText$.next(text);
    }

    /**
     * The maximum amount of characters that can be displayed without truncation.
     * @param charLimit
     */
    @Input()
    set charLimit(charLimit: number) {
        this.charLimit$.next(charLimit);
    }

    constructor() {
        // calculate the text to display
        combineLatest([
            this.fullText$,
            this.charLimit$,
        ]).pipe(
            map(([fullText, charLimit]: [string, number]) => {
                // full text is small enough - no truncation needed
                if (fullText.length <= charLimit) {
                    return fullText;
                }

                // truncate full text
                if (isNotUndefined(charLimit) && isNotNull(charLimit)) {
                    return fullText.slice(0, charLimit) + '...';
                } else {
                    return fullText;
                }
            }),
            takeUntil(this.destroy$),
        ).subscribe(this.displayedText$);
    }

    ngOnDestroy() {
        this.destroy$.next();
        this.destroy$.complete();
    }

}
