import { VHostRedirectService } from '../services/vhost-redirect.service';
import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { VHostActionTypes, VHostDsoPageAction } from './vhost.actions';
import { switchMap, tap } from 'rxjs/operators';
import { VHostService } from '../services/vhost.service';
import { ActivatedRoute, Router } from '@angular/router';
import * as parse from 'url-parse';

@Injectable()
export class VHostEffects {

  @Effect({
    dispatch: false,
  })
  vHostRedirect$ = this.actions$.pipe(
    ofType(VHostActionTypes.DSO_PAGE),
    switchMap((action: VHostDsoPageAction) => this.vHostService.getVHostCommunityByUuid(action.payload.uuid)),
    tap((community) => {

      if (!community) {
        return;
      }

      const vHost = this.vHostService.getVHostFromCommunity(community);
      const origin = this.vHostRedirectService.getOriginFromUrl();
      if (!vHost || vHost === origin) {
        return;
      }

      const route = new parse(this.vHostRedirectService.getRoute());
      route.set('protocol', 'https');
      route.set('hostname', vHost);
      this.vHostRedirectService.redirect(route.href);
    }),
  );

  constructor(
    protected actions$: Actions,
    protected vHostService: VHostService,
    protected vHostRedirectService: VHostRedirectService,
    protected route: ActivatedRoute,
    protected router: Router,
  ) {
  }
}
