import { ItemPageFieldComponent } from '../item-page-field.component';
import { ChangeDetectionStrategy, Component, Input, OnChanges, OnDestroy, OnInit } from '@angular/core';
import { Item } from '../../../../../core/shared/item.model';
import { BehaviorSubject, from as observableFrom, Observable, Subscription } from 'rxjs';
import { MetadataValue } from '../../../../../core/shared/metadata.models';
import { SearchService } from '../../../../../core/shared/search/search.service';
import { SearchOptions } from '../../../../../shared/search/search-options.model';
import { PaginatedSearchOptions } from '../../../../../shared/search/paginated-search-options.model';
import { getFirstCompletedRemoteData, getFirstSucceededRemoteData } from '../../../../../core/shared/operators';
import { map, mergeMap, toArray } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { ViewMode } from '../../../../../core/shared/view-mode.model';
import { hasValue } from '../../../../../shared/empty.util';

interface Link {
  text: string;
  href: string;
  hasResults: boolean;
}

function getUISearchHref(options: PaginatedSearchOptions, view?: ViewMode): string {
  const params = [
    'query=' + options.query,
  ];

  if (options.scope) {
    params.push('scope=' + options.scope);
  }
  if (hasValue(view)) {
    params.push('view=' + view);
  }

  return '/search?' + params.join('&');
}

@Component({
  selector: 'ds-item-page-search-link-field',
  templateUrl: './item-page-search-link-field.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ItemPageSearchLinkFieldComponent extends ItemPageFieldComponent implements OnChanges, OnDestroy {
  /**
   * The item to display metadata for.
   */
  @Input() item: Item;

  /**
   * The i18n key contains the label for this metadata field. Not shown when {@link perValue} is false.
   */
  @Input() label: string;

  /**
   * The metadata field to use
   */
  @Input() field: string;

  /**
   * The Solr query field to use. If empty, {@link field} will be used instead.
   */
  @Input() solrField?: string;

  /**
   * A transformation to apply to MetadataValue entries. Default: return value
   */
  @Input() transform: (mdv: MetadataValue) => string;

  /**
   * Whether to show a search link for each metadata value
   * When set to false, all metadata values will be combined in a single query
   */
  @Input() perValue? = false;

  /**
   * Whether to render this field with a wrapper
   */
  @Input() withWrapper? = true;

  /**
   * Optional view mode for the search links
   */
  @Input() viewMode?;

  /**
   * Whether to exclude the current item from the query
   */
  @Input() excludeCurrentItem? = false;

  /**
   * Whether to use URI of the first result instead of the search itself
   */
  @Input() firstResultURI? = false;

  /**
   * The search options for the queries; {@link SearchOptions.query} will be overridden
   */
  @Input() commonSearchOptions: PaginatedSearchOptions;

  /**
   * The i18n key to use for the combined query. Only used when {@link perValue} is false.
   */
  @Input() linkText? = 'item.page.search-link.text';

  /**
   * Array to track all subscriptions and unsubscribe them onDestroy
   * @type {Array}
   */
  private subs: Subscription[] = [];

  searchLinks$: BehaviorSubject<Link[]> = new BehaviorSubject<Link[]>([]);
  hideBullets = false;

  constructor(
    protected searchService: SearchService,
    protected translateService: TranslateService,
  ) {
    super();
  }

  public ngOnChanges(changes): void {
    if (this.item && this.commonSearchOptions) {
      this.searchLinks$.next([]);

      const links$ = this.perValue ? this.getSearchLinks() : this.getCombinedSearchLink();

      this.subs.push(
        links$.subscribe((links) => {
          // NOTE: links$ may complete, but searchLinks$ observable should NEVER complete to avoid race-condition
          //       (if searchLinks$ would be allowed to complete, it could complete before the view is rendered,
          //       which means nothing would be rendered.)
          this.searchLinks$.next(links);
        })
      );
    }
  }

  private asQueryClause(mdv: MetadataValue): string {
    const field = this.solrField ? this.solrField : this.field;
    const value = this.transform ? this.transform(mdv) : mdv.value;

    return `${field}:"${value}"`;
  }

  private getOptions(query: string): PaginatedSearchOptions {
    if (this.excludeCurrentItem) {
      const handle = this.item.handle;
      query = `-handle:${handle} AND ` + query;
    }

    return new PaginatedSearchOptions({ ...this.commonSearchOptions, query });
  }

  private getSearchLinks(): Observable<Link[]> {
    return observableFrom(
      this.item.allMetadata(this.field)
    ).pipe(
      mergeMap((mdv) => {
        const options = this.getOptions(this.asQueryClause(mdv));

        return this.searchService.search<Item>(options).pipe(
          getFirstCompletedRemoteData(),
          map((resultRD) => {
            return {
              text: mdv.value,
              href: this.firstResultURI
                ? '/handle/' + resultRD.payload.page[0]?.indexableObject.handle
                : getUISearchHref(options, this.viewMode),
              hasResults: resultRD.payload.totalElements > 0,
            } as Link;
          })
        );
      }),
      toArray(),
    );
  }

  private getCombinedSearchLink(): Observable<Link[]> {
    this.hideBullets = true;
    const query = this.item.allMetadata(this.field)
                      .map(mdv => this.asQueryClause(mdv))
                      .join(' OR ');
    const options = this.getOptions(query);

    return this.searchService.search(options).pipe(
      getFirstSucceededRemoteData(),
      map((resultRD) => {
        return [{
          text: this.translateService.instant(this.linkText),
          href: getUISearchHref(options, this.viewMode),
          hasResults: resultRD.payload.totalElements > 0,
        }] as Link[];
      })
    );
  }

  /**
   * Unsubscribe from all subscriptions
   */
  ngOnDestroy(): void {
    this.subs
      .filter((subscription) => hasValue(subscription))
      .forEach((subscription) => subscription.unsubscribe());
  }
}
