import { ChangeDetectionStrategy, Component } from '@angular/core';
import { ViewMode } from '../../../../../../../app/core/shared/view-mode.model';
import { listableObjectComponent } from '../../../../../../../app/shared/object-collection/shared/listable-object/listable-object.decorator';
import { Context } from '../../../../../../../app/core/shared/context.model';
import { Item } from '../../../../../../../app/core/shared/item.model';
import { UntypedItemComponent as BaseComponent } from '../../../../../../../app/item-page/simple/item-types/untyped-item/untyped-item.component';
import { combineLatest, Observable } from 'rxjs';
import { getFirstSucceededRemoteData, } from '../../../../../../../app/core/shared/operators';
import { MetadataValue } from '../../../../../../../app/core/shared/metadata.models';
import { map, switchMap } from 'rxjs/operators';
import { ConfigurationDataService } from '../../../../../../../app/core/data/configuration-data.service';
import { CollectionDataService } from '../../../../../../../app/core/data/collection-data.service';
import { BitstreamDataService } from '../../../../../../../app/core/data/bitstream-data.service';
import { RESTURLCombiner } from '../../../../../../../app/core/url-combiner/rest-url-combiner';
import { PaginatedSearchOptions } from '../../../../../../../app/shared/search/paginated-search-options.model';

/**
 * Component that represents a publication Item page
 */

enum CollectionType {
  ARCHIVES, PARTIES, OTHER
}

@listableObjectComponent(Item, ViewMode.StandalonePage, Context.Any, 'galleryharvest')
@Component({
  selector: 'ds-untyped-item',
  styleUrls: ['./untyped-item.component.scss'],
  templateUrl: './untyped-item.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UntypedItemComponent extends BaseComponent {
  CollectionType = CollectionType;
  collectionType$: Observable<CollectionType>;
  archivesOptions$: Observable<PaginatedSearchOptions>;
  partiesOptions$: Observable<PaginatedSearchOptions>;

  constructor(
    protected configurationDataService: ConfigurationDataService,
    protected collectionDataService: CollectionDataService,
  ) {
    super();
  }

  public ngOnInit(): void {
    super.ngOnInit();

    const archives$ = this.getFirstConfigValue$('related-items.collection.gallery.archives');
    const parties$ = this.getFirstConfigValue$('related-items.collection.gallery.parties');
    this.archivesOptions$ = this.getOptions$(archives$);
    this.partiesOptions$ = this.getOptions$(parties$);

    this.collectionType$ = combineLatest([
      archives$,
      parties$,
      this.object.owningCollection.pipe(
        getFirstSucceededRemoteData(),
        map(rd => rd.payload)
      )
    ]).pipe(
      map(([archives, parties, owningCollection]) => {
        if (owningCollection.handle.endsWith(archives)) {
          return CollectionType.ARCHIVES;
        } else if (owningCollection.handle.endsWith(parties)) {
          return CollectionType.PARTIES;
        } else {
          return CollectionType.OTHER;
        }
      })
    );
  }

  private getFirstConfigValue$(property: string): Observable<string> {
    return this.configurationDataService.findByPropertyName(property).pipe(
      getFirstSucceededRemoteData(),
      map(configRD => configRD.payload.values[0]),
    );
  }

  private getOptions$(handle$: Observable<string>): Observable<PaginatedSearchOptions> {
    return handle$.pipe(
      switchMap(handle => this.collectionDataService.findByHref(
        new RESTURLCombiner('pid', 'find', `?id=hdl:${handle}`).toString()
      )),
      getFirstSucceededRemoteData(),
      map(collectionRD => new PaginatedSearchOptions({
        scope: collectionRD.payload.uuid,
      }))
    );
  }

  getPrefix = (mdv: MetadataValue) => mdv.value.split('.').slice(0, 2).join('.');
}
