<!-- try to load all thumbnails -->
<div *ngFor="let url of uniqueUrlsFromMdValues" class="thumbnail-content">
  <ng-container [ngTemplateOutlet]="clickableImg" [ngTemplateOutletContext]="{src: url}"></ng-container>
</div>

<!-- if all thumbnails failed to load, display the fallback image -->
<div *ngIf="thumbnailsFailed >= uniqueUrlsFromMdValues.length" class="thumbnail-content thumbnail-default">
  <ng-container [ngTemplateOutlet]="clickableImg" [ngTemplateOutletContext]="{src: defaultImage}"></ng-container>
</div>

<ng-template #clickableImg let-src="src">

  <!-- enable/disable clickable image -->
  <a
    *ngIf="clickable; else img"
    href="{{ src }}"
    target="_blank"
  >
    <ng-container [ngTemplateOutlet]="img"></ng-container>
  </a>

  <!-- the image -->
  <ng-template #img>
    <img [src]="src | dsSafeUrl" class="img-fluid"/>
  </ng-template>

</ng-template>
