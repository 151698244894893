import { Component, Input, OnInit } from '@angular/core';

const ORCID_REGEX = new RegExp('^\\d{4}-\\d{4}-\\d{4}-(\\d{3}X|\\d{4})$');
const FAE_PREFIX = 'https://findanexpert.unimelb.edu.au/profile/';
const ORCID_PREFIX = 'https://orcid.org/';

@Component({
  selector: 'ds-item-page-uom-author-entry',
  templateUrl: './item-page-uom-author-entry.component.html',
  styleUrls: ['./item-page-uom-author-entry.component.scss']
})
export class ItemPageUomAuthorEntryComponent implements OnInit {
  @Input() value: string;

  public label: string;
  private fae: string;
  private orcid: string;

  constructor() {
    this.label = null;
    this.fae = null;
    this.orcid = null;
  }

  ngOnInit(): void {
    this.parseSections();
  }

  private parseSections(): void {
    if (this.value) {
      const sections = this.value.split(';');

      this.label = sections[0].trim();
      this.fae = sections[1].trim();

      const orcid = sections[sections.length - 1].trim();
      if (orcid && orcid.match(ORCID_REGEX)) {
        this.orcid = orcid;
      }
    }
  }

  public get faeUrl(): string {
    return FAE_PREFIX + this.fae;
  }

  public get hasOrcid(): boolean {
    return this.orcid !== null;
  }

  public get orcidUrl(): string {
    return ORCID_PREFIX + this.orcid;
  }

  getAuthorFilterLink(value) {
    return {
      [`f.author`]: `${value},query`
    };
  }
}
