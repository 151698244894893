import { ItemRelation } from './item-relation.interface';

export const originalWorks: ItemRelation = {
  sourceHandle:       'performing.arts.originalWorks',
  sourceMdf:          'melbourne.internal.work',
  destinationHandle:  'performing.arts.events',
  destinationMdf:     'melbourne.internal.work',
  i18nLink:           'item-relations.link.productions',
};

export const eventsToDigitalAssets: ItemRelation = {
  sourceHandle:       'performing.arts.events',
  sourceMdf:          'melbourne.internal.eventid',
  destinationHandle:  'performing.arts.digitalAssets',
  i18nLink:           'item-relations.link.digital-assets',
};

export const eventsToDigitalAssetsTheatreVersion: ItemRelation = {
  sourceHandle:       'performing.arts.events',
  sourceMdf:          'melbourne.archives.eventid',
  destinationHandle:  'theatre.dance.digitalAssets',
  i18nLink:           'item-relations.link.items',
};

export const eventsToOriginalWorks: ItemRelation = {
  sourceHandle:       'performing.arts.events',
  sourceMdf:          'melbourne.internal.work',
  destinationHandle:  'performing.arts.originalWorks',
  i18nLink:           'item-relations.link.original-work',
};

export const digitalAssets: ItemRelation = {
  sourceHandle:       'performing.arts.digitalAssets',
  sourceMdf:          'melbourne.internal.eventid',
  destinationHandle:  'performing.arts.events',
  i18nLink:           'item-relations.link.production',
};

export const defaultRelations = [
  eventsToDigitalAssets,
  originalWorks,
  eventsToOriginalWorks,
  digitalAssets,
];
