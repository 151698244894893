import {Component, Input, OnInit} from '@angular/core';
import {environment} from '../../../environments/environment';
import {Item} from '../../core/shared/item.model';

@Component({
  selector: 'ds-melbourne-item-page-thumbnail-wrapper',
  templateUrl: './melbourne-item-page-thumbnail-wrapper.component.html',
  styleUrls: ['./melbourne-item-page-thumbnail-wrapper.component.scss']
})
export class MelbourneItemPageThumbnailWrapperComponent {

  @Input()
  item: Item;

  mediaViewer = environment.mediaViewer;

}
