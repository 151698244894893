<div class="dropdown" ngbDropdown *ngIf="isAuthorized$ | async">
  <button class="btn btn-outline-dark" [ngClass]="{'btn-sm' : compact}" [ngbTooltip]="('atmire.selective-export-dropdown.' + msgPrefix + '.tooltip') | translate" ngbDropdownToggle>
    <i class="fas fa-file-download"></i>
    <span class="d-none d-sm-inline-block" *ngIf="!compact">{{('atmire.selective-export-dropdown.' + msgPrefix + '.label') | translate}}</span>
  </button>
  <div ngbDropdownMenu class="dropdown-menu dropdown-menu-right">
    <div *ngFor="let exportOption of exportOptions" (click)="export(exportOption)"
         class="dropdown-item" [ngClass]="{'disabled' : totalResults === 0}">
      {{('atmire.selective-export-dropdown.' + msgPrefix + '.option.export-as') | translate:{ exportType: exportOption.label } }}
    </div>
    <div class="dropdown-item" [ngClass]="{'disabled' : totalResults === 0}" (click)="addAll()">
      {{('atmire.selective-export-dropdown.' + msgPrefix + '.option.add-all') | translate:{ name: (myList$ | async)?.name } }}
    </div>
    <a [routerLink]="'/my-lists/' + (myList$ | async)?.name" class="dropdown-item">
      {{('atmire.selective-export-dropdown.' + msgPrefix + '.option.export-my-list') | translate:{ amount: (count$ | async), name: (myList$ | async)?.name } }}
    </a>
  </div>
</div>
