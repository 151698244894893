<div [class.d-none]="(this.citationValuesRD$ | async)?.payload?.totalElements == 0 && !(altMetricDonutRendered$ | async)" class="citations-section">
  <h5>{{ this.labelTitle | translate }}</h5>
  <div class="d-flex flex-wrap citations">
    <ds-citation-circle *ngFor="let citation of (this.citationValuesRD$ | async)?.payload?.page" [smallView]="false"
                        [citationValue]="citation"></ds-citation-circle>
    <ds-altmetric-donut (altMetricDonutRenderedEmitter)="setShowCitations($event)" [doiIdentifierMdFields]="['dc.description.doi', 'dc.identifier.doi']"
                        [item]="item" [smallView]="false">
    </ds-altmetric-donut>
  </div>
</div>
