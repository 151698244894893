import { FocusMonitor } from '@angular/cdk/a11y';
import { Component, Input, OnInit } from '@angular/core';
import { MetadataMap } from '../../../core/shared/metadata.models';

/**
 * Prefix to filter hitHighlights for snippets section
 */
export const SNIPPET_HITHIGHLIGHT_PREFIX = 'fulltext_';
/**
 * Component with list of text snippets found in the hitHighlights of a search result on the item page,
 * with an anchor to the corresponding files
 */
@Component({
  selector: 'ds-snippets-section',
  templateUrl: './snippets-section.component.html',
})
export class SnippetsSectionComponent implements OnInit {

  @Input()
  hitHighlightsOfItem: MetadataMap;

  /**
   * hitHighlightsOfItem but filtered on those with keys starting with prefix
   */
  hitHighlightsOfItemFiltered: MetadataMap = new MetadataMap();

  hasSnippets: boolean;

  constructor(private focusMonitor: FocusMonitor) {
  }

  ngOnInit(): void {
    this.hasSnippets = false;
    for (const key in this.hitHighlightsOfItem) {
      if (this.hitHighlightsOfItem.hasOwnProperty(key)) {
        const value = this.hitHighlightsOfItem[key];
        if (key.startsWith(SNIPPET_HITHIGHLIGHT_PREFIX)) {
          this.hasSnippets = true;
          this.hitHighlightsOfItemFiltered[key] = value;
        }
      }
    }
  }

  /**
   * Smooth scroll to give anchor on the page (id of a file download link, see FileDownloadLinkComponent)
   * @param elementId ID of a file download link on item page
   */
  scrollToAnchor(elementId: string): void {
    const element: Element = document.querySelector('#' + elementId);
    if (element) {
      this.focusMonitor.focusVia(element as HTMLElement, 'program');
      element.classList.add('mark');

      const elementPosition = element.getBoundingClientRect().top;

      window.scrollTo({
        behavior: 'smooth',
        // Scroll to the element's position - 100px to offset the sticky navbar
        top: elementPosition - 100,
      });
    }
  }
}
