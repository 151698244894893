<div *ngIf="this.citationValue.count != null && !smallView" class="d-flex flex-column citation">
  <h6>{{ 'publications.citations-section.' + (getSourceName() | async) + '.title' | translate }}</h6>
  <a class="btn btn-primary btn-circle btn-circle-lg text-white d-block" href="{{ this.citationValue.uri }}"
     target="_blank">
    {{ this.citationValue.count }}
  </a>
</div>

<div *ngIf="this.citationValue.count != null && smallView" class="citation-smallView">
  <a attr.aria-label="{{ 'publications.citations-section.citationLink.' + (getSourceName() | async) + '.info' | translate }}" href="{{ this.citationValue.uri }}"
     target="_blank"
     title="{{ 'publications.citations-section.citationLink.' + (getSourceName() | async) + '.info' | translate }}">
    <span *ngIf="(getSourceName() | async) === 'scopus'" class=""><img alt="Scopus logo"
                                                                       class="citation-source-logo"
                                                                       src="assets/images/favicon-scopus.ico"></span>
    <span *ngIf="(getSourceName() | async) === 'isi_wos'" class=""><img alt="Web of Science logo"
                                                                        class="citation-source-logo"
                                                                        src="assets/images/logo-wos.png"></span>
    <span>{{ this.citationValue.count }}</span>
  </a>
</div>
