import { Injectable } from '@angular/core';
import { hasValue } from '../../../app/shared/empty.util';
import { DSpaceObject } from '../../../app/core/shared/dspace-object.model';
import { UnresolvedDso } from '../shared/unresolved-dso.model';
import { EPERSON } from '../../../app/core/eperson/models/eperson.resource-type';
import { BITSTREAM } from '../../../app/core/shared/bitstream.resource-type';
import { COLLECTION } from '../../../app/core/shared/collection.resource-type';
import { COMMUNITY } from '../../../app/core/shared/community.resource-type';
import { DSONameService } from '../../../app/core/breadcrumbs/dso-name.service';

/**
 * Returns a name for a {@link DSpaceObject} based
 * on its render types.
 */
@Injectable({
  providedIn: 'root'
})
export class AtmireDSONameService extends DSONameService {

  protected readonly factories = {
    Person: (dso: DSpaceObject): string => {
      return `${dso.firstMetadataValue('person.familyName')}, ${dso.firstMetadataValue('person.givenName')}`;
    },
    OrgUnit: (dso: DSpaceObject): string => {
      return dso.firstMetadataValue('organization.legalName');
    },
    Unresolved: (dso: UnresolvedDso): string => {
      return this.getUnresolvedDSOName(dso);
    },
    Default: (dso: DSpaceObject): string => {
      // If object doesn't have dc.title metadata use name property
      return dso.firstMetadataValue('dc.title') || dso.name || this.translateService.instant('dso.name.untitled');
    }
  };

  private getUnresolvedDSOName(dso: UnresolvedDso) {
    switch (dso.unresolvedType) {
      case (EPERSON):
      case (BITSTREAM):
      case (COLLECTION):
      case (COMMUNITY):
        return this.getUnresolvedDSONameKey(dso, 'storage-reports.missing.' + dso.unresolvedType.value);
      default:
        return this.getUnresolvedDSONameKey(dso, 'storage-reports.missing.dso');
    }
  }

  private getUnresolvedDSONameKey(dso: UnresolvedDso, key: string): string {
    if (hasValue(dso.getId())) {
      return this.translateService.instant(key, { id: dso.getId() });
    } else {
      return this.translateService.instant(key + '.no-id');
    }
  }

}
