import { ItemPageFieldComponent } from '../item-page-field.component';
import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { BehaviorSubject, combineLatest, from as observableFrom, Observable, of as observableOf } from 'rxjs';
import { Item } from '../../../../../core/shared/item.model';
import { ConfigurationDataService } from '../../../../../core/data/configuration-data.service';
import { map, switchMap } from 'rxjs/operators';
import {
  getAncestorDSOs, getFirstSucceededRemoteData, getPaginatedListPayload, getRemoteDataPayload,
} from '../../../../../core/shared/operators';
import { ItemRelation } from './item-relation.interface';
import { defaultRelations } from './item-relations.config';
import { PaginatedSearchOptions } from '../../../../../shared/search/paginated-search-options.model';
import { ViewMode } from '../../../../../core/shared/view-mode.model';
import { LinkService } from '../../../../../core/cache/builders/link.service';
import { Collection } from '../../../../../core/shared/collection.model';
import { Community } from '../../../../../core/shared/community.model';
import { DSpaceObjectDataService } from '../../../../../core/data/dspace-object-data.service';
import { RESTURLCombiner } from '../../../../../core/url-combiner/rest-url-combiner';
import { CollectionDataService } from '../../../../../core/data/collection-data.service';
import { FindListOptions } from '../../../../../core/data/request.models';
import { DSpaceObject } from '../../../../../core/shared/dspace-object.model';
import { isNotEmpty } from '../../../../../shared/empty.util';

const FIND_ALL = Object.assign(new FindListOptions(), {
  elementsPerPage: 9999,
});

interface LinkField {
  relation: ItemRelation;
  isInSourceOf: boolean;
  searchOptions: PaginatedSearchOptions;
}

@Component({
  selector: 'ds-item-page-relations-field',
  templateUrl: './item-page-relations-field.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ItemPageRelationsFieldComponent extends ItemPageFieldComponent implements OnInit {
  /**
   * The item to display metadata for.
   */
  @Input() item: Item;

  /**
   * The relations to display.
   */
  @Input() relations: ItemRelation[] = defaultRelations;

  /**
   * The i18n key contains the label for this metadata field.
   */
  @Input() label = 'item-relations.label';

  ViewMode = ViewMode;

  ancestorHandles$: BehaviorSubject<string[]> = new BehaviorSubject<string[]>([]);

  constructor(
    private configurationDataService: ConfigurationDataService,
    private linkService: LinkService,
    private dsoDataService: DSpaceObjectDataService,
    private collectionDataService: CollectionDataService,
  ) {
    super();
  }

  public ngOnInit(): void {
    combineLatest([
      this.collectionDataService.findOwningCollectionFor(this.item).pipe(
        getFirstSucceededRemoteData(),
        getRemoteDataPayload(),
      ),
      this.collectionDataService.findMappedCollectionsFor(this.item, FIND_ALL).pipe(
        getFirstSucceededRemoteData(),
        getRemoteDataPayload(),
        getPaginatedListPayload(),
      ),
    ]).pipe(
      map(([owningCollection, mappedCollections]) => [
        owningCollection,
        ...mappedCollections
      ]),
      switchMap((collections) => {
        return observableFrom(collections).pipe(
          getAncestorDSOs(this.linkService),
          map((cs: DSpaceObject[]) => cs.map((c: Collection | Community) => this.extractHandle(c.handle))),
        );
      }),
    ).subscribe((handles) => {
      this.ancestorHandles$.next(handles);
    });
  }

  /**
   * Extract a handle from the given string.
   * Rules:
   * - string contains at least 1 `/`
   * - when splitting the string on each `/`:
   *   - the second-last segment only contains digits
   *   - the last segment only contains digits
   * @param str the string.
   */
  protected extractHandle(str: string): string {
    const segments = str.split('/');
    if (segments.length < 2) {
      console.error(`failed to parse handle from string "${str}": expected at least one / in the string`);
      return;
    }
    const handleFirst = segments[segments.length - 2];
    if (!this.isInt(handleFirst)) {
      console.error(`failed to parse handle from string "${str}": "${handleFirst}" is not an integer`);
      return;
    }
    const handleSecond = segments[segments.length - 1];
    if (!this.isInt(handleSecond)) {
      console.error(`failed to parse handle from string "${str}": "${handleSecond}" is not an integer`);
      return;
    }
    return `${handleFirst}/${handleSecond}`;
  }

  protected isInt(str: string) {
    return /^\d+$/.test(str);
  }

  showLink$(relation: ItemRelation): Observable<boolean> {
    if (isNotEmpty(this.item.allMetadata(relation.sourceMdf))) {
      return combineLatest([
        this.ancestorHandles$,
        this.getHandleFromProperty$(relation.sourceHandle),
      ]).pipe(
        map(([handles, source]) => handles.includes(source)),
      );
    } else {
     return observableOf(false);
    }
  }

  getSearchOptions$(relation: ItemRelation): Observable<PaginatedSearchOptions> {
    return this.getHandleFromProperty$(relation.destinationHandle).pipe(
      switchMap(handle => this.dsoDataService.findByHref(
        new RESTURLCombiner('pid', 'find', `?id=hdl:${handle}`).toString()
      )),
      getFirstSucceededRemoteData(),
      map((destinationRD) => new PaginatedSearchOptions({
        scope: destinationRD.payload.uuid,
      })),
    );
  }

  private getHandleFromProperty$(property: string): Observable<string> {
    return this.configurationDataService.findByPropertyName(property).pipe(
      getFirstSucceededRemoteData(),
      getRemoteDataPayload(),
      map(configProperty => configProperty.values[0]),
    );
  }
}
