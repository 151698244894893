/**
 *  The contents of this file are subject to the license and copyright
 *  detailed in the LICENSE and NOTICE files at the root of the source
 *  tree and available online at
 *
 *  http://www.dspace.org/license/
 */

class ISimpleDateModel {
  year: number;
  month: number;
  day: number;
}

// tslint:disable-next-line:max-classes-per-file
export class SimpleDateModel implements ISimpleDateModel {
  year: number;
  month: number;
  day: number;

  public constructor(from: Date | ISimpleDateModel | string) {
    if (typeof from === 'string') {
      from = new Date(from);
    }
    if (from instanceof Date) {
      this.year = from.getFullYear();
      this.month = from.getMonth() + 1;
      this.day = from.getDate();
    } else {
      this.year = from?.year;
      this.month = from?.month;
      this.day = from?.day;
    }
  }

  public asDate(): Date {
    const date = new Date(0);
    date.setUTCFullYear(this.year, this.month - 1, this.day);
    return date;
  }

  public asString(): string {
    return `${this.year}-${this.month}, ${this.day}`;
  }

  public equals(other: Date | ISimpleDateModel | string) {
    if (typeof other === 'string') {
      other = new Date(other);
    }
    if (other instanceof Date) {
      return this.year === other.getFullYear() && this.month === other.getMonth() + 1 && this.day === other.getDate();
    } else {
      return this.year === other?.year && this.month === other?.month && this.day === other?.day;
    }
  }

  public notBeforeToday(): boolean {
    return this.asDate() >= new Date();
  }
}
