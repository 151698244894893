<div class="d-flex flex-row">
    <h2 class="item-page-title-field mr-auto">
        <ds-metadata-values [mdValues]="object?.allMetadata(['dc.title'])"></ds-metadata-values>
    </h2>
  <div class="pl-2 d-flex flex-row">
        <ds-atmire-saved-item-list-control class="mr-1" [item]="object"></ds-atmire-saved-item-list-control>
        <ds-atmire-item-export-dropdown class="mr-1" [item]="object" [compact]="true"></ds-atmire-item-export-dropdown>
        <ds-dso-page-edit-button [dso]="object" [pageRoute]="itemPageRoute"
                                 [tooltipMsg]="'item.page.edit'"></ds-dso-page-edit-button>
        <ds-dso-page-preservica-button [dso]="object"
                                       [tooltipMsg]="'item.page.send-to-preservica'"></ds-dso-page-preservica-button>
    </div>
</div>
<ds-melbourne-item-page-thumbnail-wrapper [item]="object"></ds-melbourne-item-page-thumbnail-wrapper>
<div class="row">
    <div class="col-xs-12 col-md-12">
        <ds-item-page-file-section [item]="object" [hitHighlightsOfItem]="hitHighlightsOfItem" label="item.page.files.simple"></ds-item-page-file-section>

        <a [routerLink]="'/atmire-cua/reports/ItemTopCountriesPage'" [queryParams]="{ dso: object?.id }" class="btn btn-outline-primary mb-2">
          {{ 'statlets.item.page.countries' | translate }}
        </a>

        <ds-item-page-relations-field [item]="object"
        ></ds-item-page-relations-field>

        <!--        Authors -->
        <ds-item-page-author-discovery-link-field [item]="object"></ds-item-page-author-discovery-link-field>

        <ds-generic-item-page-field [fields]="['dc.contributor.editor']"
                                    [item]="object"
                                    [label]="'item.page.editor'"
                                    [separator]="'; '"
        >
        </ds-generic-item-page-field>
        <ds-item-page-uom-author [item]="object"
                                 [fields]="['melbourne.internal.authorids']"
                                 [label]="'item.page.melbourne-authors'">
        </ds-item-page-uom-author>
        <ds-generic-item-page-field [fields]="['melbourne.affiliation.department']"
                                    [item]="object"
                                    [label]="'item.page.melbourne-affiliation'"
        >
        </ds-generic-item-page-field>
        <ds-generic-item-page-field [fields]="['melbourne.source.title']"
                                    [item]="object"
                                    [label]="'item.page.melbourne.source.title'"
        >
        </ds-generic-item-page-field>
        <ds-generic-item-page-field [fields]="['*.publisher']"
                                    [item]="object"
                                    [label]="'item.page.publisher'"
        >
        </ds-generic-item-page-field>
        <ds-generic-item-page-field [fields]="['melbourne.archives.relateddates']"
                                    [item]="object"
                                    [label]="'item.page.melbourne.archives.relateddates'"
        >
        </ds-generic-item-page-field>
        <ds-generic-item-page-field [fields]="['melbourne.archives.birthdate']"
                                    [item]="object"
                                    [label]="'item.page.melbourne.archives.birthdate'"
        >
        </ds-generic-item-page-field>
        <ds-generic-item-page-field [fields]="['melbourne.archives.deathdate']"
                                    [item]="object"
                                    [label]="'item.page.melbourne.archives.deathdate'"
        >
        </ds-generic-item-page-field>
        <ds-generic-item-page-field [fields]="['melbourne.archives.establishmentdate']"
                                    [item]="object"
                                    [label]="'item.page.melbourne.archives.establishmentdate'"
        >
        </ds-generic-item-page-field>
        <ds-generic-item-page-field [fields]="['melbourne.archives.cessationdate']"
                                    [item]="object"
                                    [label]="'item.page.melbourne.archives.cessationdate'"
        >
        </ds-generic-item-page-field>
        <ds-generic-item-page-field [fields]="['dc.type']"
                                    [item]="object"
                                    [label]="'item.page.type'"
        >
        </ds-generic-item-page-field>
        <ds-generic-item-page-field [fields]="['melbourne.course.code']"
                                    [item]="object"
                                    [label]="'item.page.melbourne.course.code'"
        >
        </ds-generic-item-page-field>
        <ds-generic-item-page-field [fields]="['*.subject']"
                                    [item]="object"
                                    [label]="'item.page.subject'"
                                    [separator]="'; '"
        >
        </ds-generic-item-page-field>
        <ds-access-rights-field [item]="object"></ds-access-rights-field>
        <ds-generic-item-page-field [fields]="['melbourne.archives.accessconditions']"
                                    [item]="object"
                                    [label]="'item.page.melbourne.archives.accessconditions'"
                                    [separator]="', '"
        >
        </ds-generic-item-page-field>
        <ds-generic-item-page-field [fields]="['melbourne.archives.useconditions']"
                                    [item]="object"
                                    [label]="'item.page.melbourne.archives.useconditions'"
                                    [separator]="', '"
        >
        </ds-generic-item-page-field>
        <ds-item-page-uri-field [addPrefixIfDoesNotContains]="['doi.org']" [fields]="['dc.identifier.doi', 'dc.description.doi']"
                                [item]="object"
                                [label]="'item.page.doi'"
                                [prefixValue]="'http://dx.doi.org/'">
        </ds-item-page-uri-field>
        <ds-item-page-uri-field [fields]="['*.bitstream.url']"
                                [item]="object"
                                [label]="'item.page.melbourne.bitstream.url'">
        </ds-item-page-uri-field>
        <ds-generic-item-page-field [fields]="['melbourne.archives.referenceid']"
                                    [item]="object"
                                    [label]="'item.page.melbourne.archives.referenceid'"
                                    [separator]="'; '"
        >
        </ds-generic-item-page-field>
        <ds-item-page-uri-field [fields]="['melbourne.archives.digitalurl']"
                                [item]="object"
                                [label]="'item.page.melbourne.archives.digitalurl'"
        >
        </ds-item-page-uri-field>
        <ds-item-page-uri-field [fields]="['melbourne.openaccess.url']"
                                [item]="object"
                                [label]="'item.page.melbourne.openaccess.url-harvestspecific'"
        >
        </ds-item-page-uri-field>
        <ds-item-page-uri-field [fields]="['melbourne.openaccess.pmc']"
                                [item]="object"
                                [label]="'item.page.melbourne.openaccess.pmc'"
        >
        <ds-item-page-uri-field [fields]="['melbourne.linkedresource.url']"
                                [item]="object"
                                [label]="'item.page.melbourne.linkedresource.url'"
        >
        </ds-item-page-uri-field>

        </ds-item-page-uri-field>
        <ds-item-page-grant-field [fields]="['melbourne.identifier.nhmrc']"
                                  [item]="object"
                                  [label]="'item.page.melbourne.grant'"
                                  grantName="NHMRC">
        </ds-item-page-grant-field>
        <ds-item-page-grant-field [fields]="['melbourne.identifier.arc']"
                                  [item]="object"
                                  [label]="'item.page.melbourne.grant'"
                                  grantName="ARC">
        </ds-item-page-grant-field>
        <ds-item-page-uri-field [fields]="['*.identifier.uri']"
                                [item]="object"
                                [label]="'item.page.uri'"
        >
        </ds-item-page-uri-field>
        <ds-generic-item-page-field [fields]="['*.description']"
                                    [item]="object"
                                    [separator]="'<br><br>'"
                                    [label]="'item.page.description'"
        >
        </ds-generic-item-page-field>
        <ds-generic-item-page-field [fields]="['*.description.abstract']"
                                    [item]="object"
                                    [separator]="'<br><br>'"
                                    [label]="'item.page.abstract'"
        >
        </ds-generic-item-page-field>
        <ds-generic-item-page-field [fields]="['melbourne.description.notes']"
                                    [item]="object"
                                    [label]="'item.page.melbourne.description.notes'"
        >
        </ds-generic-item-page-field>
        <ds-generic-item-page-field [fields]="['melbourne.rights']"
                                    [item]="object"
                                    [label]="'item.page.melbourne.rights'"
        >
        </ds-generic-item-page-field>
        <ds-generic-item-page-field [fields]="['*.identifier.citation']"
                                    [item]="object"
                                    [label]="'item.page.citations'"
        >
        </ds-generic-item-page-field>

        <ds-snippets-section [hitHighlightsOfItem]="hitHighlightsOfItem"></ds-snippets-section>

        <div class="mb-2">
            <a [routerLink]="[itemPageRoute + '/full']" class="btn btn-outline-primary" role="button">
                <i class="fas fa-info-circle"></i> {{"item.page.link.full" | translate}}
            </a>
        </div>
        <div class="mb-2">
            <a class="btn btn-outline-primary" href="https://aeon.unimelb.edu.au/" role="button">
                {{"item.page.access.archives" | translate}}
            </a>
        </div>

        <div *ngIf="(collectionType$ | async) === CollectionType.ARCHIVES">
          <!-- Related people & organisations -->
          <ds-item-page-search-link-field [item]="object"
                                          [label]="'item.page.melbourne.related-parties'"
                                          [field]="'dc.contributor.author'"
                                          [perValue]="true"
                                          [firstResultURI]="true"
                                          [commonSearchOptions]="partiesOptions$ | async"
          ></ds-item-page-search-link-field>
          <!-- Related Items ~ melbourne.archives.referenceid prefix -->
          <ds-item-page-search-link-field [item]="object"
                                          [linkText]="'item.page.melbourne.related-items'"
                                          [field]="'melbourne.archives.referenceid'"
                                          [solrField]="'melbourne.archives.referenceid_prefix_keyword'"
                                          [transform]="getPrefix"
                                          [excludeCurrentItem]="true"
                                          [perValue]="false"
                                          [commonSearchOptions]="archivesOptions$ | async"
          ></ds-item-page-search-link-field>
        </div>
        <div *ngIf="(collectionType$ | async) === CollectionType.PARTIES">
          <!-- Related Items -->
          <ds-item-page-search-link-field [item]="object"
                                          [linkText]="'item.page.melbourne.related-items'"
                                          [field]="'dc.contributor.author'"
                                          [perValue]="false"
                                          [commonSearchOptions]="archivesOptions$ | async"
          ></ds-item-page-search-link-field>

        </div>
    </div>
</div>
