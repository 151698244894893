import { Injectable } from '@angular/core';
import {
  DynamicDatePickerModel, DynamicDatePickerModelConfig, DynamicFormControlEvent, DynamicFormControlLayout,
  DynamicFormControlModel, DynamicFormGroupModel, DynamicFormService, DynamicSelectModel,
} from '@ng-dynamic-forms/core';
import { distinctUntilChanged, filter, map, skip, switchMap, tap } from 'rxjs/operators';
import { Bitstream } from '../../core/shared/bitstream.model';
import {
  getAllCompletedRemoteData,
  getAllSucceededRemoteData, getFirstCompletedRemoteData, getFirstSucceededRemoteData,
  getFirstSucceededRemoteWithNotEmptyData,
  getPaginatedListPayload, getRemoteDataPayload,
} from '../../core/shared/operators';
import { Item } from '../../core/shared/item.model';
import { RemoteData } from '../../core/data/remote-data';
import { DSpaceObject } from '../../core/shared/dspace-object.model';
import { Observable } from 'rxjs/internal/Observable';
import { FormGroup } from '@angular/forms';
import { combineLatest } from 'rxjs';
import { RequestService } from '../../core/data/request.service';
import { PutRequest } from '../../core/data/request.models';
import { AccessConditionOptionModel } from '../../core/config/models/config-access-conditions-option.model';
import { LinkService } from '../../core/cache/builders/link.service';
import { followLink } from '../utils/follow-link-config.model';
import { AccessConditionModel } from '../../core/config/models/config-access-conditions.model';
import { RequestEntry } from '../../core/data/request.reducer';
import { NotificationsService } from '../notifications/notifications.service';
import { TranslateService } from '@ngx-translate/core';
import { isNotEmpty } from '../empty.util';
import { SimpleDateModel } from './simple-date.model';
import { PaginatedList } from '../../core/data/paginated-list.model';

@Injectable()
export class DSOAccessConditionsFormService {
  public static ID = 'embargoContainer';
  public static ACCESS_CONDITION = 'accessConditions';
  public static ACCESS_FROM_DATE = 'accessFromDate';
  public static ACCESS_UNTIL_DATE = 'accessUntilDate';

  /**
   * Fallback option for unknown/unset access conditions
   */
  public static CUSTOM_PERMISSION = Object.assign(new AccessConditionOptionModel(), {
      name: 'Custom Permission',
      hasEndDate: false,
      hasStartDate: false,
  });

  private static ACCESS_CONDITIONS_MODEL = {
    id: DSOAccessConditionsFormService.ACCESS_CONDITION,
    name: DSOAccessConditionsFormService.ACCESS_CONDITION,
    options: [],
    disabled: false,
    hidden: false,
    label: ' ',
    hint: ' ',
  };

  private static BASE_DATE_MODEL: DynamicDatePickerModelConfig = {
    id: '',
    name: '',
    disabled: false,
    readOnly: false,
    inline: false,
    label: ' ',
    hint: ' ',
    toggleIcon: 'far fa-calendar',
  };

  private static ACCESS_FROM_DATE_MODEL: DynamicDatePickerModelConfig = {
    ...DSOAccessConditionsFormService.BASE_DATE_MODEL,
    id: DSOAccessConditionsFormService.ACCESS_FROM_DATE,
    name: DSOAccessConditionsFormService.ACCESS_FROM_DATE,
    placeholder: 'Start date',
  };

  private static ACCESS_UNTIL_DATE_MODEL: DynamicDatePickerModelConfig = {
    ...DSOAccessConditionsFormService.BASE_DATE_MODEL,
    id: DSOAccessConditionsFormService.ACCESS_UNTIL_DATE,
    name: DSOAccessConditionsFormService.ACCESS_UNTIL_DATE,
    hidden: true,
    placeholder: 'End date',
  };

  private static DATE_LAYOUT: DynamicFormControlLayout = {
    element: {
      container: 'p-0',
      label: 'col-form-label',
    },
    grid: {
      host: 'col-md-4'
    }
  };

  constructor(
    private formService: DynamicFormService,
    private requestService: RequestService,
    private linkService: LinkService,
    private notificationsService: NotificationsService,
    private translate: TranslateService,
  ) {}

  /**
   * Add an Access Conditions section into a dynamic form
   * @param form
   * @param model
   * @param dsoRD$
   * @param index
   */
  public addAccessConditionsForm(
    form: FormGroup,
    model: DynamicFormControlModel[],
    dsoRD$: Observable<RemoteData<Bitstream | Item>>,
    index: number = null,
  ): Observable<void> {
    return combineLatest([
      this.getAccessConditionsOptions(dsoRD$),
      this.getAccessConditions(dsoRD$),
    ]).pipe(
      map(([accessConditionsOptions, accessConditions]) => {
        this.buildForm(form, model, index, accessConditionsOptions);
        this.setAccessConditions(form, accessConditionsOptions, accessConditions);
        this.updateForm(form, model);
      })
    );
  }

  /**
   * Get a DSO's accessConditionsOptions
   * Works for Item and Bitstream  todo: Item endpoint doesn't exist yet, but should
   */
  protected getAccessConditionsOptions(
    dsoRD$: Observable<RemoteData<Bitstream | Item>>
  ): Observable<AccessConditionOptionModel[]> {
    return dsoRD$.pipe(
      getFirstSucceededRemoteWithNotEmptyData(),
      getRemoteDataPayload(),
      map((dso: Bitstream | Item) => this.linkService.resolveLink<typeof dso>(
        dso, followLink(
          'accessConditionsOptions',  // Options won't change often, so it's ok to use the cache
          { isOptional: true }        // Item doesn't have this link; don't complain if it we can't follow it
        )
      )),
      switchMap((dso: DSpaceObject) => {
        if (dso instanceof Bitstream) {
          return (dso as Bitstream).accessConditionsOptions;
        } else if (dso instanceof Item) {
          return (dso as Item).accessConditionsOptions;
        } else {
          throw new Error(`dso should be an Item or Bitstream, got a ${typeof dso}`);
        }
      }),
      getFirstSucceededRemoteWithNotEmptyData(),
      getRemoteDataPayload(),
      getPaginatedListPayload(),
    );
  }

  /**
   * Get a DSO's accessConditions
   * Works for Item and Bitstream
   */
  protected getAccessConditions(
    dsoRD$: Observable<RemoteData<Bitstream | Item>>,
    useCache: boolean = false,
    first: boolean = true,
  ): Observable<AccessConditionModel[]> {
    return dsoRD$.pipe(
      first ? getFirstSucceededRemoteData() : getAllSucceededRemoteData(),
      getRemoteDataPayload(),
      map((dso: Bitstream | Item) => this.linkService.resolveLink<typeof dso>(
        dso, followLink('accessConditions', {
          shouldEmbed: true,
          useCachedVersionIfAvailable: useCache,
        })
      )),
      switchMap((dso: Item | Bitstream) => dso.accessConditions),
      first ? getFirstCompletedRemoteData() : getAllCompletedRemoteData(),
      map((accessConditionsRD: RemoteData<PaginatedList<AccessConditionModel>>) => {
        if (accessConditionsRD.hasSucceeded) {
          return accessConditionsRD.payload.page.map(ac => { return {
            ...ac,
            startDate: ac.startDate ? new Date(ac.startDate) : null,  // Sneaky strings
            endDate: ac.endDate ? new Date(ac.endDate) : null,
          };});
        } else {
          return [];
        }
      }),
    );
  }

  private buildForm(
    form: FormGroup,
    model: DynamicFormControlModel[],
    index: number,
    accessConditions: AccessConditionOptionModel[],
  ): void {
    if (accessConditions.length > 0) {
      const accessConditionsModel = new DynamicSelectModel(DSOAccessConditionsFormService.ACCESS_CONDITIONS_MODEL);
      const accessFromDateModel = new DynamicDatePickerModel(
        DSOAccessConditionsFormService.ACCESS_FROM_DATE_MODEL, DSOAccessConditionsFormService.DATE_LAYOUT
      );
      const accessUntilDateModel = new DynamicDatePickerModel(
        DSOAccessConditionsFormService.ACCESS_UNTIL_DATE_MODEL, DSOAccessConditionsFormService.DATE_LAYOUT
      );

      accessConditionsModel.options = [
        {
          value: DSOAccessConditionsFormService.CUSTOM_PERMISSION,
          label: DSOAccessConditionsFormService.CUSTOM_PERMISSION.name,
          disabled: true,
        },
        ...accessConditions.map(
          (option: AccessConditionOptionModel) => Object.assign({
            value: option,
            label: option.name,
          })
        ),
      ];

      const group = new DynamicFormGroupModel({
        id: DSOAccessConditionsFormService.ID,
        group: [
          accessConditionsModel,
          accessFromDateModel,
          accessUntilDateModel,
        ]
      });

      // Check if there is a placeholder group
      let placeholderIndex = null;
      model.map((control, idx) => {
        if (control.id === DSOAccessConditionsFormService.ID) {
          placeholderIndex = idx;
        }
      });
      if (placeholderIndex !== null) {
        // Remove placeholder group & insert the new group in its place
        this.formService.removeFormGroupControl(placeholderIndex, form, model);
        this.formService.insertFormGroupControl(placeholderIndex, form, model, group);
      } else {
        // Add or insert the new group
        if (index === null) {
          this.formService.addFormGroupControl(form, model, group);
        } else {
          this.formService.insertFormGroupControl(index, form, model, group);
        }
      }
    }
  }

  /**
   * Update Access Conditions form ~ selected Access Conditions option
   */
  public updateForm(
    form: FormGroup, model: DynamicFormControlModel[],
    event?: DynamicFormControlEvent, dsoRD$?: Observable<RemoteData<Bitstream | Item>>,
  ): void {
    const option = this.getAccessConditionOption(form);
    const group = model.find(g => g.id === DSOAccessConditionsFormService.ID) as DynamicFormGroupModel;

    if (group) {
      group.get(1).hidden = !option || !option.hasStartDate;
      group.get(2).hidden = !option || !option.hasEndDate;
    }

    if (event?.model.id === DSOAccessConditionsFormService.ACCESS_CONDITION) {
      this.getAccessConditions(dsoRD$).subscribe((values) => {
        this.resetDates(form, option, values);
      });
    }
  }

  /**
   * A placeholder for the Access Conditions form section.
   * Should be included in dynamic form model constants with which this service is to be used.
   */
  public static get placeholder(): DynamicFormGroupModel {
    return new DynamicFormGroupModel({
      id: DSOAccessConditionsFormService.ID,
      group: [
        new DynamicSelectModel(DSOAccessConditionsFormService.ACCESS_CONDITIONS_MODEL),
        new DynamicDatePickerModel(
          DSOAccessConditionsFormService.ACCESS_FROM_DATE_MODEL, DSOAccessConditionsFormService.DATE_LAYOUT
        ),
        new DynamicDatePickerModel(
          DSOAccessConditionsFormService.ACCESS_UNTIL_DATE_MODEL, DSOAccessConditionsFormService.DATE_LAYOUT
        ),
      ]
    });
  }

  protected getAccessConditionOption(form: FormGroup): AccessConditionOptionModel {
    return form.get(`${DSOAccessConditionsFormService.ID}.${DSOAccessConditionsFormService.ACCESS_CONDITION}`).value;
  }

  getStartDate(form: FormGroup): SimpleDateModel {
    return new SimpleDateModel(
      form.get(`${DSOAccessConditionsFormService.ID}.${DSOAccessConditionsFormService.ACCESS_FROM_DATE}`).value
    );
  }

  getEndDate(form: FormGroup): SimpleDateModel {
    return new SimpleDateModel(
      form.get(`${DSOAccessConditionsFormService.ID}.${DSOAccessConditionsFormService.ACCESS_UNTIL_DATE}`).value
    );
  }

  setAccessConditions(form: FormGroup, options: AccessConditionOptionModel[], values: AccessConditionModel[]): void {
    let option: AccessConditionOptionModel;
    try {
      option = options.find(o => o.name === values[0].name);
    } catch {
      option = DSOAccessConditionsFormService.CUSTOM_PERMISSION;
    }

    form.patchValue({
      [DSOAccessConditionsFormService.ID]: {
        [DSOAccessConditionsFormService.ACCESS_CONDITION]: option,
      },
    });

    this.resetDates(form, option, values);
  }

  private resetDates(form: FormGroup, option: AccessConditionOptionModel, values: AccessConditionModel[]): void {
    let end = null;
    let start = null;

    if (option.hasStartDate) {
      const startDate = values.map(v => v.startDate).find(v => v);
      if (startDate !== undefined) {
        start = new SimpleDateModel(startDate);
      } else {
        start = new SimpleDateModel(new Date());
      }
    }
    if (option.hasEndDate) {
      const endDate = values.map(v => v.endDate).find(v => v);

      if (endDate !== undefined) {
        end = new SimpleDateModel(endDate);
      } else {
        if (option.maxEndDate) {
          end = new SimpleDateModel(option.maxEndDate);
        } else {
          end = new SimpleDateModel(new Date());
        }
      }
    }

    form.patchValue({
      [DSOAccessConditionsFormService.ID]: {
        [DSOAccessConditionsFormService.ACCESS_FROM_DATE]: start,
        [DSOAccessConditionsFormService.ACCESS_UNTIL_DATE]: end
      },
    });
  }

  protected getAccessConditionsFromOption(form: FormGroup): AccessConditionModel {
    const option = this.getAccessConditionOption(form);
    return {
      name: option.name,
      description: null,
      startDate: option.hasStartDate ? this.getStartDate(form).asDate() : null,
      endDate: option.hasEndDate ? this.getEndDate(form).asDate() : null,
    } as AccessConditionModel;
  }

  /**
   * Emits true if the Access Conditions section of the form is changed.
   * @param form
   * @param dsoRD$
   */
  public hasChanges(
    form: FormGroup,
    dsoRD$: Observable<RemoteData<Bitstream | Item>>
  ): Observable<boolean> {
    return combineLatest([
      form.valueChanges,
      this.getAccessConditions(dsoRD$, false, true),
    ]).pipe(
      map(([_, values]) => {
        const option = this.getAccessConditionOption(form);
        const startDate = this.getStartDate(form);
        const endDate = this.getEndDate(form);

        if (option && isNotEmpty(values)) {
          const optionChanged = values[0].name !== option.name;

          // Access conditions are exposed as "policy start/end events"
          // Therefore an access condition with a date is duplicated into two values, one with the date as startDate
          //  and one with the date as end date. To check for changes, all values should be considered.
          const startDateChanged = option.hasStartDate
            && values.find(v => startDate?.equals(v.startDate)) === undefined;
          const endDateChanged = option.hasEndDate
            && values.find(v => endDate?.equals(v.endDate)) === undefined;

          return optionChanged || startDateChanged || endDateChanged;
        } else {
          // Show discard/submit by default so changes can be made in case e.g. the access conditions are not configured
          return true;
        }
      })
    );
  }

  /**
   * Submit the Access Conditions section of the form.
   * @param form
   * @param model
   * @param dsoRD$
   */
  public submit(
    form: FormGroup,
    model: DynamicFormControlModel[],
    dsoRD$: Observable<RemoteData<Bitstream | Item>>,
  ): Observable<RequestEntry> {
    const requestId = this.requestService.generateRequestId();

    dsoRD$.pipe(
      getFirstSucceededRemoteWithNotEmptyData(),
      getRemoteDataPayload(),
    ).subscribe((dso: Item | Bitstream) => {
      this.requestService.send(new PutRequest(
        requestId,
        dso._links.accessConditions.href,
        this.getAccessConditionsFromOption(form),
      ));

      // remove the dso from cache, such that modified metadata is displayed without hard page refresh
      this.requestService.setStaleByHrefSubstring(dso._links.self.href);
    });

    return this.requestService.getByUUID(requestId).pipe(
      filter((requestEntry: RequestEntry) => requestEntry.response !== null),
      tap(() => {
        combineLatest([
          this.getAccessConditionsOptions(dsoRD$),
          // a request has been sent to change the accessConditions, but will not be reflected here immediately
          //  -> look at _changes in emissions_
          //  -> the first emission corresponds to the _previous_ value, so we skip it
          this.getAccessConditions(dsoRD$).pipe(
            distinctUntilChanged(),
            skip(1),
          ),
        ]).subscribe(([accessConditionsOptions, accessConditions]) => {
          this.setAccessConditions(form, accessConditionsOptions, accessConditions);
          this.updateForm(form, model);
        });
      }),
    );
  }

  public notify(requestEntry: RequestEntry, onSuccess: boolean = false): void {
    let type = 'DSpaceObject';

    if (requestEntry.request.href.includes('item')) {
      type = 'Item';
    } else if (requestEntry.request.href.includes('bitstream')) {
      type = 'Bitstream';
    }

    if (requestEntry.response.statusCode === 200) {
      if (onSuccess) {
        this.notificationsService.success(
          this.translate.instant('access-conditions.notification.success.title'),
          this.translate.instant('access-conditions.notification.success.content', { type: type }),
        );
      }
    } else {
      this.notificationsService.error(
        this.translate.instant('access-conditions.notification.failure.title'),
        this.translate.instant('access-conditions.notification.failure.content', { type: type }),
      );
    }
  }

  /**
   * Discard any changes in the Access Conditions section of the form.
   * @param form
   * @param model
   * @param dsoRD$
   */
  public discard(
    form: FormGroup,
    model: DynamicFormControlModel[],
    dsoRD$: Observable<RemoteData<Bitstream | Item>>
  ): void {
    combineLatest([
      this.getAccessConditionsOptions(dsoRD$),
      this.getAccessConditions(dsoRD$),
    ]).subscribe(([accessConditionsOptions, accessConditions]) => {
        this.setAccessConditions(form, accessConditionsOptions, accessConditions);
        this.updateForm(form, model);
    });
  }
}
