import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { Item } from '../../core/shared/item.model';
import { Observable, of } from 'rxjs';
import { getFirstCompletedRemoteData } from '../../core/shared/operators';
import { map } from 'rxjs/operators';
import { hasNoValue, hasValue } from '../empty.util';
import { LinkService } from '../../core/cache/builders/link.service';
import { followLink } from '../utils/follow-link-config.model';

/**
 * Component that renders the thumbnails from URL's in metadata fields on the simple item page
 */
@Component({
  selector: 'ds-melbourne-thumbnail',
  templateUrl: './melbourne-thumbnail.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MelbourneThumbnailComponent implements OnInit {

  @Input()
  item: Item;

  /**
   * Passed through to {@link MelbourneUrlThumbnailComponent}.
   */
  @Input()
  clickable = true;

  /**
   * Passed through to {@link MelbourneUrlThumbnailComponent}.
   */
  @Input() imageCount: number = null;

  @Input()
  limitWidth = false;

  @Input()
  mdFieldsForThumbnail: string[];

  hasBitstreamThumbnail: Observable<boolean>;

  constructor(
    private linkService: LinkService
  ) {}

  ngOnInit(): void {
    this.hasBitstreamThumbnail = this.hasThumbnail();
  }

  hasThumbnail(): Observable<boolean> {
    if (!this.item) {
      return of(false);
    }

    if (!this.item.thumbnail) {
      this.linkService.resolveLink(this.item, followLink('thumbnail'));
    }

    const mdValue = this.item.firstMetadataValue(this.mdFieldsForThumbnail);

    return this.item.thumbnail?.pipe(
      getFirstCompletedRemoteData(),
      map((rd) => {
        return hasValue(rd.payload) || hasNoValue(mdValue);
      })
    );
  }

}
