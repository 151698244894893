<ng-container *ngVar="(data$ | async) as data">
  <table class="table table-striped" *ngIf="data?.length > 0">
    <thead>
      <tr>
        <th class="d-flex">
          <div *ngIf="displayColours" class="legend-color mx-2 align-self-center"></div>
          <span>{{ 'statlets.type.' + statlet.shortName + '.' + (inverseData ? statlet.graphtype.listViewOptions.columnLabelAxis : statlet.graphtype.listViewOptions.rowLabelAxis) + '-axis' | translate }}</span>
        </th>
        <th class="text-right" *ngFor="let segment of data[0].series" [innerHTML]="'statlets.type.' + statlet.shortName + '.' + (inverseData ? statlet.graphtype.listViewOptions.rowLabelAxis : statlet.graphtype.listViewOptions.columnLabelAxis) + '-axis.' + segment.name | translate: { default: segment.name }"></th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let row of data; let idx = index">
        <td class="text-wrap d-flex">
          <ng-container *ngVar="(colors$ | async) as colors">
            <div *ngIf="displayColours" class="legend-color mx-2 align-self-center"
                 [ngStyle]="{ background: colors.domain[idx % colors.domain.length] }">
            </div>
          </ng-container>
          <a *ngIf="renderLinks && row.link && !row.link.absoluteLink" [routerLink]="row.link.href" [queryParams]="row.link.queryParams">{{ row.name }}</a>
          <a *ngIf="renderLinks && row.link && row.link.absoluteLink" href="{{row.link.href}}" target="_blank">{{ row.name }}</a>
          <span *ngIf="!renderLinks || !row.link">{{ row.name }}</span>
        </td>
        <td class="text-wrap text-right" *ngFor="let segment of row.series">{{ segment.value | dsDecimal:'1.0-2' }}</td>
      </tr>
    </tbody>
  </table>
</ng-container>
