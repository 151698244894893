<div *ngIf="hasBitstreamThumbnail | async">
  <ds-thumbnail
    [thumbnail]="item?.thumbnail | async"
    [limitWidth]="limitWidth"
  ></ds-thumbnail>
</div>
<div *ngIf="!(hasBitstreamThumbnail | async)">
  <ds-melbourne-url-thumbnail
    [clickable]="clickable"
    [metadataValues]="item.allMetadata(mdFieldsForThumbnail)"
    [imageCount]="imageCount"
  ></ds-melbourne-url-thumbnail>
</div>
