import { Component, Input, OnInit } from '@angular/core';
import { Observable, of } from 'rxjs';
import { select, Store } from '@ngrx/store';
import { AuthMethod } from '../../core/auth/models/auth.method';
import {
  getAuthenticationError,
  getAuthenticationMethods,
  isAuthenticated,
  isAuthenticationLoading
} from '../../core/auth/selectors';
import { CoreState } from '../../core/core.reducers';
import { getForgotPasswordRoute, getRegisterRoute } from '../../app-routing-paths';
import { hasValue } from '../empty.util';
import { AuthService } from '../../core/auth/auth.service';
import { AuthorizationDataService } from '../../core/data/feature-authorization/authorization-data.service';
import { FeatureID } from '../../core/data/feature-authorization/feature-id';
import { map } from 'rxjs/operators';

const moveMethodTo = (methods: AuthMethod[], methodType: string, newIndex: number): AuthMethod[] =>  {
  const currentIndex = methods.findIndex(m => m.authMethodType === methodType);

  if (currentIndex !== -1) {
    const updatedMethods = [...methods];
    const method = updatedMethods.splice(currentIndex, 1)[0];
    updatedMethods.splice(newIndex, 0, method);
    return updatedMethods;
  }  else {
    return methods;
  }
};

/**
 * /users/sign-in
 * @class LogInComponent
 */
@Component({
  selector: 'ds-log-in',
  templateUrl: './log-in.component.html',
  styleUrls: ['./log-in.component.scss']
})
export class LogInComponent implements OnInit {

  /**
   * A boolean representing if LogInComponent is in a standalone page
   * @type {boolean}
   */
  @Input() isStandalonePage: boolean;

  /**
   * The list of authentication methods available
   * @type {AuthMethod[]}
   */
  public authMethods: Observable<AuthMethod[]>;

  /**
   * Whether user is authenticated.
   * @type {Observable<string>}
   */
  public isAuthenticated: Observable<boolean>;

  /**
   * True if the authentication is loading.
   * @type {boolean}
   */
  public loading: Observable<boolean>;

  /**
   * Whether or not the current user (or anonymous) is authorized to register an account
   */
  canRegister$: Observable<boolean>;

  constructor(private store: Store<CoreState>,
              private authService: AuthService,
              private authorizationService: AuthorizationDataService) {
  }

  ngOnInit(): void {

    this.authMethods = this.store.pipe(
      select(getAuthenticationMethods),
      map(methods => {  // todo: Quick fix -- show shibboleth _before_ password
        methods = moveMethodTo(methods, 'password', 0);
        methods = moveMethodTo(methods, 'shibboleth', 0);
        return methods;
      }),
    );

    // set loading
    this.loading = this.store.pipe(select(isAuthenticationLoading));

    // set isAuthenticated
    this.isAuthenticated = this.store.pipe(select(isAuthenticated));

    // Clear the redirect URL if an authentication error occurs and this is not a standalone page
    this.store.pipe(select(getAuthenticationError)).subscribe((error) => {
      if (hasValue(error) && !this.isStandalonePage) {
        this.authService.clearRedirectUrl();
      }
    });

    this.canRegister$ = this.authorizationService.isAuthorized(FeatureID.EPersonRegistration);
  }

  getRegisterRoute() {
    return getRegisterRoute();
  }

  getForgotRoute() {
    return getForgotPasswordRoute();
  }
}
