import { link, typedObject } from '../../../../../core/cache/builders/build-decorators';
import { HALResource } from '../../../../../core/shared/hal-resource.model';
import { STATLET } from './statlet.resource-type';
import { autoserialize, autoserializeAs, deserialize } from 'cerialize';
import { HALLink } from '../../../../../core/shared/hal-link.model';
import { StatletFilter } from './statlet-filter.model';
import { StatletPoint } from './statlet-point.model';
import { StatletStyle } from './statlet-style.model';
import { StatletPosition } from './statlet-position.model';
import { StatletReportType } from './statlet-report-type.model';
import { CacheableObject } from '../../../../../core/cache/object-cache.reducer';
import {
  StatletGraphType,
  StatletGraphTypeSerializer
} from '../../single-statlet/graph-types/statlet-graph-types.model';
import { StatletHeader } from './statlet-header.model';
import { PaginatedList } from '../../../../../core/data/paginated-list.model';
import { RemoteData } from '../../../../../core/data/remote-data';
import { Observable } from 'rxjs';
import { StatletSortOption } from './statlet-sort-option.model';

@typedObject
export class Statlet extends HALResource implements CacheableObject {
  static type = STATLET;
  type = STATLET;

  @autoserialize
  id: string;

  @autoserializeAs('id')
  uuid: string;

  @autoserialize
  dso: string;

  @autoserialize
  position: StatletPosition;

  @autoserializeAs(StatletFilter)
  filters: StatletFilter[];

  @autoserializeAs(StatletFilter)
  optionalFilters: StatletFilter[];

  @autoserializeAs(StatletFilter)
  appliedFilters: StatletFilter[];

  @autoserialize
  sizes: number[];

  @autoserialize
  appliedSize: number;

  @autoserializeAs(StatletStyle)
  style: StatletStyle;

  @autoserializeAs('report-type')
  reportType: StatletReportType;

  @autoserialize
  shortName: string;

  @autoserializeAs(StatletGraphTypeSerializer)
  graphtype: StatletGraphType;

  @autoserialize
  rowHeaderCategory: string;

  @autoserialize
  rowHeaderType: string;

  @autoserialize
  columnHeaderCategory: string;

  @autoserialize
  columnHeaderType: string;

  @autoserialize
  points: StatletPoint[];

  @autoserializeAs(StatletHeader)
  rowHeaders: StatletHeader[];

  @autoserializeAs(StatletHeader)
  columnHeaders: StatletHeader[];

  @autoserialize
  optionalSortOptions: StatletSortOption[];

  @autoserialize
  defaultSortOption: StatletSortOption;

  @autoserialize
  appliedSortOption: StatletSortOption;

  @deserialize
  _links: {
    self: HALLink;
    nestedStatlets?: HALLink;
  };

  /**
   * A list of nested {@link Statlet}s related to this {@link Statlet}
   */
  @link(STATLET, true)
  nestedStatlets?: Observable<RemoteData<PaginatedList<Statlet>>>;
}
