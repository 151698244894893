import { Component, Injector } from '@angular/core';
import { slideMobileNav } from '../shared/animations/slide';
import { MenuComponent } from '../shared/menu/menu.component';
import { MenuService } from '../shared/menu/menu.service';
import { MenuID, MenuItemType } from '../shared/menu/initial-menus-state';
import { TextMenuItemModel } from '../shared/menu/menu-item/models/text.model';
import { LinkMenuItemModel } from '../shared/menu/menu-item/models/link.model';
import { HostWindowService } from '../shared/host-window.service';
import { environment } from '../../environments/environment';
import { StatisticsMenuService } from '../shared/menu/statistics-menu/statistics-menu.service';
import { map, take } from 'rxjs/operators';
import { VHostService } from '../core/services/vhost.service';
import { hasValue, isEmpty, isNotEmptyOperator } from '../shared/empty.util';
import { AuthService } from '../core/auth/auth.service';

/**
 * Component representing the public navbar
 */
@Component({
  selector: 'ds-navbar',
  styleUrls: ['./navbar.component.scss'],
  templateUrl: './navbar.component.html',
  animations: [slideMobileNav]
})
export class NavbarComponent extends MenuComponent {
  /**
   * The menu ID of the Navbar is PUBLIC
   * @type {MenuID.PUBLIC}
   */
  menuID = MenuID.PUBLIC;

  constructor(
    protected menuService: MenuService,
    protected statisticsMenuService: StatisticsMenuService,
    protected injector: Injector,
    protected authService: AuthService,
    public windowService: HostWindowService,
    protected vhostService: VHostService,
  ) {
    super(menuService, injector);
  }

  ngOnInit(): void {
    this.createMenu();
    this.createStatisticsMenu();
    super.ngOnInit();
  }

  /**
   * Initialize all menu sections and items for this menu
   */
  createMenu() {
    const menuList: any[] = [
      /* Communities & Collections tree */
      {
        id: `browse_global_communities_and_collections`,
        active: false,
        visible: true,
        index: 0,
        model: {
          type: MenuItemType.LINK,
          text: `menu.section.browse_global_communities_and_collections`,
          link: `/community-list`
        } as LinkMenuItemModel
      },
      /* News */
      {
        id: 'browse_global',
        active: false,
        visible: true,
        index: 1,
        model: {
          type: MenuItemType.TEXT,
          text: 'menu.section.browse_global'
        } as TextMenuItemModel,
      },
      {
        id: 'my_lists',
        active: false,
        visible: true,
        model: {
          type: MenuItemType.LINK,
          text: 'menu.section.my_lists',
          link: `/my-lists`
        } as LinkMenuItemModel,
        index: 2
      },
    ];
    // Read the different Browse-By types from config and add them to the browse menu
    const types = environment.browseBy.types;
    types.forEach((typeConfig) => {
      menuList.push({
        id: `browse_global_by_${typeConfig.id}`,
        parentID: 'browse_global',
        active: false,
        visible: true,
        model: {
          type: MenuItemType.LINK,
          text: `menu.section.browse_global_by_${typeConfig.id}`,
          link: `/browse/${typeConfig.id}`
        } as LinkMenuItemModel
      });
    });
    menuList.forEach((menuSection) => this.menuService.addSection(this.menuID, Object.assign(menuSection, {
      shouldPersistOnRouteChange: true
    })));

  }

  /**
   * Create the statistics menu
   *
   * If the current tenant (i.e. the first part of the VHost, some-tenant.somewhere.org -> some-tenant)
   * matches one of the standalone tenants configured in the environment, use type='standAloneTenant_${tenant}'
   * Otherwise, use type='Site' (default DSpace behaviour)
   */
  createStatisticsMenu() {
    this.vhostService.getVHostFromStore().pipe(
      isNotEmptyOperator(),
      take(1),
      map((vhost: string) => {
        if (hasValue(vhost) && !isEmpty(environment.atmire.cua.standAloneTenants)) {
          const tenant = vhost.substring(0, vhost.indexOf('.'));
          if (environment.atmire.cua.standAloneTenants.includes(tenant)) {
            // The "standAlone" part is already prepended by StatisticsMenuService.addMenuOptionsForType
            return `Tenant_${tenant}`;
          }
        }
        return 'Site';
      }),
    ).subscribe((type: string) => {
      this.statisticsMenuService.addMenuOptionsForType(type, true);
    });
  }

}
