import { Component } from '@angular/core';

@Component({
  selector: 'ds-home-icon',
  template: `<svg viewBox="0 0 48 48" fill="currentColor"><path d="M20 41V29h8v12h10V25h6L24 7 4 25h6v16h10z"></path></svg>`,
  styles: [
    `:host {
      display: inline-block;
    }`,
    `svg {
      height: 100%;
      vertical-align: top;
    }`
  ]
})
export class HomeIconComponent {}
