import {StoreVHostAction, StoreVHostCommunityAction, VHostAction, VHostActionTypes} from './vhost.actions';

/**
 * The VHost state
 */
export interface VHostState {
  vHost?: string;
  community?: string;
}

const initialState: VHostState = {};

/**
 * The VHost reducer
 * @param state
 *    the current vHost state
 * @param action
 *    the action to perform on the state
 * @return
 *    the new vHost state
 */
export function vHostReducer(state = initialState, action: VHostAction): VHostState {

  switch (action.type) {

    case VHostActionTypes.STORE: {
      return Object.assign({}, state, {
        vHost: (action as StoreVHostAction).payload.vHost,
      });
    }

    case VHostActionTypes.STORE_COMMUNITY: {
      return Object.assign({}, state, {
        community: (action as StoreVHostCommunityAction).payload.uuid,
      });
    }

    default: {
      return state;
    }
  }
}
