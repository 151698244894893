<nav [ngClass]="{'open': !(menuCollapsed | async)}"
     [@slideMobileNav]="!(windowService.isXsOrSm() | async) ? 'default' : ((menuCollapsed | async) ? 'collapsed' : 'expanded')"
     class="navbar navbar-expand-md navbar-dark bg-primary p-0 navbar-container align-items-stretch"
     role="navigation" [attr.aria-label]="'nav.main.description' | translate">
    <div class="w-100 align-items-md-center d-flex">
        <a class="logo-link mr-4 justify-content-start d-none d-md-inline" href="https://www.unimelb.edu.au/" target="_blank" rel="noopener noreferrer" >
            <img src="assets/images/melbourne-logo.svg" alt="logo"/>
        </a>
        <a
                [attr.aria-label]="'nav.home' | translate"
                class="d-none d-md-flex flex-column align-self-center page-title font-weight-bold" href="https://library.unimelb.edu.au/"
        >
          <span>
            <ds-home-icon style="height: 1.3rem"></ds-home-icon>
              {{'nav.home' | translate}}
          </span>
        </a>
        <div class="menu-links-container d-flex flex-column ml-0 ml-md-auto pr-md-3 h-100">
            <nav id="collapsingNav" class="justify-content-md-end flex-md-grow-1">
                <ul
                  class="navbar-nav text-center font-weight-bold align-items-md-end justify-content-md-end flex-grow-1 h-100">
                    <li class="nav-item header-menu-item d-flex flex-column justify-content-center"><a class="nav-item nav-link" target="_blank" href="http://library.unimelb.edu.au/digital-collections#digitised-collections">{{'nav.digitisedcollections.faq' | translate}}</a></li>
                    <ng-container *ngFor="let section of (sections | async)">
                        <ng-container
                                *ngComponentOutlet="(sectionMap$ | async).get(section.id).component; injector: (sectionMap$ | async).get(section.id).injector;"></ng-container>
                    </ng-container>
                </ul>
            </nav>
        </div>
        <div class="header-button d-none d-md-flex">
            <a class="btn w-100 d-flex flex-column align-items-center justify-content-center" routerLink="/search">
                <svg aria-label="search" focusable="false" role="img">
                    <svg viewBox="0 0 10 10" id="um-icon-search">
                        <path d="M7.148 6.29h-.454l-.157-.158c.557-.65.896-1.493.896-2.416C7.433 1.664 5.77 0 3.716 0 1.664 0 0 1.664 0 3.716 0 5.77 1.664 7.432 3.716 7.432c.923 0 1.766-.338 2.416-.895l.158.157v.453L9.147 10 10 9.148zm-3.432 0c-1.42 0-2.572-1.153-2.572-2.574 0-1.42 1.15-2.573 2.572-2.573S6.29 2.295 6.29 3.716c0 1.42-1.153 2.573-2.574 2.573z"></path>
                    </svg>
                    <title>search</title>
                </svg>
                <span class="small">Search</span></a>
        </div>
        <!--        <ds-search-navbar class="navbar-collapsed"></ds-search-navbar>-->
        <!--        <ds-lang-switch class="navbar-collapsed"></ds-lang-switch>-->
<!--        <div class="header-button d-none d-md-flex">-->
<!--            <ds-themed-atmire-saved-item-list-link class="w-100"></ds-themed-atmire-saved-item-list-link>-->
<!--        </div>-->
        <ds-auth-nav-menu class="navbar-collapsed d-none d-md-inline"></ds-auth-nav-menu>
        <ds-impersonate-navbar class="navbar-collapsed d-none d-md-inline"></ds-impersonate-navbar>
    </div>
</nav>

