<div class="d-inline-block w-100">
  <div class="d-flex w-100">
    <p class="lead mb-0 mr-auto">{{ title }}</p>
    <div *ngIf="hasExtraMetadata && extraMetadataSectionEnabled" class="metadata-toggle mx-2" role="button" tabindex="0"
          (click)="expanded = !expanded"
          (keyup.enter)="expanded = !expanded"
          (keydown.space)="$event.stopPropagation(); $event.preventDefault()"
          (keyup.space)="expanded = !expanded"
          [attr.aria-expanded]="expanded">
      <i [ngClass]="expanded ? 'fa fa-chevron-down' : 'fa fa-chevron-left'"></i>
    </div>
  </div>
  <div *ngIf="orcid">
    <a *ngIf="uri" target="_blank" [href]="uri.value">{{ orcid.value }}</a>
    <span *ngIf="!uri">{{ orcid }}</span>
  </div>
  <div *ngIf="extraMetadataSectionEnabled"
       class="overflow-hidden"
       [@slide]="expanded ? 'expanded' : 'collapsed'"
       [class]="expanded ? 'visible' : 'invisible'"> <!-- setting visibility: hidden ensures the content isn't included in the tab order-->
    <ul class="list-unstyled">
      <li *ngIf="firstName"><span class="font-weight-bold">{{ 'atmire.submission.search-modal.list-element.firstName' | translate }}</span>{{ firstName.value }}</li>
      <li *ngIf="lastName"><span class="font-weight-bold">{{ 'atmire.submission.search-modal.list-element.lastName' | translate }}</span>{{ lastName.value }}</li>
      <li *ngIf="orcid"><span class="font-weight-bold">{{ 'atmire.submission.search-modal.list-element.orcid' | translate }}</span>{{ orcid.value }}</li>
      <li *ngIf="uri"><span class="font-weight-bold">{{ 'atmire.submission.search-modal.list-element.uri' | translate }}</span><a target="_blank" [href]="uri.value">{{ uri.value }}</a></li>
      <li *ngIf="email"><span class="font-weight-bold">{{ 'atmire.submission.search-modal.list-element.email' | translate }}</span>{{ email.value }}</li>
      <li *ngIf="proprietaryId"><span class="font-weight-bold">{{ 'atmire.submission.search-modal.list-element.proprietaryId' | translate }}</span>{{ proprietaryId.value }}</li>
    </ul>
  </div>
</div>
