import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ExtendEmbargoService } from '../extend-embargo.service';
import { FeatureID } from '../../core/data/feature-authorization/feature-id';
import { AuthorizationDataService } from '../../core/data/feature-authorization/authorization-data.service';
import { Bitstream } from '../../core/shared/bitstream.model';
import { getFirstCompletedRemoteData } from '../../core/shared/operators';
import { RemoteData } from '../../core/data/remote-data';
import { filter, switchMap, take, takeUntil } from 'rxjs/operators';
import { ReplaySubject, Subject } from 'rxjs';
import { NotificationsService } from '../../shared/notifications/notifications.service';
import { TranslateService } from '@ngx-translate/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ConfirmationModalComponent } from '../../shared/confirmation-modal/confirmation-modal.component';
import { Item } from '../../core/shared/item.model';
import { RequestService } from '../../core/data/request.service';

@Component({
  selector: 'ds-extend-embargo-link',
  templateUrl: './extend-embargo-link.component.html',
  styleUrls: ['./extend-embargo-link.component.scss']
})
export class ExtendEmbargoLinkComponent implements OnInit, OnDestroy {

  messagePrefix = 'item.page.filesection.extend-embargo';

  @Input() item: Item;
  @Input() bitstream: Bitstream;

  @Input() prefix: string = null;
  @Input() postfix: string = null;

  protected destroy$: Subject<void> = new Subject();

  public checkCanExtendEmbargo$: Subject<void>;
  public canExtendEmbargo$: Subject<boolean>;

  constructor(
    protected authorizationDataService: AuthorizationDataService,
    protected extendEmbargoService: ExtendEmbargoService,
    protected notificationsService: NotificationsService,
    protected translateService: TranslateService,
    protected modalService: NgbModal,
    protected requestService: RequestService,
  ) {
    this.checkCanExtendEmbargo$ = new ReplaySubject<void>(1);
    this.canExtendEmbargo$ = new ReplaySubject<boolean>(1);

    this.checkCanExtendEmbargo$.pipe(
      switchMap(() => {
        // check authorization every time a value is pushed into checkCanExtendEmbargo$
        // NOTE: this value is cached and/or may not reflect the latest state yet
        return this.authorizationDataService.isAuthorized(FeatureID.ExtendEmbargo, this.bitstream.self);
      }),
      takeUntil(this.destroy$)
    ).subscribe(this.canExtendEmbargo$);
  }

  ngOnInit(): void {
    this.checkCanExtendEmbargo$.next();
  }

  handleClick(): void {
    const modalRef = this.modalService.open(ConfirmationModalComponent);
    modalRef.componentInstance.dso = this.bitstream;
    modalRef.componentInstance.headerLabel = `${this.messagePrefix}.confirm-extend-modal.header`;
    modalRef.componentInstance.infoLabel = `${this.messagePrefix}.confirm-extend-modal.info`;
    modalRef.componentInstance.cancelLabel = `${this.messagePrefix}.confirm-extend-modal.cancel`;
    modalRef.componentInstance.confirmLabel = `${this.messagePrefix}.confirm-extend-modal.confirm`;

    modalRef.componentInstance.response.pipe(
      take(1),
      filter((isConfirmed: boolean) => isConfirmed),
      switchMap(() => {
        return this.extendEmbargoService.extendEmbargo(this.bitstream).pipe(
          getFirstCompletedRemoteData(),
        );
      }),
      takeUntil(this.destroy$),
    ).subscribe((rd: RemoteData<Bitstream>) => {
      if (rd.hasSucceeded) {
        this.requestService.setStaleByHrefSubstring(this.item._links.self.href);
        this.notificationsService.success(
          null,
          this.translateService.get(`${this.messagePrefix}.notifications.success.content`),
        );

        // assume the user cannot extend the embargo anymore, after the first successful attempt
        this.canExtendEmbargo$.next(false);
      } else {
        this.notificationsService.error(
          null,
          this.translateService.get(`${this.messagePrefix}.notifications.error.content`),
        );
      }
    });
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

}
