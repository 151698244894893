<ds-metadata-field-wrapper *ngIf="withWrapper; else searchLinkField" [label]="(label | translate)">
  <ng-container *ngTemplateOutlet="searchLinkField"></ng-container>
</ds-metadata-field-wrapper>

<ng-template #searchLinkField>
  <ul class="search-link-field" [ngClass]="{ 'list-unstyled': hideBullets, 'mb-0': !withWrapper }">
    <ng-container *ngFor="let link of (searchLinks$ | async)">
      <li *ngIf="link.hasResults">
        <a [href]="link.href">
          {{ link.text }}
        </a>
      </li>
    </ng-container>
  </ul>
</ng-template>
