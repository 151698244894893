import { Component, Inject, Injector } from '@angular/core';
import { SingleStatletTableComponent as BaseComponent } from '../../../../../../../../../app/atmire-cua/statlets/shared/single-statlet/graph-types/single-statlet-table/single-statlet-table.component';
import {
  DEFAULT_STATLET_CONTEXT,
  rendersSingleStatletForType
} from '../../../../../../../../../app/atmire-cua/statlets/shared/single-statlet/graph-types/single-statlet.decorator';
import { StatletGraphTypes } from '../../../../../../../../../app/atmire-cua/statlets/shared/single-statlet/graph-types/statlet-graph-types.model';
import { StatletPosition } from '../../../../../../../../../app/atmire-cua/statlets/shared/data/models/statlet-position.model';
import { StatletReportType } from '../../../../../../../../../app/atmire-cua/statlets/shared/data/models/statlet-report-type.model';
import { map, switchMap } from 'rxjs/operators';
import { zip as observableZip, combineLatest as observableCombineLatest } from 'rxjs';
import { hasValue, isNotEmpty } from '../../../../../../../../../app/shared/empty.util';
import { AtmireCuaColorService } from '../../../../../../../../../app/atmire-cua/shared/atmire-cua-color.service';
import { AlternativeLabelService } from '../../../../../../../../../app/atmire-cua/shared/alternative-label.service';
import { HostWindowService } from '../../../../../../../../../app/shared/host-window.service';
import { STATLET_DATA_TYPE_SERVICE_FACTORY } from '../../../../../../../../../app/atmire-cua/statlets/shared/single-statlet/data-type-services/statlet-data-type.decorator';
import { GenericConstructor } from '../../../../../../../../../app/core/shared/generic-constructor';
import { ItemDataService } from '../../../../../../../../../app/core/data/item-data.service';
import { getFirstSucceededRemoteDataPayload } from '../../../../../../../../../app/core/shared/operators';
import { Item } from '../../../../../../../../../app/core/shared/item.model';

@rendersSingleStatletForType(StatletGraphTypes.TABLE, DEFAULT_STATLET_CONTEXT, 'melbourne')
@rendersSingleStatletForType(StatletGraphTypes.LIST, DEFAULT_STATLET_CONTEXT, 'melbourne')
@Component({
  selector: 'ds-atmire-cua-single-statlet-table',
  templateUrl: './single-statlet-table.component.html',
  styleUrls: ['../../../../../../../../../app/atmire-cua/statlets/shared/single-statlet/graph-types/single-statlet-table/single-statlet-table.component.scss']
})
export class SingleStatletTableComponent extends BaseComponent {

  shouldRenderPrimaryAuthor = false;

  constructor(protected parentInjector: Injector,
              protected colorService: AtmireCuaColorService,
              protected alternativeLabelService: AlternativeLabelService,
              protected windowService: HostWindowService,
              protected itemDataService: ItemDataService,
              @Inject(STATLET_DATA_TYPE_SERVICE_FACTORY) protected getStatletDataTypeServiceFor: (category: string, type: string, context: string) => GenericConstructor<any>) {
    super(parentInjector, colorService, alternativeLabelService, windowService, getStatletDataTypeServiceFor);
  }

  ngOnInit() {
    this.shouldRenderPrimaryAuthor = this.statlet.position === StatletPosition.StandAloneSite && this.statlet.reportType === StatletReportType.TopItems;
    super.ngOnInit();
  }

  loadRegularData() {
    if (!this.shouldRenderPrimaryAuthor) {
      super.loadRegularData();
    } else {
      this.data$ = this.windowService.widthCategory.pipe(
        switchMap((width) => observableZip(...this.statlet.points.map((point) => {
          return observableCombineLatest(
            observableZip(...Object.entries(point.values).map(([id, value]) => {
              const header = this.getHeaderById(this.statlet.columnHeaders, id);
              const dataService = this.getStatletDataTypeServiceForHeader(header);
              return this.getHeaderName(header, dataService, id, width).pipe(
                map((name) => Object.assign({name, value}))
              );
            })),
            this.itemDataService.findById(point.id).pipe(getFirstSucceededRemoteDataPayload()),
          ).pipe(
            switchMap(([series, item]: [any[], Item]) => {
              const header = this.getHeaderById(this.statlet.rowHeaders, point.id);
              const dataService = this.getStatletDataTypeServiceForHeader(header);
              const data = {series} as any;
              if (this.renderLinks && hasValue(dataService)) {
                data.link = dataService.getLinkByPoint(point);
              }
              const author = item.firstMetadataValue('melbourne.contributor.author');
              if (isNotEmpty(author)) {
                data.author = {};
                data.author.name = author;
                data.author.link = '/browse/author';
                data.author.queryParams = {
                  value: author,
                };
              }
              return this.getHeaderName(header, dataService, point.label, width).pipe(
                map((name) => Object.assign({}, data, {name}))
              );
            })
          );
        })))
      );
    }
  }

}
