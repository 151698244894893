  <div class="card" [@focusShadow]="(isCollapsed$ | async)?'blur':'focus'">
    <ds-truncatable [id]="dso.id">
      <div class="position-absolute ml-1">
        <ng-content></ng-content>
      </div>
      <a *ngIf="linkType != linkTypes.None" [target]="(linkType == linkTypes.ExternalLink) ? '_blank' : '_self'"
         [attr.rel]="(linkType == linkTypes.ExternalLink) ? 'noopener' : null"[routerLink]="[itemPageRoute]"
         [state]="getState(object)" class="card-img-top full-width">
          <div>
              <ds-melbourne-thumbnail
                [item]="dso"
                [mdFieldsForThumbnail]="['melbourne.prints.digitalurl', 'melbourne.archives.digitalurl']"
                [clickable]="false"
                [imageCount]="1"
              >
              </ds-melbourne-thumbnail>
          </div>
      </a>
      <span *ngIf="linkType == linkTypes.None" class="card-img-top full-width">
          <div>
              <ds-thumbnail [thumbnail]="dso?.thumbnail | async" [limitWidth]="false">
              </ds-thumbnail>
          </div>
      </span>
      <div class="card-body">
          <ds-type-badge *ngIf="showLabel" [object]="dso"></ds-type-badge>
          <ds-truncatable-part [id]="dso.id" [minLines]="3" type="h4">
              <h4 class="card-title" [innerHTML]="firstMetadataValue('dc.title')"></h4>
          </ds-truncatable-part>
          <p *ngIf="dso.hasMetadata(['dc.contributor.author', 'dc.creator', 'dc.contributor.*'])"
             class="item-authors card-text text-muted">
              <ds-truncatable-part [id]="dso.id" [minLines]="1">
                  <span *ngIf="dso.hasMetadata('dc.date.issued')" class="item-date">{{firstMetadataValue('dc.date.issued')}}</span>
                  <span *ngFor="let author of allMetadataValues(['dc.contributor.author', 'dc.creator', 'dc.contributor.*']);">,
                      <span [innerHTML]="author"></span>
                  </span>
              </ds-truncatable-part>
          </p>
          <p *ngIf="dso.hasMetadata('dc.description.abstract')" class="item-abstract card-text">
              <ds-truncatable-part [id]="dso.id" [minLines]="3">
                  <span [innerHTML]="firstMetadataValue('dc.description.abstract')"></span>
              </ds-truncatable-part>
          </p>
          <ds-search-result-statlet [object]="object"
                                                      [separator]="'<br/>'">
          </ds-search-result-statlet>
          <div class="d-flex justify-content-center">
            <div *ngIf="linkType != linkTypes.None" class="text-center">
              <a [target]="(linkType == linkTypes.ExternalLink) ? '_blank' : '_self'" [attr.rel]="(linkType == linkTypes.ExternalLink) ? 'noopener' : null" [routerLink]="[itemPageRoute]"
                 [state]="getState(object)" class="lead btn btn-primary viewButton">View</a>
            </div>
            <div [ngClass]="{'ml-2': linkType != linkTypes.None}">
              <ds-atmire-saved-item-list-control *ngIf="savedItemListControlEnabled" [item]="dso" [useLargeButton]="true" [context]="context"></ds-atmire-saved-item-list-control>
            </div>
          </div>
      </div>
    </ds-truncatable>
    <ds-citations-section-browse-element [item]="object"
                                         [labelTitle]="'publications.citations-section.title'"></ds-citations-section-browse-element>
    <ng-content></ng-content>
  </div>
