import { Component, Injector } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';

import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { ClaimedTaskActionsAbstractComponent } from '../abstract/claimed-task-actions-abstract.component';
import { ClaimedTaskDataService } from '../../../../core/tasks/claimed-task-data.service';
import { rendersWorkflowTaskOption } from '../switcher/claimed-task-actions-decorator';
import { Router } from '@angular/router';
import { NotificationsService } from '../../../notifications/notifications.service';
import { TranslateService } from '@ngx-translate/core';
import { SearchService } from '../../../../core/shared/search/search.service';
import { RequestService } from '../../../../core/data/request.service';

export const WORKFLOW_TASK_OPTION_DELETE = 'submit_delete';

@rendersWorkflowTaskOption(WORKFLOW_TASK_OPTION_DELETE)
@Component({
  selector: 'ds-claimed-task-actions-delete',
  templateUrl: './claimed-task-actions-delete.html',
})
/**
 * Component for displaying and processing the delete action on a workflow task item
 */
export class ClaimedTaskActionsDeleteComponent extends ClaimedTaskActionsAbstractComponent {
  /**
   * This component represents the delete option
   */
  option = WORKFLOW_TASK_OPTION_DELETE;

  /**
   * Reference to NgbModal
   */
  public modalRef: NgbModalRef;

  /**
   * Initialize instance variables
   *
   * @param {FormBuilder} formBuilder
   * @param {NgbModal} modalService
   * @param claimedTaskService
   */
  constructor(protected injector: Injector,
              protected router: Router,
              protected notificationsService: NotificationsService,
              protected translate: TranslateService,
              protected searchService: SearchService,
              protected requestService: RequestService,
              private modalService: NgbModal) {
    super(injector, router, notificationsService, translate, searchService, requestService);
  }

  /**
   * Submit a delete option for the task
   */
  submitTask() {
    this.modalRef.close('Send Button');
    super.submitTask();
  }

  /**
   * Open modal
   *
   * @param content
   */
  openDeleteModal(content: any) {
    this.modalRef = this.modalService.open(content);
  }
}
