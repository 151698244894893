import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { Observable, of as observableOf } from 'rxjs';
import {
  SingleFeatureAuthorizationGuard
} from '../app/core/data/feature-authorization/feature-authorization-guard/single-feature-authorization.guard';
import { AuthorizationDataService } from '../app/core/data/feature-authorization/authorization-data.service';
import { FeatureID } from '../app/core/data/feature-authorization/feature-id';
import { AuthService } from '../app/core/auth/auth.service';

/**
 * Prevent unauthorized access to the CUA admin features when the current authenticated user doesn't have
 * rights to the these
 */
@Injectable({
  providedIn: 'root'
})
export class AtmireCUAAdministratorGuard extends SingleFeatureAuthorizationGuard {
  constructor(protected authorizationService: AuthorizationDataService, protected router: Router, protected authService: AuthService) {
    super(authorizationService, router, authService);
  }

  getFeatureID(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<FeatureID> {
    return observableOf(FeatureID.CUAAdministratorOf);
  }

}
