import { Component, Input } from '@angular/core';
import { ThemedComponent } from '../../../../shared/theme-support/themed.component';
import { AtmireSavedItemListSelectiveExportDropdownComponent } from './atmire-saved-item-list-selective-export-dropdown.component';
import { SearchOptions } from '../../../../shared/search/search-options.model';
import { DSpaceObject } from '../../../../core/shared/dspace-object.model';

/**
 * Themed wrapper for AtmireSavedItemListSelectiveExportDropdownComponent
 */
@Component({
  selector: 'ds-themed-atmire-saved-item-list-selective-export-dropdown',
  styleUrls: [],
  templateUrl: '../../../../shared/theme-support/themed.component.html',
})
export class ThemedArtmireSavedItemListSelectiveExportDropdownComponent extends ThemedComponent<AtmireSavedItemListSelectiveExportDropdownComponent> {

  /**
   * THe prefix to use for i18n messages
   */
  @Input() msgPrefix = 'saved-item-list';

  /**
   * The total number of results for the current search options
   */
  @Input() totalResults = -1;

  /**
   * The current configuration of the search
   */
  @Input() searchConfig: SearchOptions;

  @Input() scope: DSpaceObject;

  protected inAndOutputNames: (keyof AtmireSavedItemListSelectiveExportDropdownComponent & keyof this)[] =
    ['msgPrefix', 'totalResults', 'searchConfig', 'scope'];

  protected getComponentName(): string {
    return 'AtmireSavedItemListSelectiveExportDropdownComponent';
  }

  protected importThemedComponent(themeName: string): Promise<any> {
    return import(`../../../../../themes/${themeName}/app/atmire-selective-export/atmire-selective-export-shared/atmire-export-dropdown/atmire-saved-item-list-selective-export-dropdown/atmire-saved-item-list-selective-export-dropdown.component`);
  }

  protected importUnthemedComponent(): Promise<any> {
    return import(`./atmire-saved-item-list-selective-export-dropdown.component`);
  }
}
