import { Component } from '@angular/core';
import { GenericItemPageFieldComponent } from '../generic/generic-item-page-field.component';

@Component({
  selector: 'ds-item-page-uom-author',
  templateUrl: './item-page-uom-author.component.html',
})
/**
 * Represents an author's name(s) along with FAE and ORCID badges
 */
export class ItemPageUomAuthorComponent extends GenericItemPageFieldComponent {
}
