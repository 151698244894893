import { ChangeDetectionStrategy, Component } from '@angular/core';
import { ViewMode } from '../../../../../../../app/core/shared/view-mode.model';
import { listableObjectComponent } from '../../../../../../../app/shared/object-collection/shared/listable-object/listable-object.decorator';
import { Context } from '../../../../../../../app/core/shared/context.model';
import { Item } from '../../../../../../../app/core/shared/item.model';
import { UntypedItemComponent as BaseComponent } from '../../../../../../../app/item-page/simple/item-types/untyped-item/untyped-item.component';
import { MetadataValue } from '../../../../../../../app/core/shared/metadata.models';
import {
  digitalAssets,
  eventsToDigitalAssetsTheatreVersion, originalWorks,
} from '../../../../../../../app/item-page/simple/field-components/specific-field/relations/item-relations.config';

/**
 * Component that represents a publication Item page
 */

@listableObjectComponent(Item, ViewMode.StandalonePage, Context.Any, 'theatredance')
@Component({
  selector: 'ds-untyped-item',
  styleUrls: ['./untyped-item.component.scss'],
  templateUrl: './untyped-item.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UntypedItemComponent extends BaseComponent {
  public theatreDanceRelations = [
    eventsToDigitalAssetsTheatreVersion,
    originalWorks,
    digitalAssets,
  ];
}
