import { type } from '../../shared/ngrx/type';
import { Action } from '@ngrx/store';

/**
 * The VHost action types.
 */
export const VHostActionTypes = {
  STORE: type('dspace/core/vhost/STORE'),
  STORE_COMMUNITY: type('dspace/core/vhost/STORE_COMMUNITY'),
  DSO_PAGE: type('dspace/core/vhost/DSO_PAGE'),
};

/* tslint:disable:max-classes-per-file */

/**
 * An action to store the vHost.
 */
export class StoreVHostAction implements Action {

  type: string = VHostActionTypes.STORE;

  payload: {
    vHost: string;
  };

  constructor(vHost: string) {
    this.payload = {
      vHost: vHost,
    };
  }
}

/**
 * An action to store the vHost community id.
 */
export class StoreVHostCommunityAction implements Action {

  type: string = VHostActionTypes.STORE_COMMUNITY;

  payload: {
    uuid: string;
  };

  constructor(uuid: string) {
    this.payload = {
      uuid: uuid,
    };
  }
}

export class VHostDsoPageAction implements Action {

  type: string = VHostActionTypes.DSO_PAGE;

  payload: {
    uuid: string;
  };

  constructor(uuid: string) {
    this.payload = {
      uuid: uuid,
    };
  }
}

/**
 * A type to encompass all VHost actions.
 */
export type VHostAction
  = StoreVHostAction
  | StoreVHostCommunityAction;
